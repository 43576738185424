import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../components/Spinner";
import h from "../utilities/helpers";
import { set_poll_modal, route } from "../redux/actions";
import { Link } from "react-router-dom";

class PollVotersModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  setShowModal = (option) =>
    this.props.set_poll_modal({
      shown: option,
    });

  toggleShowModal = () =>
    this.props.set_poll_modal({
      shown: !this.props.modalShown,
    });

  route = (e, route) => {
    e.preventDefault();
    this.props.route(route);
    this.toggleShowModal();
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    Users who voted "{this.props.option}"
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  {this.props.voters.length ? (
                    <MDBListGroup>
                      {this.props.voters.map((voter) => (
                        <Link
                          className="w-100"
                          key={voter.userID}
                          to={`/${voter.username}`}
                          onClick={(e) => this.route(e, `/${voter.username}`)}
                        >
                          <MDBRipple rippleColor="primary" className="w-100">
                            <MDBListGroupItem
                              className="d-flex align-items-center w-100 p-2"
                              action
                            >
                              <div className="chat-avatars rounded-circle">
                                <div
                                  className="fit-images fit-round"
                                  style={{
                                    backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${voter.avatar.thumbnail}")`,
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                              <h5 className="mb-0 ms-2">@{voter.username}</h5>
                            </MDBListGroupItem>
                          </MDBRipple>
                        </Link>
                      ))}
                    </MDBListGroup>
                  ) : (
                    <h5 className="text-center my-4">
                      There are no voters for {this.props.option}
                    </h5>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn className="bg-gray" onClick={this.toggleShowModal}>
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalShown: state.pollModalOptions.shown,
  voters: state.pollModalOptions.voters,
  option: state.pollModalOptions.option,
  emissionID: state.pollModalOptions.emissionID,
});

export default connect(mapStateToProps, { set_poll_modal, route })(
  PollVotersModal
);
