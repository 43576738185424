import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import LinearProgress from "@mui/material/LinearProgress";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import { route, set_report_count } from "../redux/actions";
import DetailModal from "./reports/DetailModal";
import ToastMisc from "../components/notifications/ToastMisc";
import { Link } from "react-router-dom";

class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * reports: Array - List of reports
       * loaded: Boolean - Whether the initial data has loaded
       * detailModalShown: Boolean - Whether the modal with report details is currently displayed
       * socketConnected: Boolean - Whether the main socket has connected
       * reportSelected: false | Object - Reports document
       */
      reports: [],
      loaded: false,
      detailModalShown: false,
      socketConnected: false,
      reportSelected: false,
    };
    /**
     * this.toastRef - Clicked in order to trigger notifications
     */
    this.toastRef = React.createRef(null);
  }

  /**
   * If user is not logged in, redirect to the login page
   * Else, load report data
   */
  componentDidMount() {
    if (
      !this.props.userInfo._id &&
      this.props.history &&
      !this.state.redirecting
    )
      this.setState(
        {
          ...this.state,
          redirecting: true,
        },
        () => this.props.route("/login")
      );
    else this.load();
  }

  /**
   * If user logs out, redirect to the login page
   * Once a new report is received, mark reports as read after 1 second
   * If socket connects for the first time, or disconnected socket reconnects, initialize socket
   * If a new report is received, update the reports list
   */
  componentDidUpdate(prevProps) {
    if (
      !this.props.userInfo._id &&
      this.props.history &&
      !this.state.redirecting
    )
      this.setState(
        {
          ...this.state,
          redirecting: true,
        },
        () => this.props.route("/login")
      );
    if (prevProps.userInfo.unreadReports !== this.props.userInfo.unreadReports)
      this.readAll();
    if (prevProps.socketReconnect !== this.props.socketReconnect)
      this.initializeSocket();
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (prevProps.openReports < this.props.openReports) this.update();
  }

  /**
   * Triggered when the user receives a new report via socket
   * Pings the server for an updated reports list
   * Updates the reports list
   */
  update = () =>
    axios
      .post("/api/reports/update", {
        ids: this.state.reports.map((r) => r._id),
      })
      .then((res) =>
        this.setState({
          ...this.state,
          reports: [...this.state.reports, ...res.data.reports],
        })
      )
      .catch((err) => {
        console.log("update error", err);
        setTimeout(this.update, 1500);
      });

  /**
   *
   * @param {Object} report - Reports document
   *
   * Triggered when the user clicks on a report
   * Sets that report into state and opens the Report Details modal
   */
  selectReport = (report) =>
    this.setState(
      {
        ...this.state,
        reportSelected: report,
      },
      this.toggleDetailModal
    );

  /**
   * Loads the initial list of reports
   * Sets that list into state
   */
  load = () =>
    axios
      .get("/api/reports")
      .then((res) =>
        this.setState(
          {
            ...this.state,
            reports: res.data.reports,
            loaded: true,
          },
          () => {
            this.readAll();
            if (this.props.socket && !this.state.socketConnected)
              this.connectSocket();
          }
        )
      )
      .catch((err) => {
        console.log("notification load error", err);
        setTimeout(this.load, 1000);
      });

  /**
   * Triggered when the main socket is connected
   * Sets the condition in class state, then initializes the socket
   */
  connectSocket = () =>
    this.setState(
      {
        ...this.state,
        socketConnected: true,
      },
      this.initializeSocket
    );

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    this.props.socket.off("report");

    this.props.socket.on("report", (report) => {
      this.props.set_report_count(this.props.userInfo.unreadReports + 1);
    });
  };

  /**
   * Triggered any time the user loads the page or receives new notifications
   * After 1 second, marks all reports as read
   */
  readAll = () =>
    setTimeout(
      () =>
        axios
          .get("/profile/read-reports")
          .then(() => this.props.set_report_count(0))
          .catch((err) => {
            console.log("Read all error", err);
            setTimeout(this.readAll, 2000);
          }),
      1000
    );

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, destination) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.route(destination);
  };

  toggleDetailModal = () =>
    this.setState({
      ...this.state,
      detailModalShown: !this.state.detailModalShown,
    });

  setDetailModal = (option) =>
    this.setState({
      ...this.state,
      detailModalShown: option,
    });

  /**
   *
   * @param {Object} data - Data of report(s) to purge (Content id, notification text/icon)
   *
   * Triggered when the user takes action on one of the reports
   * Purges all of the reports received for the same content
   * Notifies the user that the action has completed
   */
  purgeReports = (data) =>
    this.setState(
      {
        ...this.state,
        detailModalShown: false,
        notificationText: data.text,
        notificationIcon: data.icon,
      },
      () => {
        this.toastRef.current.click();
        if (data.type === "emission")
          this.setState(
            {
              ...this.state,
              reports: this.state.reports.filter(
                (r) => r.emissionID !== data.emissionID
              ),
              reportSelected: false,
            },
            () => {
              if (data.ban)
                this.setState({
                  ...this.state,
                  reports: this.state.reports.filter(
                    (r) =>
                      r.user_id !== data.user_id &&
                      (!r.emission || r.emission.user_id !== data.user_id)
                  ),
                });
            }
          );
        else if (data.type === "approval")
          this.setState({
            ...this.state,
            reports: this.state.reports.filter((r) => r._id !== data.reportID),
            reportSelected: false,
          });
        else
          this.setState(
            {
              ...this.state,
              reports: this.state.reports.filter(
                (r) => r.user_id !== data.user_id
              ),
              reportSelected: false,
            },
            () => {
              if (data.ban)
                this.setState({
                  ...this.state,
                  reports: this.state.reports.filter(
                    (r) => r.poster !== data.poster
                  ),
                });
            }
          );
      }
    );

  /**
   *
   * @param {JSX} icon - Notification icon
   * @param {String} text - Notification text
   *
   * Clicks the toastRef to notify the user with the text and icon supplied
   */
  notify = (icon, text) =>
    this.setState(
      {
        ...this.state,
        notificationText: text,
        notificationIcon: icon,
      },
      () => this.toastRef.current.click()
    );

  render() {
    let reports = [
      ...this.state.reports
        .filter((r) => r.type === "approval")
        .map((r) => ({
          ...r,
          userInfo: r.user,
          lastReport: new Date(r.timestamp),
        })),
    ];
    [
      ...new Set(
        this.state.reports
          .filter((r) => r.type === "user")
          .map((r) => r.user_id)
      ),
    ].forEach((user_id) => {
      const affectedReports = this.state.reports
        .filter((r) => r.type === "user" && r.user_id === user_id)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      reports.push({
        lastReport: affectedReports[0].timestamp,
        reports: affectedReports,
        type: "user",
        user_id: user_id,
        unread: affectedReports.find((r) => r.unread),
        userInfo: affectedReports[0].user,
      });
    });
    [
      ...new Set(
        this.state.reports
          .filter((r) => r.type === "emission")
          .map((r) => r.emissionID)
      ),
    ].forEach((emissionID) => {
      const affectedReports = this.state.reports
        .filter((r) => r.type === "emission" && r.emissionID === emissionID)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      reports.push({
        lastReport: affectedReports[0].timestamp,
        reports: affectedReports,
        type: "emission",
        emissionID: emissionID,
        unread: affectedReports.find((r) => r.unread),
        emission: affectedReports[0].emission,
      });
    });
    reports = [
      ...reports
        .filter((r) => r.unread)
        .sort((a, b) => new Date(b.lastReport) - new Date(a.lastReport)),
      ...reports
        .filter((r) => !r.unread)
        .sort((a, b) => new Date(b.lastReport) - new Date(a.lastReport)),
    ];
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 h-100 report-container"
      >
        <DetailModal
          modalShown={this.state.detailModalShown}
          toggleShowModal={this.toggleDetailModal}
          setShowModal={this.setDetailModal}
          report={this.state.reportSelected}
          purgeReports={this.purgeReports}
        />
        <MDBBtn
          id="toast-trigger-report"
          className="d-none"
          color="primary"
          ref={this.toastRef}
        >
          Toast
        </MDBBtn>
        <ToastMisc
          triggerRef={this.toastRef}
          id={"report-toast"}
          icon={this.state.notificationIcon}
          text={this.state.notificationText}
        />
        <MDBContainer>
          {this.state.loaded ? (
            <motion.div
              transition={t.transition}
              exit={t.fade_out}
              animate={t.normalize}
              initial={t.fade_out}
            >
              {this.state.reports.length ? (
                <MDBCard>
                  <MDBCardBody>
                    <h5 className="text-center display-6">Reports</h5>
                    <hr />
                    <MDBListGroup light>
                      {reports.map((report) => (
                        <MDBListGroupItem
                          key={
                            report.type === "user"
                              ? `user-${report.user_id}`
                              : `emission-${report.emissionID}`
                          }
                        >
                          <motion.div
                            className="px-2 d-flex justify-content-between align-items-center report-info-container"
                            transition={t.transition}
                            exit={t.fade_out}
                            animate={t.normalize}
                            initial={t.fade_out}
                          >
                            {report.type === "user" ? (
                              <h5 className="m-0">
                                <i
                                  style={{ width: "25px" }}
                                  className="fas fa-user text-primary me-2 text-center"
                                ></i>
                                <Link
                                  className="text-default"
                                  to={`/${report.userInfo.username}`}
                                  onClick={(e) =>
                                    this.route(
                                      e,
                                      `/${report.userInfo.username}`
                                    )
                                  }
                                >
                                  @{report.userInfo.username}
                                </Link>
                              </h5>
                            ) : (
                              <>
                                {report.type === "emission" ? (
                                  <h5 className="m-0">
                                    <i
                                      style={{ width: "25px" }}
                                      className="fas fa-wifi text-success me-2 text-center"
                                    ></i>
                                    <Link
                                      className="text-default"
                                      to={`/e/${report.emissionID}`}
                                      onClick={(e) =>
                                        this.route(e, `/e/${report.emissionID}`)
                                      }
                                    >
                                      <span className="text-capitalize">
                                        {process.env.REACT_APP_EMISSION_NAME}
                                      </span>{" "}
                                      <span className="text-pkmn">
                                        #{report.emissionID}
                                      </span>
                                    </Link>
                                  </h5>
                                ) : (
                                  <h5 className="m-0">
                                    <i
                                      style={{ width: "25px" }}
                                      className="fas fa-user-plus text-secondary me-2 text-center"
                                    ></i>
                                    <Link
                                      className="text-default"
                                      to={`/${report.userInfo.username}`}
                                      onClick={(e) =>
                                        this.route(
                                          e,
                                          `/${report.userInfo.username}`
                                        )
                                      }
                                    >
                                      @{report.userInfo.username}
                                    </Link>
                                    <> requires approval</>
                                  </h5>
                                )}
                              </>
                            )}
                            {report.type === "approval" ? (
                              <h5
                                onClick={() => this.selectReport(report)}
                                className="text-primary cursor-pointer m-0"
                              >
                                View
                              </h5>
                            ) : (
                              <h5
                                onClick={() => this.selectReport(report)}
                                className="text-primary cursor-pointer m-0"
                              >
                                {h.numberWithCommas(report.reports.length)}{" "}
                                Report{report.reports.length === 1 ? "" : "s"}
                              </h5>
                            )}
                          </motion.div>
                        </MDBListGroupItem>
                      ))}
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              ) : (
                <h5 className="text-center display-4 mb-4 mt-5">
                  There are no reports
                </h5>
              )}
            </motion.div>
          ) : (
            <>
              <h5 className="text-center display-6 mb-4 mt-5">
                Loading Reports
              </h5>
              <LinearProgress />
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_report_count })(Reports);
