import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner";
import axios from "axios";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";

class DeleteAccountModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a new avatar is in the process of being uploaded to and processed on the server
       */
      working: false,
      emailSent: false,
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Make request to server
   * Update user details with new avatar
   * Hide modal
   */
  submit = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () => {
          axios
            .get("/auth/delete-account")
            .then((res) =>
              this.setState({
                ...this.state,
                emailSent: res.data.email,
                working: false,
              })
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            );
        }
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            id="change-avatar-modal"
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="lg">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Are you sure?</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  {this.state.emailSent ? (
                    <motion.div
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out}
                    >
                      <h5 className="text-center display-6 my-4">
                        Check your email
                      </h5>
                      <p className="text-center">
                        An email has been sent to {this.state.emailSent} with a
                        link to confirm the deletion of your account.
                      </p>
                    </motion.div>
                  ) : (
                    <h5 className="text-center">
                      A link will be sent to your email in order to confirm your
                      decision. Once this link is clicked, your account will be
                      deleted.
                    </h5>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  {!this.state.emailSent && (
                    <MDBBtn
                      color="danger"
                      disabled={this.state.working}
                      onClick={this.submit}
                    >
                      {this.state.working ? (
                        <>
                          <Spinner size="sm" className="me-2" />
                          Sending Email
                        </>
                      ) : (
                        <>
                          <i className="fas fa-check-circle me-2" />
                          Send Email
                        </>
                      )}
                    </MDBBtn>
                  )}

                  <MDBBtn
                    className="bg-gray"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(DeleteAccountModal);
