import React from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import h from "../../../../../utilities/helpers";
import { set_profile } from "../../../../../redux/actions";
import axios from "axios";
import { connect } from "react-redux";

// Reasons start
const reasons = [
  {
    id: "spam",
    label: "Spam",
  },
  {
    id: "porn",
    label: "Porn",
  },
  {
    id: "fed",
    label: "Illegal/Fedposting",
  },
  {
    id: "other",
    label: "Other",
  },
];
// Reasons end

class Ban extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the user is in the process of banning the profile
       * reason: String - Reason for the ban
       * input: Object - The text input data (values, errors, etc)
       * duration: String - Ban duration
       */
      working: false,
      reason: reasons[0].id,
      input: {
        id: "ban-input",
        error: "",
        invalid: false,
        value: "",
      },
      duration: "day",
    };
    this.submit = this.submit.bind(this); // Allows the submit method to be called from the parent
  }

  /**
   * Fix mdb inputs
   * Set the submit() method in the parent
   */
  componentDidMount() {
    setTimeout(this.fixMDBInputs, 250);
    this.props.setSubmit(this.submit);
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   * Fixes MDB ui bug in which labels are not properly floating or are crossed out
   */
  fixMDBInputs = () => {
    [].slice
      .call(document.getElementsByClassName("details-inputs"))
      .forEach((e) => {
        if (!e.value && e.classList.contains("active"))
          e.classList.remove("active");
        else if (e.value && !e.classList.contains("active"))
          e.classList.add("active");
      });
    this.setState(
      {
        ...this.state,
        reset: !this.state.reset,
      },
      () => h.floatLabels()
    );
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        input: {
          ...this.state.input,
          value: e.target.value,
        },
      },
      () => {
        if (this.state.input.value.length > 5000)
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: true,
                error: "Too long (Max: 5000 chars)",
              },
            },
            () =>
              document
                .getElementById("ban-input")
                .setCustomValidity(this.state.input.error)
          );
        else
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: false,
                error: "",
              },
            },
            () => document.getElementById("ban-input").setCustomValidity("")
          );
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Notify user that the profile has been banned
   * Reset the form
   * Update the profile
   */
  submit = () => {
    document.getElementById("ban-form").classList.add("was-validated");
    if (this.state.input.value.length > 5000)
      document
        .getElementById("ban-input")
        .setCustomValidity(this.state.input.error);
    if (!this.state.working && this.state.input.value.length <= 5000)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          this.props.setWorking(true);
          try {
            const submission = {
              details: this.state.input.value,
              reason: this.state.reason,
              userID: this.props.profileInfo.user._id,
              duration: this.state.duration,
            };
            axios
              .post("/support/users/ban", submission)
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    reason: reasons[0].id,
                    input: {
                      id: "ban-input",
                      error: "",
                      invalid: false,
                      value: "",
                    },
                  },
                  () => {
                    this.props.notify(
                      <i className="fas fa-gavel text-danger me-2"></i>,
                      <>@{this.props.profileInfo.user.username} Banned</>
                    );
                    this.props.reset();
                    this.props.set_profile({
                      user: res.data.userInfo,
                    });
                  }
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    this.props.setWorking(false);
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                this.props.setWorking(false);
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  reasonChange = (e) =>
    this.setState({
      ...this.state,
      reason: e.target.value,
    });

  durationChange = (e) =>
    this.setState({
      ...this.state,
      duration: e.target.value,
    });

  render() {
    return (
      <div className="d-flex details-modal-ban-options">
        <div className="d-flex justify-content-between">
          <div className="px-4">
            <FormControl>
              <h5 className="fs-5 display-6 m-0">Reason</h5>
              <hr className="my-1" />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={this.state.reason}
                name="radio-buttons-group"
                onChange={this.reasonChange}
              >
                {reasons.map((reason) => (
                  <FormControlLabel
                    key={reason.id}
                    value={reason.id}
                    control={<Radio />}
                    label={reason.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="px-4">
            <FormControl>
              <h5 className="fs-5 display-6 m-0">Duration</h5>
              <hr className="my-1" />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={this.state.duration}
                name="radio-buttons-group"
                onChange={this.durationChange}
              >
                <FormControlLabel
                  key="day"
                  value="day"
                  control={<Radio />}
                  label="1 Day"
                />
                <FormControlLabel
                  key="week"
                  value="week"
                  control={<Radio />}
                  label="1 Week"
                />
                <FormControlLabel
                  key="month"
                  value="month"
                  control={<Radio />}
                  label="1 Month"
                />
                <FormControlLabel
                  key="permanent"
                  value="permanent"
                  control={<Radio />}
                  label="Permanent"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="flex-grow-1">
          <MDBValidation
            className="h-100"
            method="dialog"
            id="ban-form"
            name="ban-form"
          >
            <MDBValidationItem
              className="pb-4 h-100 h-100-child"
              feedback={this.state.input.error}
              invalid={true}
            >
              <MDBTextArea
                name="ban-input"
                onChange={this.changeHandler}
                id="ban-input"
                label="Details"
                size="lg"
                className={`h-100 ${!this.state.input.invalid ? "mb-0" : 0}`}
              />
            </MDBValidationItem>
          </MDBValidation>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_profile })(Ban);
