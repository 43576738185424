import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRipple,
} from "mdb-react-ui-kit";
import { parse } from "node-html-parser";
import ChatBox from "./messages/ChatBox";
import { route, set_unread_messages } from "../redux/actions";

class Messages extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * conversations: Array - List of private chats
       * loaded: Boolean - Whether the initial data has loaded
       * conversationSelected: false | Object - The selected conversation data
       * socketConnected: Boolean - Whether the message socket has connected
       */
      conversations: [],
      loaded: false,
      conversationSelected: false,
      socketConnected: false,
    };
  }

  /**
   * If user is not logged in, route to the login page
   * Else, load messages
   */
  componentDidMount() {
    if (!this.props.userInfo._id) this.props.route("/login");
    else this.load();
  }

  /**
   * If the user logs out, route to the login page
   * If the main socket is disconnected then reconnects, initialize the component socket
   * If the main socket is initially connected, initialize the component socket
   */
  componentDidUpdate(prevProps) {
    if (prevProps.userInfo._id && !this.props.userInfo._id)
      this.props.route("/login");
    if (prevProps.socketReconnect !== this.props.socketReconnect)
      this.initializeSocket();
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
  }

  connectSocket = () =>
    this.setState(
      {
        ...this.state,
        socketConnected: true,
      },
      this.initializeSocket
    );

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    this.props.socket.off("new-message");
    this.props.socket.off("update-user");

    this.props.socket.on("update-user", this.updateUser);
    this.props.socket.on("new-message", this.addMessage);
  };

  clearMessage = () =>
    this.setState({
      ...this.state,
      conversationSelected: false,
    });

  /**
   *
   * @param {Object} userInfo - Users document minus sensitive info
   *
   * Triggered when a user's chat partner's profile is updated
   * Updates the conversation with the updated user info
   */
  updateUser = (userInfo) =>
    this.setState({
      ...this.state,
      conversations: this.state.conversations.map((conversation) => {
        if (conversation.users.find((user) => user._id === userInfo._id))
          return {
            ...conversation,
            users: [
              conversation.users.find((u) => u._id === this.props.userInfo._id),
              userInfo,
            ],
          };
        else return conversation;
      }),
    });

  /**
   * Loads a list of all of the user's private messages
   */
  load = () =>
    axios
      .get("/messages/init")
      .then((res) =>
        this.setState(
          {
            ...this.state,
            loaded: true,
            conversations: this.sortConversations(res.data.conversations),
          },
          () => {
            this.setUnreadMessages();
            if (this.props.socket && !this.state.socketConnected)
              this.connectSocket();
          }
        )
      )
      .catch((err) => {
        console.log("message load err", err);
        setTimeout(this.load, 1000);
      });

  /**
   *
   * @param {String} conversation - _id of Conversation that the user has selected
   *
   * Triggered when the user clicks on one of their conversations
   *
   * Sets the selected conversation into state
   * Sets all of that conversations messages as read
   */
  selectConversation = (conversation) =>
    this.setState(
      {
        ...this.state,
        conversationSelected: conversation,
        conversations: this.sortConversations(
          this.state.conversations.map((c) => {
            if (c._id === conversation)
              return {
                ...c,
                messages: c.messages.map((message) => {
                  if (message.author === this.props.userInfo._id)
                    return message;
                  else
                    return {
                      ...message,
                      read: true,
                    };
                }),
              };
            else return c;
          })
        ),
      },
      () => {
        this.props.socket.emit(
          "read-messages",
          this.state.conversations
            .find((c) => c._id === conversation)
            .users.find((p) => p._id !== this.props.userInfo._id)._id
        );
        this.setUnreadMessages();
      }
    );

  /**
   *
   * @param {Object} messageObj - Conversations document
   *
   * Triggered when the user receives a new message via socket
   * Adds that message into state
   */
  addMessage = (messageObj) => {
    if (
      !this.state.conversations.find((conversation) =>
        conversation.messages.find((message) => message.id === messageObj.id)
      )
    ) {
      let found = false;
      let user = this.state.conversationSelected
        ? this.state.conversations
            .find((c) => c._id === this.state.conversationSelected)
            .users.find((p) => p._id !== this.props.userInfo._id)
        : {
            _id: false,
          };
      this.setState(
        {
          ...this.state,
          conversations: this.sortConversations(
            this.state.conversations.map((conversation) => {
              if (conversation.users.find((p) => p._id === messageObj.party)) {
                found = true;
                return {
                  ...conversation,
                  messages: [
                    ...conversation.messages,
                    {
                      ...messageObj,
                      read: messageObj.party === user._id,
                    },
                  ].sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                  ),
                };
              } else return conversation;
            })
          ),
        },
        () => {
          if (!found) this.getNewConversation(messageObj.party);
          if (
            found &&
            this.state.conversationSelected &&
            messageObj.party === user._id
          )
            this.props.socket.emit("read-messages", messageObj.party);
          this.setUnreadMessages();
        }
      );
    }
  };

  setUnreadMessages = () =>
    this.props.set_unread_messages(
      this.state.conversations.reduce(
        (prev, curr) =>
          prev +
          curr.messages.filter(
            (m) => !m.read && m.author !== this.props.userInfo._id
          ).length,
        0
      )
    );

  /**
   * Triggered when the user receives a new message for a brand new conversation
   * Grabs the Conversations document for that message
   */
  getNewConversation = (userID) =>
    axios
      .get(`/messages/${userID}`)
      .then((res) =>
        this.setState(
          {
            ...this.state,
            conversations: this.sortConversations([
              ...this.state.conversations,
              res.data.conversation,
            ]),
          },
          this.setUnreadMessages
        )
      )
      .catch((err) => {
        console.log("error getting new conversation", err);
        if (err.response && err.response.status === 404)
          console.log("Conversation not found");
        else setTimeout(() => this.getNewConversation(userID), 1000);
      });

  // Sorts the conversations by most recent last message sent or received
  sortConversations = (conversations) =>
    conversations.sort((a, b) => {
      const lastMessageA = a.messages.sort(
        (c, d) => new Date(d.timestamp) - new Date(c.timestamp)
      )[0].timestamp;
      const lastMessageB = b.messages.sort(
        (c, d) => new Date(d.timestamp) - new Date(c.timestamp)
      )[0].timestamp;
      return new Date(lastMessageB) - new Date(lastMessageA);
    });

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 pb-3 h-100"
      >
        <MDBContainer className="h-100 message-container" fluid>
          {this.state.loaded ? (
            <>
              {this.state.conversations.length ? (
                <motion.div
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                  className="row h-100"
                >
                  <div className="col-12 col-lg-4 h-100 message-list-lg">
                    <MDBCard className="d-flex flex-column h-100">
                      <MDBCardHeader>
                        <h5 className="m-0 display-6">Messages</h5>
                      </MDBCardHeader>
                      <MDBCardBody className="fg-1 overflow-y-auto p-0">
                        <ul className="list-group">
                          {this.state.conversations.map((conversation) => {
                            const user = conversation.users.find(
                              (p) => p._id !== this.props.userInfo._id
                            );
                            const unread = conversation.messages.find(
                              (m) =>
                                !m.read && m.author !== this.props.userInfo._id
                            );
                            const lastMessage = conversation.messages.sort(
                              (a, b) =>
                                new Date(b.timestamp) - new Date(a.timestamp)
                            )[0];
                            return (
                              <MDBRipple>
                                <li
                                  className={`list-group-item list-group-item-action cursor-pointer ps-0 ${
                                    this.state.conversationSelected ===
                                    conversation._id
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.selectConversation(conversation._id)
                                  }
                                  key={conversation._id}
                                >
                                  <div className="d-flex">
                                    <i
                                      className="fas fa-circle align-self-center text-primary mx-2"
                                      style={{ opacity: unread ? 1 : 0 }}
                                    />
                                    <div className="chat-avatars">
                                      <div
                                        className="fit-images chat-avatars fit-round"
                                        style={{
                                          backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}")`,
                                          borderRadius: "50%",
                                        }}
                                      ></div>
                                    </div>
                                    <div className="flex-grow-1 ms-2">
                                      <h6 className="m-0 line-height-16">
                                        @{user.username}
                                      </h6>
                                      <p
                                        className={`m-0 ${
                                          unread ? "fw-bold" : ""
                                        }`}
                                      >
                                        {h.shortString(
                                          parse(lastMessage.message).textContent
                                        )}
                                      </p>
                                    </div>
                                    <p
                                      className={`m-0 ${
                                        unread ? "fw-bold" : ""
                                      } text-${
                                        this.state.conversationSelected ===
                                        conversation._id
                                          ? "light"
                                          : "blusteel"
                                      }`}
                                    >
                                      {h.getMessageTime(lastMessage.timestamp)}
                                    </p>
                                  </div>
                                </li>
                              </MDBRipple>
                            );
                          })}
                        </ul>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                  {this.state.conversationSelected ? (
                    <motion.section
                      transition={t.transition}
                      exit={t.fade_out}
                      animate={t.normalize}
                      initial={t.fade_out}
                      className="col-12 col-lg-8 h-100"
                    >
                      <ChatBox
                        conversation={this.state.conversations.find(
                          (c) => c._id === this.state.conversationSelected
                        )}
                        addMessage={this.addMessage}
                        clearMessage={this.clearMessage}
                      />
                    </motion.section>
                  ) : (
                    <motion.div
                      transition={t.transition}
                      exit={t.fade_out}
                      animate={t.normalize}
                      initial={t.fade_out}
                      className="col-12 col-lg-4 h-100 message-list-mobile"
                    >
                      <MDBCard className="d-flex flex-column h-100">
                        <MDBCardHeader>
                          <h5 className="m-0 display-6 text-center">
                            Messages
                          </h5>
                        </MDBCardHeader>
                        <MDBCardBody className="fg-1 overflow-y-auto p-0">
                          <ul className="list-group">
                            {this.state.conversations.map((conversation) => {
                              const user = conversation.users.find(
                                (p) => p._id !== this.props.userInfo._id
                              );
                              const unread = conversation.messages.find(
                                (m) =>
                                  !m.read &&
                                  m.author !== this.props.userInfo._id
                              );
                              const lastMessage = conversation.messages.sort(
                                (a, b) =>
                                  new Date(b.timestamp) - new Date(a.timestamp)
                              )[0];
                              return (
                                <MDBRipple>
                                  <li
                                    className={`list-group-item list-group-item-action cursor-pointer ${
                                      this.state.conversationSelected ===
                                      conversation._id
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      this.selectConversation(conversation._id)
                                    }
                                    key={conversation._id}
                                  >
                                    <div className="d-flex">
                                      <div className="chat-avatars">
                                        <div
                                          className="fit-images chat-avatars fit-round"
                                          style={{
                                            backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}")`,
                                            borderRadius: "50%",
                                          }}
                                        ></div>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="m-0 line-height-16">
                                          @{user.username}
                                        </h6>
                                        <p
                                          className={`m-0 ${
                                            unread ? "fw-bold" : ""
                                          }`}
                                        >
                                          {h.shortString(
                                            parse(lastMessage.message)
                                              .textContent
                                          )}
                                        </p>
                                      </div>
                                      <p
                                        className={`m-0 ${
                                          unread ? "fw-bold" : ""
                                        } text-${
                                          this.state.conversationSelected ===
                                          conversation._id
                                            ? "light"
                                            : "blusteel"
                                        }`}
                                      >
                                        {h.getMessageTime(
                                          lastMessage.timestamp
                                        )}
                                      </p>
                                    </div>
                                  </li>
                                </MDBRipple>
                              );
                            })}
                          </ul>
                        </MDBCardBody>
                      </MDBCard>
                    </motion.div>
                  )}
                </motion.div>
              ) : (
                <>
                  <h5 className="text-center display-6 mt-5">
                    You have no messages
                  </h5>
                  <p className="text-center">
                    Visit a user's profile and send them a message to begin
                  </p>
                </>
              )}
            </>
          ) : (
            <div className="w-75 mx-auto">
              <h5 className="text-center display-6 mb-4 mt-5">
                Loading Messages
              </h5>
              <LinearProgress />
            </div>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_unread_messages })(
  Messages
);
