import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBRipple,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner';
import { change_user_details } from '../../../../redux/actions';
import axios from 'axios';

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

class ChangeAvatarModal extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * avatar: String, Path to the user's avatar
             * avatarName: String, "Click to Change", or the name of the file the user has selected, if any
             * avatarFile: false | File object that contains an avatar file that the user selected
             * working: Boolean - Whether a new avatar is in the process of being uploaded to and processed on the server
             * newFileSelected: Boolean - Whether the current file selected is different from the user's current avatar
             */
            avatar: `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${props.userInfo.avatar.main}`,
            avatarName: 'Click to Change',
            avatarFile: '',
            working: false,
            newFileSelected: false
        }
    }

    /**
     * If user's avatar changes, update the default values
     */
    componentDidUpdate(prevProps){
        if (prevProps.userInfo.avatar.main !== this.props.userInfo.avatar.main) this.setState({
            ...this.state,
            avatar: `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${this.props.userInfo.avatar.main}`,
            avatarName: 'Click to Change',
            avatarFile: ''
        });
    }

    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Removes the input after the file is selected
     */
    selectFile = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        document.body.appendChild(input);
        input.onchange = e => {
            let file = e.target.files[0];
            if (allowedExtensions.indexOf(file.type) !== -1){
                if (file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) alert(`Max individual file size exceeded. (Max: ${Math.round((Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) / (1024 * 1024))}MB)`) ;
                else this.setState({
                    ...this.state,
                    avatarName: e.target.files[0].name,
                    avatarFile: e.target.files[0],
                    avatar: URL.createObjectURL(e.target.files[0]),
                    newFileSelected: true
                }, () => document.body.removeChild(input));
            } else {
                document.body.removeChild(input)
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
        }
        input.click();
    }

    /**
     * Submit only if there isn't already a submission being sent
     * Set working
     * Make request to server
     * Update user details with new avatar
     * Hide modal
     */
    submit = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => {
            const fd = new FormData();
            fd.append('avatar', this.state.avatarFile, this.state.avatarName);
            axios.post('/dashboard/change-avatar', fd).then(res => this.setState({
                ...this.state,
                working: false,
                newFileSelected: false
            }, () => {
                this.props.change_user_details(res.data.userInfo);
                this.props.toggleShowModal();
            })).catch(err => this.setState({
                ...this.state,
                working: false
            }, () => {
                console.log(err);
                alert('An error occurred. Please try again later');
            }));
        });
    }

    render(){
        return (
            <>
              
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal id="change-avatar-modal" show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Change Avatar</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="d-flex justify-content-center">
                                    <MDBRipple onClick={this.selectFile} className="mx-auto max-w-100 cursor-pointer" rippleColor="primary">
                                        <p className="file-labels-dark position-absolute bottom-0 text-center w-100 mb-0 text-light cursor-pointer">{this.state.avatarName}</p>
                                        <img style={{maxHeight: '50vh', minWidth: '10rem'}} src={this.state.avatar} className="max-w-100 d-block mx-auto cursor-pointer"/>
                                    </MDBRipple>
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                {this.state.newFileSelected ?
                                <>
                                    {this.state.working ?
                                    <MDBBtn disabled color="primary"><Spinner size="sm" className="me-2" />Saving</MDBBtn> :
                                    <MDBBtn onClick={this.submit} color="primary"><i className="fas fa-save me-2"></i>Save</MDBBtn>}
                                </> : <></>}
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { change_user_details })(ChangeAvatarModal);