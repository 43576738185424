import React from "react";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import { connect } from "react-redux";
import { route } from "../../redux/actions";
import SampleProfile from "../../components/SampleProfile";
import { MDBBtn } from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import { v4 as uuid } from "uuid";

class Live extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.socket.onAny((event, data) => {
      if (
        [
          "new-emission",
          "vote",
          "like",
          "signalboost",
          "update-user",
          "remove-emission",
          "restore-emission",
          "ban",
          "restore-user",
          "private",
          "unprivate",
          "reply",
          "block",
          "unblock",
          "view",
          "views",
        ].indexOf(event) > -1
      ) {
        const socketEvent = {
          event: event,
          data: data,
          id: uuid(),
        };
        switch (event) {
          case "update-user":
            this.props.userEdit(data, socketEvent);
            break;
          case "ban":
            this.props.ban(data, socketEvent);
            break;
          case "restore-user":
            this.props.restoreUser(data, socketEvent);
            break;
          case "unblock":
            this.props.unblockOther(data, socketEvent);
            break;
          case "unprivate":
            this.props.unprivate(data, socketEvent);
            break;
          case "block":
            this.props.blockOther(data, socketEvent);
            break;
          case "private":
            this.props.privateProfile(data, socketEvent);
            break;
          default:
            this.props.pushSocketEvent(socketEvent);
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.socket.onAny(() => {});
  }

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 h-100"
      >
        <MDBBtn
          className="d-block ms-auto"
          disabled={this.props.checkingStreams}
          onClick={this.props.checkStreams}
          color="success"
        >
          {this.props.checkingStreams ? (
            <>
              <Spinner className="me-2" size="sm" />
              Checking
            </>
          ) : (
            <>
              <i className="fas fa-undo-alt me-2"></i>
              Check Again
            </>
          )}
        </MDBBtn>
        {this.props.profiles.length ? (
          <>
            {this.props.profiles
              .filter((profile) => profile.live)
              .sort((a, b) => b.live.viewers - a.live.viewers)
              .map((profile, p) => (
                <motion.div
                  key={profile._id}
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                >
                  <SampleProfile
                    profile={profile}
                    index={p}
                    live={true}
                    preventRouting={true}
                  />
                </motion.div>
              ))}
            {this.props.liveEnd ? (
              <></>
            ) : (
              <div className="py-4">
                {this.props.loadingMore.indexOf("live") > -1 ? (
                  <MDBBtn
                    size="lg"
                    disabled
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    <Spinner color="primary" size="sm" className="me-2" />
                    Please Wait
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    size="lg"
                    onClick={() => this.props.loadMore("live")}
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    See More
                  </MDBBtn>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <h5 className="text-center display-6 mt-4">
              Nobody is Currently Live
            </h5>
          </>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route })(Live);
