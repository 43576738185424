import React from "react";
import t from "../../utilities/transitions";
import { motion } from "framer-motion";
import EmissionDetailed from "./EmissionDetailed";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import Spinner from "../Spinner";
import { LinearProgress } from "@mui/material";

class EmissionList extends React.Component {
  constructor(props) {
    super();
    this.flavor = props.flavor;
    this.state = {
      loaded: false,
      loadMoreScrollHeight: 0,
    };
  }

  componentDidMount() {
    document.getElementById("root").addEventListener("scroll", this.scroll);
    if (this.props.loaded)
      this.setState({
        ...this.state,
        loaded: true,
      });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded)
      this.setState({
        ...this.state,
        loaded: true,
      });
    if (
      this.state.loadMoreScrollHeight &&
      prevProps.emissions.length !== this.props.emissions.length
    )
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            loadMoreScrollHeight: 0,
          }),
        1000
      );
  }

  componentWillUnmount() {
    document.getElementById("root").removeEventListener("scroll", this.scroll);
  }

  scroll = (e) => {
    if (
      this.flavor !== "emission" &&
      !(
        this.flavor === "feed-recent" &&
        (this.props.totalEnd || this.props.loadingMore?.indexOf("recent") > -1)
      ) &&
      !(
        this.flavor === "feed-following" &&
        (this.props.followEnd ||
          this.props.checkingNewer.indexOf("following") > -1)
      ) &&
      !(
        this.flavor === "feed-popular" &&
        (this.props.popularEnd ||
          this.props.checkingNewer.indexOf("popular") > -1)
      ) &&
      !(
        this.flavor === "search" &&
        (this.props.emissionEndReached ||
          this.props.loadingMore.indexOf("emissions") > -1)
      ) &&
      !(
        this.flavor === "tag" &&
        (this.props.endReached || this.props.loadingMore)
      ) &&
      !this.state.loadMoreScrollHeight &&
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        0.1 * e.target.clientHeight
    ) {
      this.setState(
        {
          ...this.state,
          loadMoreScrollHeight: e.target.scrollHeight,
        },
        () => {
          switch (this.flavor) {
            case "feed-recent":
              this.props.loadMore("recent");
              break;
            case "feed-following":
              this.props.loadMore("following");
              break;
            case "feed-popular":
              this.props.loadMore("popular");
              break;
            case "search":
              this.props.loadMore("emissions");
              break;
            case "tag":
              this.props.loadMore();
              break;
            default:
              console.log("oob flavor scroll", this.flavor);
          }
        }
      );
    }
  };

  /**
   * Organizes emissions into a tree for the Emission page
   * Root of the tree is the main emission
   * Branches are replies/replies to replies etc
   *
   *
   * @returns Tree of emissions
   */
  filterEmissions = () => {
    const emissions = this.props.emissions
      .filter((e) => e.emissionID !== this.props.emissionID)
      .sort((a, b) => b.replyID - a.replyID);
    let root = {
      ...this.props.emissions.find(
        (e) => e.emissionID === this.props.emissionID
      ),
      children: [],
    };
    emissions.forEach((emission) => {
      if (emission.replyEmission.replyEmission) {
        if (
          root.emissionID === emission.replyEmission.replyEmission.emissionID
        ) {
          let found = false;
          root.children.forEach((child, c) => {
            if (child.emissionID === emission.replyEmission.emissionID) {
              found = true;
              root.children[c].children.push(emission);
            }
          });
          if (!found)
            root.children.push({
              ...emission.replyEmission,
              children: [emission],
            });
        }
      }
      if (emission.replyEmission.emissionID === root.emissionID) {
        if (!root.children.find((c) => c.emissionID === emission.emissionID))
          root.children.push({
            ...emission,
            children: [],
          });
      }
    });
    root.children = root.children.sort((a, b) => b.emissionID - a.emissionID);
    return [root];
  };

  render() {
    switch (this.flavor) {
      case "emission":
        const mainEmission = this.props.emissions.find(
          (e) => e.emissionID === this.props.emissionID
        );

        if (!this.props.loaded)
          return (
            <>
              <h5 className="text-center mb-4 mt-5 display-6">
                Getting{" "}
                <span className="text-capitalize">
                  {process.env.REACT_APP_EMISSION_NAME}
                </span>
              </h5>
              <LinearProgress />
            </>
          );

        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
          >
            {mainEmission.replyEmission ? (
              <div className="px-4 pt-4 pb-2 bg-emphasis rounded-6">
                <h5>
                  <i
                    style={{ color: "#FFAB00" }}
                    className="fas fa-comments me-2"
                  ></i>
                  Thread
                </h5>
                <hr></hr>
                {mainEmission.replyEmission &&
                mainEmission.replyEmission.replyEmission ? (
                  <EmissionDetailed
                    emission={mainEmission.replyEmission.replyEmission}
                    loaded={this.state.loaded}
                    clickEmissionBody={this.props.clickEmissionBody}
                    vote={this.props.vote}
                    polls={this.props.polls}
                    submitVotes={this.props.submitVotes}
                    pollsSubmitting={this.props.pollsSubmitting}
                    selectFile={this.props.selectFile}
                    signalBoost={this.props.signalBoost}
                    like={this.props.like}
                    copyEmissionLink={this.props.copyEmissionLink}
                    emissionCopied={this.props.emissionCopied}
                    reply={this.props.reply}
                    index={true}
                    userInfo={this.props.userInfo}
                    route={this.props.route}
                    remove={this.props.remove}
                    restore={this.props.restore}
                    report={this.props.report}
                    updateEmission={this.updateEmission}
                    loadingMore={this.props.loadingMore}
                    seeMore={this.props.seeMore}
                    setPollModal={this.props.setPollModal}
                    animation={t.fade_out}
                  />
                ) : (
                  <></>
                )}
                {mainEmission.replyEmission ? (
                  <EmissionDetailed
                    emission={mainEmission.replyEmission}
                    loaded={this.props.loaded}
                    animation={t.fade_out}
                    clickEmissionBody={this.props.clickEmissionBody}
                    vote={this.props.vote}
                    polls={this.props.polls}
                    submitVotes={this.props.submitVotes}
                    pollsSubmitting={this.props.pollsSubmitting}
                    selectFile={this.props.selectFile}
                    signalBoost={this.props.signalBoost}
                    like={this.props.like}
                    copyEmissionLink={this.props.copyEmissionLink}
                    emissionCopied={this.props.emissionCopied}
                    reply={this.props.reply}
                    index={true}
                    userInfo={this.props.userInfo}
                    route={this.props.route}
                    remove={this.props.remove}
                    restore={this.props.restore}
                    report={this.props.report}
                    updateEmission={this.props.updateEmission}
                    loadingMore={this.props.loadingMore}
                    seeMore={this.props.seeMore}
                    setPollModal={this.props.setPollModal}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {this.filterEmissions().map((emission, e) => (
              <EmissionDetailed
                emission={emission}
                loaded={this.props.loaded}
                animation={t.fade_out}
                clickEmissionBody={this.props.clickEmissionBody}
                vote={this.props.vote}
                polls={this.props.polls}
                submitVotes={this.props.submitVotes}
                pollsSubmitting={this.props.pollsSubmitting}
                selectFile={this.props.selectFile}
                signalBoost={this.props.signalBoost}
                like={this.props.like}
                copyEmissionLink={this.props.copyEmissionLink}
                emissionCopied={this.props.emissionCopied}
                reply={this.props.reply}
                index={e}
                userInfo={this.props.userInfo}
                route={this.props.route}
                key={emission._id}
                remove={this.props.remove}
                restore={this.props.restore}
                report={this.props.report}
                highlight={true}
                updateEmission={this.props.updateEmission}
                loadingMore={this.props.loadingMore}
                seeMore={this.props.seeMore}
                setPollModal={this.props.setPollModal}
              />
            ))}
          </motion.div>
        );
      case "feed-recent":
        const emissions = this.props.emissions.sort(
          (a, b) => b.emissionID - a.emissionID
        );
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            className="pt-4 h-100"
          >
            {this.props.checkingNewer.indexOf("recent") > -1 ? (
              <MDBBtn className="d-block ms-auto" color="success" disabled>
                <Spinner size="sm" className="me-2" />
                Checking
              </MDBBtn>
            ) : (
              <MDBBtn
                className="d-block ms-auto"
                onClick={() => this.props.checkNewer("recent")}
                color="success"
              >
                <i className="fas fa-undo-alt me-2"></i>
                Check for Newer
              </MDBBtn>
            )}
            {emissions.map((emission, e) => (
              <EmissionDetailed
                emission={emission}
                loaded={this.props.loaded}
                animation={t.fade_out}
                clickEmissionBody={this.props.clickEmissionBody}
                vote={this.props.vote}
                polls={this.props.polls}
                submitVotes={this.props.submitVotes}
                pollsSubmitting={this.props.pollsSubmitting}
                selectFile={this.props.selectFile}
                signalBoost={this.props.signalBoost}
                like={this.props.like}
                copyEmissionLink={this.props.copyEmissionLink}
                emissionCopied={this.props.emissionCopied}
                reply={this.props.reply}
                index={e}
                userInfo={this.props.userInfo}
                route={this.props.route}
                key={emission._id}
                remove={this.props.remove}
                restore={this.props.restore}
                report={this.props.report}
                updateEmission={this.props.updateEmission}
                loadingMore={this.props.loadingMore}
                seeMore={this.props.seeMore}
                setPollModal={this.props.setPollModal}
              />
            ))}
            {!this.props.totalEnd && (
              <div className="py-4">
                {this.props.loadingMore.indexOf("recent") > -1 ? (
                  <MDBBtn
                    size="lg"
                    disabled
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    <Spinner color="primary" size="sm" className="me-2" />
                    Please Wait
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    size="lg"
                    onClick={() => this.props.loadMore("recent")}
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    See More
                  </MDBBtn>
                )}
              </div>
            )}
          </motion.div>
        );
      case "feed-following":
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            className="pt-4 h-100"
          >
            {this.props.emissions.filter((e) => e.following).length ? (
              <>
                {this.props.checkingNewer.indexOf("following") > -1 ? (
                  <MDBBtn className="d-block ms-auto" color="success" disabled>
                    <Spinner size="sm" className="me-2" />
                    Checking
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    className="d-block ms-auto"
                    onClick={() => this.props.checkNewer("following")}
                    color="success"
                  >
                    <i className="fas fa-undo-alt me-2"></i>
                    Check for Newer
                  </MDBBtn>
                )}
                {this.props.emissions
                  .filter((e) => e.following)
                  .sort((a, b) => b.emissionID - a.emissionID)
                  .map((emission, e) => (
                    <EmissionDetailed
                      emission={emission}
                      loaded={this.props.loaded}
                      animation={t.fade_out}
                      clickEmissionBody={this.props.clickEmissionBody}
                      vote={this.props.vote}
                      polls={this.props.polls}
                      submitVotes={this.props.submitVotes}
                      pollsSubmitting={this.props.pollsSubmitting}
                      selectFile={this.props.selectFile}
                      signalBoost={this.props.signalBoost}
                      like={this.props.like}
                      copyEmissionLink={this.props.copyEmissionLink}
                      emissionCopied={this.props.emissionCopied}
                      reply={this.props.reply}
                      index={e}
                      userInfo={this.props.userInfo}
                      route={this.props.route}
                      key={emission._id}
                      remove={this.props.remove}
                      restore={this.props.restore}
                      report={this.props.report}
                      updateEmission={this.props.updateEmission}
                      loadingMore={this.props.loadingMore}
                      seeMore={this.props.seeMore}
                      setPollModal={this.props.setPollModal}
                    />
                  ))}
                {!this.props.followEnd && (
                  <div className="py-4">
                    {this.props.loadingMore.indexOf("following") > -1 ? (
                      <MDBBtn
                        size="lg"
                        disabled
                        className="d-block mx-auto w-50"
                        rippleColor="primary"
                        outline
                        color="primary"
                        rounded
                      >
                        <Spinner color="primary" size="sm" className="me-2" />
                        Please Wait
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        size="lg"
                        onClick={() => this.props.loadMore("following")}
                        className="d-block mx-auto w-50"
                        rippleColor="primary"
                        outline
                        color="primary"
                        rounded
                      >
                        See More
                      </MDBBtn>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <h5 className="text-center display-6 mt-4">
                  No{" "}
                  <span className="text-capitalize">
                    {process.env.REACT_APP_EMISSION_PLURAL}
                  </span>{" "}
                  Found
                </h5>
                <p className="text-center">
                  You are either not{" "}
                  <span className="text-lowercase">
                    {process.env.REACT_APP_FOLLOW_CURRENT}
                  </span>{" "}
                  anyone, or nobody that you{" "}
                  <span className="text-lowercase">
                    {process.env.REACT_APP_FOLLOW_VERB}
                  </span>{" "}
                  has made any{" "}
                  <span className="text-lowercase">
                    {process.env.REACT_APP_EMISSION_PLURAL}
                  </span>
                  .
                </p>
              </>
            )}
          </motion.div>
        );
      case "feed-popular":
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            className="pt-4 h-100"
          >
            {this.props.emissions.filter((e) => e.popular).length ? (
              <>
                {this.props.emissions
                  .filter((e) => e.popular)
                  .sort((a, b) => {
                    const aScore =
                      a.views +
                      25 * a.likes +
                      50 * a.replies +
                      75 * a.signalBoosts;
                    const bScore =
                      b.views +
                      25 * b.likes +
                      50 * b.replies +
                      75 * b.signalBoosts;
                    return bScore - aScore;
                  })
                  .map((emission, index) => {
                    return (
                      <EmissionDetailed
                        emission={emission}
                        loaded={this.props.loaded}
                        animation={t.fade_out}
                        clickEmissionBody={this.props.clickEmissionBody}
                        vote={this.props.vote}
                        polls={this.props.polls}
                        submitVotes={this.props.submitVotes}
                        pollsSubmitting={this.props.pollsSubmitting}
                        selectFile={this.props.selectFile}
                        signalBoost={this.props.signalBoost}
                        like={this.props.like}
                        copyEmissionLink={this.props.copyEmissionLink}
                        emissionCopied={this.props.emissionCopied}
                        reply={this.props.reply}
                        index={index}
                        userInfo={this.props.userInfo}
                        route={this.props.route}
                        key={emission._id}
                        remove={this.props.remove}
                        restore={this.props.restore}
                        report={this.props.report}
                        updateEmission={this.props.updateEmission}
                        loadingMore={this.props.loadingMore}
                        seeMore={this.props.seeMore}
                        setPollModal={this.props.setPollModal}
                      />
                    );
                  })}

                {!this.props.popularEnd && (
                  <div className="py-4">
                    {this.props.loadingMore.indexOf("popular") > -1 ? (
                      <MDBBtn
                        size="lg"
                        disabled
                        className="d-block mx-auto w-50"
                        rippleColor="primary"
                        outline
                        color="primary"
                        rounded
                      >
                        <Spinner color="primary" size="sm" className="me-2" />
                        Please Wait
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        size="lg"
                        onClick={() => this.props.loadMore("popular")}
                        className="d-block mx-auto w-50"
                        rippleColor="primary"
                        outline
                        color="primary"
                        rounded
                      >
                        See More
                      </MDBBtn>
                    )}
                  </div>
                )}
              </>
            ) : (
              <h5 className="text-center mt-4 display-6">
                No popular{" "}
                <span className="text-lowercase">
                  {process.env.REACT_APP_EMISSION_PLURAL}
                </span>{" "}
                have been made recently
              </h5>
            )}
          </motion.div>
        );
      case "search":
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_left}
            animate={t.normalize}
            initial={t.fade_out_left}
            className="py-4 h-100"
          >
            {this.props.emissions.length ? (
              <>
                {this.props.emissions
                  .sort((a, b) => a._id.localeCompare(b._id))
                  .sort((a, b) => b.score - a.score)
                  .map((emission, e) => (
                    <EmissionDetailed
                      emission={emission}
                      loaded={this.props.loaded}
                      animation={t.fade_out}
                      clickEmissionBody={this.props.clickEmissionBody}
                      vote={this.props.vote}
                      polls={this.props.polls}
                      submitVotes={this.props.submitVotes}
                      pollsSubmitting={this.props.pollsSubmitting}
                      selectFile={this.props.selectFile}
                      signalBoost={this.props.signalBoost}
                      like={this.props.like}
                      copyEmissionLink={this.props.copyEmissionLink}
                      emissionCopied={this.props.emissionCopied}
                      reply={this.props.reply}
                      index={e}
                      userInfo={this.props.userInfo}
                      route={this.props.route}
                      key={emission._id}
                      remove={this.props.remove}
                      restore={this.props.restore}
                      report={this.props.report}
                      updateEmission={this.props.updateEmission}
                      loadingMore={this.props.loadingMore}
                      seeMore={this.props.seeMore}
                      setPollModal={this.props.setPollModal}
                    />
                  ))}
                {!this.props.emissionEndReached && (
                  <>
                    {this.props.loadingMore.indexOf("emissions") > -1 ? (
                      <MDBBtn
                        size="lg"
                        disabled
                        className="d-block mx-auto w-50"
                        rippleColor="primary"
                        outline
                        color="primary"
                        rounded
                      >
                        <Spinner color="primary" size="sm" className="me-2" />
                        Please Wait
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        size="lg"
                        onClick={() => this.props.loadMore("emissions")}
                        className="d-block mx-auto w-50"
                        rippleColor="primary"
                        outline
                        color="primary"
                        rounded
                      >
                        See More
                      </MDBBtn>
                    )}
                  </>
                )}
              </>
            ) : (
              <h5 className="text-center display-6 mt-4">
                No {process.env.REACT_APP_EMISSION_PLURAL} found
              </h5>
            )}
          </motion.div>
        );
      case "tag":
        const list = this.props.emissions.filter((e) =>
          new RegExp(`#${this.props.tag}`, "i").test(e.html)
        );
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            className="pt-4 h-100"
          >
            <MDBContainer>
              {this.props.loaded ? (
                <motion.div
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                >
                  {list.length ? (
                    <>
                      <h5 className="display-6 text-center mt-5 text-break">
                        #{this.props.tag}
                      </h5>
                      <hr></hr>
                      {list
                        .sort((a, b) => b.emissionID - a.emissionID)
                        .map((emission, e) => (
                          <EmissionDetailed
                            emission={emission}
                            loaded={this.state.loaded}
                            animation={this.props.animation}
                            clickEmissionBody={this.props.clickEmissionBody}
                            vote={this.props.vote}
                            polls={this.props.polls}
                            submitVotes={this.props.submitVotes}
                            pollsSubmitting={this.props.pollsSubmitting}
                            selectFile={this.props.selectFile}
                            signalBoost={this.props.signalBoost}
                            like={this.props.like}
                            copyEmissionLink={this.props.copyEmissionLink}
                            emissionCopied={this.props.emissionCopied}
                            reply={this.props.reply}
                            index={e}
                            userInfo={this.props.userInfo}
                            route={this.props.route}
                            key={emission._id}
                            remove={this.props.remove}
                            restore={this.props.restore}
                            report={this.props.report}
                            updateEmission={this.props.updateEmission}
                            loadingMore={this.props.loadingMore}
                            seeMore={this.props.seeMore}
                            setPollModal={this.props.setPollModal}
                          />
                        ))}
                      {!this.props.endReached && (
                        <div className="py-4">
                          {this.props.loadingMore ? (
                            <MDBBtn
                              size="lg"
                              disabled
                              className="d-block mx-auto w-50"
                              rippleColor="primary"
                              outline
                              color="primary"
                              rounded
                            >
                              <Spinner
                                color="primary"
                                size="sm"
                                className="me-2"
                              />
                              Please Wait
                            </MDBBtn>
                          ) : (
                            <MDBBtn
                              size="lg"
                              onClick={() => this.props.loadMore()}
                              className="d-block mx-auto w-50"
                              rippleColor="primary"
                              outline
                              color="primary"
                              rounded
                            >
                              See More
                            </MDBBtn>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <h5 className="text-center mt-4 display-6 mb-4 mt-5">
                      No{" "}
                      <span className="text-capitalize">
                        {process.env.REACT_APP_EMISSION_PLURAL}
                      </span>{" "}
                      found for{" "}
                      <span className="text-break">#{this.props.tag}</span>
                    </h5>
                  )}
                </motion.div>
              ) : (
                <>
                  <h5 className="text-center mt-4 display-6 mb-4 mt-5">
                    Fetching{" "}
                    <span className="text-capitalize">
                      {process.env.REACT_APP_EMISSION_PLURAL}
                    </span>
                  </h5>
                  <LinearProgress />
                </>
              )}
            </MDBContainer>
          </motion.div>
        );
      default:
        console.log("oob emission page flavor", this.flavor);
        return <></>;
    }
  }
}

export default EmissionList;
