import React from "react";
import { connect } from "react-redux";
import { route, clear_temp_action, set_temp_action } from "../../redux/actions";
import { MDBTooltip } from "mdb-react-ui-kit";
import LoginModal from "../loginModal/LoginModal";
import ToastMisc from "../notifications/ToastMisc";
import GlobalEmissionInputModal from "./GlobalEmissionInputModal";

class GlobalEmissionInput extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether an emission is in the process of being submitted
       * emissionModalShown: Boolean - Whether the emission input modal is shown
       * loginModalShown: Boolean - Whether the login modal is shown
       * notificationIcon: JSX - The notification icon
       * notificationText: String - String of the notification
       */
      working: false,
      emissionModalShown: false,
      loginModalShown: false,
      notificationIcon: <></>,
      notificationText: "",
    };
    this.toastRef = React.createRef(null); // Clicked in order to spawn MDB toast notification
  }

  /**
   * If user logs in using the login modal:
   * * Hide login modal
   * * Notify user that they have logged in
   * * Open Emission modal
   * * Clear temp action
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tempAction &&
      prevProps.tempAction.label !==
        `make a new ${process.env.REACT_APP_EMISSION_NAME}` &&
      this.props.tempAction.label ===
        `make a new ${process.env.REACT_APP_EMISSION_NAME}`
    )
      this.toggleLoginModal();
    if (
      this.state.loginModalShown &&
      !prevProps.userInfo._id &&
      this.props.userInfo._id
    )
      this.setState(
        {
          ...this.state,
          notificationIcon: (
            <i className="fas fa-check-circle text-success me-2" />
          ),
          notificationText: `Logged in as @${this.props.userInfo.username}`,
          loginModalShown: false,
        },
        () => {
          this.toastRef.current.click();
          this.toggleEmissionModal();
        }
      );
    if (prevState.loginModalShown && !this.state.loginModalShown)
      this.props.clear_temp_action();
  }

  setLoginModal = (option) =>
    this.setState({
      ...this.state,
      loginModalShown: option,
    });

  toggleLoginModal = () =>
    this.setState({
      ...this.state,
      loginModalShown: !this.state.loginModalShown,
    });

  setEmissionModal = (option) =>
    this.setState({
      ...this.state,
      emissionModalShown: option,
    });

  toggleEmissionModal = () =>
    this.setState(
      {
        ...this.state,
        emissionModalShown: !this.state.emissionModalShown,
      },
      () => this.props.clear_temp_action()
    );

  render() {
    return (
      <>
        <button className="d-none" ref={this.toastRef} />
        <ToastMisc
          triggerRef={this.toastRef}
          icon={this.state.notificationIcon}
          text={this.state.notificationText}
        />
        <MDBTooltip
          wrapperProps={{
            size: "lg",
            floating: true,
            className: "bg-darkblu position-fixed p-2",
            style: {
              bottom: "1rem",
              right: "1rem",
              width: "4rem",
              height: "4rem",
            },
            onClick: () => {
              if (this.props.userInfo._id) this.toggleEmissionModal();
              else
                this.props.set_temp_action({
                  label: `make a new ${process.env.REACT_APP_EMISSION_NAME}`,
                });
            },
          }}
          title={`New ${process.env.REACT_APP_EMISSION_NAME}`}
          placement="left"
        >
          <div
            style={{
              backgroundImage: 'url("/icons/newjizz.png")',
            }}
            className="fit-images"
          ></div>
        </MDBTooltip>

        <LoginModal
          modalShown={this.state.loginModalShown}
          toggleShowModal={this.toggleLoginModal}
          setShowModal={this.setLoginModal}
          tempAction={this.props.tempAction}
        />
        <GlobalEmissionInputModal
          modalShown={this.state.emissionModalShown}
          toggleShowModal={this.toggleEmissionModal}
          setShowModal={this.setEmissionModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  clear_temp_action,
  set_temp_action,
})(GlobalEmissionInput);
