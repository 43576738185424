import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

class FeedNav extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <Tabs className="mt-2" value={this.props.tab} onChange={this.props.selectTab}>
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-wifi text-danger"></i>} 
                    iconPosition="start" 
                    label={process.env.REACT_APP_EMISSION_PLURAL} 
                    value="emissions" 
                    id="tab-emissions"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-users text-success"></i>} 
                    iconPosition="start" 
                    label="Users" 
                    value="users" 
                    id="tab-users"
                />
            </Tabs>
        )
    }
}

export default FeedNav;