import React from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRipple,
} from "mdb-react-ui-kit";
import Url from "url-parse";
import { Link } from "react-router-dom";
import t from "../utilities/transitions";
import { route } from "../redux/actions";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import axios from "axios";
import DeleteAccountModal from "./info/DeleteAccountModal";

const rules = [
  {
    title: `Prerequisites for using ${process.env.REACT_APP_NAME}`,
    html: `<p>To use ${process.env.REACT_APP_NAME}, you must be at least 13 years old, and there must be no US law, or law of any jurisdiction that applies to you, which prevents you from accessing the content and services here.</p>`,
  },
  {
    title: `You are responsible for everything that you post on ${process.env.REACT_APP_NAME}`,
    html: `<p>By using this service, you agree that all interactions you have on this service abide by all US laws, and all laws of all jurisdictions that apply to you. All content that you post on ${
      process.env.REACT_APP_NAME
    } must belong to you. Do not post copyrighted content that does not belong to you without permission from the copyright holder. If your copyrighted material is posted on ${
      process.env.REACT_APP_NAME
    } without your consent and you would like for it to be removed, please visit our <a class="links-generic text-blue text-decoration-none" href="${
      process.env.REACT_APP_HOST + "/contact"
    }">Contact Page</a> and fill out the form, specifying "DMCA" as the subject, and properly identifying yourself and providing links to all infringing content, and provide proof that you are the owner of said content.</p>`,
  },
  {
    title: `You give the general public the right to take screenshots and make archives of content that you put on ${process.env.REACT_APP_NAME}`,
    html: `<p>By using this service, you give permission for anyone, including people that you have blocked or banned, to take screenshots of your posts and/or make archives of them.</p>`,
  },
  {
    title: `You give Carbon Valley the right to use content that you put on ${process.env.REACT_APP_NAME} in promotional content without attribution or compensation`,
    html: `<p>By using this service, you give permission for Carbon Valley to take pictures and videos of your content, and use it in advertisements and other promotional pieces.</p>`,
  },
  {
    title: `You will not hold ${process.env.REACT_APP_NAME} liable for any content posted on this service.`,
    html: `<p>${process.env.REACT_APP_NAME} does not endorse or support, or guarantee the truthfulness of anything posted on this service. You agree not to hold ${process.env.REACT_APP_NAME} for any negative consequences arising from content posted on this service.</p>`,
  },
  {
    title: `You will not use ${process.env.REACT_APP_NAME} to plan or organize any illegal behavior.`,
    html: `<p>Users who use ${process.env.REACT_APP_NAME} to plan or organize any illegal behavior will be banned, and their information may be preserved and transmitted to the appropriate law enforcement agencies.</p>`,
  },
  {
    title: "Pornography is not Allowed",
    html: `<p>Pornography is not allowed on ${process.env.REACT_APP_NAME} regardless of the age of the participants and/or their consent. Pornography is defined as images of a sexual nature that have no artistic or other value. Lewd images that have comedic or other artistic value may be allowed pending permission from the administrators.</p>`,
  },
  {
    title:
      "Content that supports the legalization of pedophilia or beastiality, or the belief that Jewish people control various important societies are not allowed",
    html: `<p>${process.env.REACT_APP_NAME} allows for a wide variety of topics to be discussed, and would ask that discussions about pedophilia, beastiality, and/or the "Jewish Question" (JQ) take place off the site. Violators may be permanently banned from this service.</p>`,
  },
  {
    title: `You understand that ${process.env.REACT_APP_NAME} offers its services AS IS.`,
    html: `<p>You will not hold ${process.env.REACT_APP_NAME} responsible for any consequences of any data breach, glitch, or any actions committed by any malicious actor on ${process.env.REACT_APP_NAME}.</p>`,
  },
  {
    title: `${process.env.REACT_APP_NAME} reserves the right to change the Terms of Service for any reason.`,
    html: `<p>${process.env.REACT_APP_NAME} may change the Terms of Service at any time, and these changes will not be retroactive.</p>`,
  },
  {
    title: `Enforceability.`,
    html: `<p>If any of these terms are determined to be unenforceable, the rest shall be enforced.</p>`,
  },
  {
    title: `Privacy`,
    html: `
            <hr/>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <h6 class="mb-1">What we collect and why we collect it.</h6>
                    <p class="mb-1">Upon account creation, users must supply a username, password, and display name. This is to uniquely identify the user.</p>
                    <p class="mb-1">Upon account creation, users must supply an email address. This is so that the user can easily reset their password in case they forget it, and to help prevent spam accounts from using this service. This may be changed later.</p>
                    <p class="mb-1">Upon account creation and afterwards, users may optionally provide a bio, location, website, avatar, and background image.</p>
                    <p class="mb-1">While some of the information listed above will be publicly available, it is not sold to any third party.</p>
                </li>
                <li class="list-group-item">
                    <h6 class="mb-1">Cookies.</h6>
                    <p class="mb-1">Cookies are used to keep track of user session data and preferences only. Your activity on ${process.env.REACT_APP_NAME} is not saved anywhere outside of your session cookie, is deleted when you logout, and is not shared with any 3rd party.</p>
                    <p class="mb-1">${process.env.REACT_APP_NAME} does not use Google Analytics or any other SEO or user metrics scripts software.</p>
                </li>
            </ul>
        `,
  },
];

class Info extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * additionalRules: Array - List of additional, custom rules that the user created on their Carbon Valley dashboard
       */
      additionalRules: [],
      deleteAccountModalShown: false,
    };
  }

  // Load the additional rules on mount
  componentDidMount() {
    this.load();
  }

  /**
   * Load the additional, custom rules from the server
   *
   */
  load = () =>
    axios
      .get("/api/rules")
      .then((res) =>
        this.setState({
          ...this.state,
          additionalRules: res.data.rules,
        })
      )
      .catch((err) => {
        console.log("Error loading additional rules", err);
        setTimeout(this.load, 1000);
      });

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (href) {
        const url = new Url(href);
        if (url.hostname === window.location.hostname)
          this.props.route(url.pathname);
        else window.location = href;
      }
    }
  };

  setDeleteAccountModal = (option) =>
    this.setState({
      ...this.state,
      deleteAccountModalShown: option,
    });

  toggleDeleteAccountModal = () =>
    this.setState({
      ...this.state,
      deleteAccountModalShown: !this.state.deleteAccountModalShown,
    });

  render() {
    return (
      <motion.div
        className="py-4"
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <DeleteAccountModal
          modalShown={this.state.deleteAccountModalShown}
          setShowModal={this.setDeleteAccountModal}
          toggleShowModal={this.toggleDeleteAccountModal}
        />
        <MDBContainer>
          <h5 className="text-center display-6 mb-0 mt-5">Terms of Service</h5>
          <hr></hr>
          {rules.map((rule, r) => (
            <MDBCard key={rule.html} className={r ? "mt-3" : ""}>
              <MDBCardBody>
                <h5>{rule.title}</h5>
                <div
                  dangerouslySetInnerHTML={{ __html: rule.html }}
                  onClick={this.clickEmissionBody}
                ></div>
              </MDBCardBody>
            </MDBCard>
          ))}
          {this.props.userInfo._id && this.props.userInfo.user_id !== 1 && (
            <MDBCard className="mt-3">
              <MDBCardBody>
                <h5>Deleting Your Account</h5>
                <div>
                  If you would like to delete your account, you may request to
                  do so{" "}
                  <MDBRipple
                    className="text-blue fw-bold cursor-pointer"
                    onClick={this.toggleDeleteAccountModal}
                  >
                    {" "}
                    here.{" "}
                  </MDBRipple>
                  If you do not have access to the email that you used to create
                  your account, please{" "}
                  <Link
                    className="text-blue text-decoration-none fw-bold"
                    to={process.env.REACT_APP_HOST + "/contact"}
                  >
                    contact a site administrator.
                  </Link>
                </div>
              </MDBCardBody>
            </MDBCard>
          )}

          <motion.div
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
          >
            {this.state.additionalRules.map((rule) => (
              <MDBCard key={rule.html} className="mt-3">
                <MDBCardBody>
                  <h5>{rule.title}</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: rule.html }}
                    onClick={this.clickEmissionBody}
                  ></div>
                </MDBCardBody>
              </MDBCard>
            ))}
          </motion.div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route })(Info);
