import React from "react";
import { connect } from "react-redux";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import Chat from "../../../../components/goLiveModal/Chat";
import {
  clear_stream_chat,
  stream_end,
  stream_start,
} from "../../../../redux/actions";
import LinearProgress from "@mui/material/LinearProgress";

class LiveStreamModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * peer: false | Peer object
       * peerOpen: Boolean - Whether the peer has received the "open" event
       * stream: false | MediaStream of streamer
       * videoDimensions: Object - The height and width of the video stream
       * peerConnection: false | RtcConnection - The RTC connection to the live stream server
       */
      peer: false,
      peerOpen: false,
      stream: false,
      videoDimensions: {
        height: 0,
        width: 0,
      },
      peerConnection: false,
      dataConnection: false,
      networkProblems: false,
    };
  }

  /**
   * Initialize peer
   *
   */
  componentDidMount() {
    this.setState(
      {
        ...this.state,
        peer: new window.Peer(undefined, {
          host: process.env.REACT_APP_PEER_HOST,
          port: process.env.REACT_APP_PEER_PORT,
          secure: true,
        }),
      },
      () => {
        /**
         * When peer opens, set peer status to "open" in state, and if modal is displayed, connect to stream
         */
        this.state.peer.on("open", () =>
          this.setState(
            {
              ...this.state,
              peerOpen: true,
            },
            () => {
              if (this.props.modalShown) this.setStream();
            }
          )
        );

        /**
         * Stream server will call the user with the live stream when the user makes a peer connection
         * Answer with nothing
         */
        this.state.peer.on("call", (call) => {
          call.answer();

          /**
           * Get video dimensions, if any
           * Set stream, peer connection, and dimensions into state
           * Pipe stream to <video>
           */
          call.on("stream", async (stream) => {
            let videoDimensions = {
              height: 0,
              width: 0,
            };
            if (stream.getVideoTracks().length)
              videoDimensions = await h.getStreamDimensions(stream);
            this.setState(
              {
                ...this.state,
                stream: stream,
                videoDimensions: videoDimensions,
                peerConnection: call.peerConnection,
                networkProblems: false,
              },
              () =>
                setTimeout(() => {
                  const player = document.getElementById("streamer-viewer");
                  if (player) {
                    player.srcObject = stream;
                    player.play();
                    const secondaryVideo = document.getElementById(
                      "streamer-viewer-background"
                    );
                    secondaryVideo.srcObject = this.state.stream;
                    secondaryVideo.play();
                  } else
                    this.setState({
                      ...this.state,
                      screen: "error",
                      error: "An unknown error occurred",
                    });
                }, 350)
            );
          });

          /**
           * On disconnect
           *
           * Hide modal
           * Clear stream
           * Reset state
           */
          call.peerConnection.oniceconnectionstatechange = () => {
            if (
              ["failed", "disconnected"].indexOf(
                call.peerConnection.iceConnectionState
              ) > -1 &&
              this.props.profileInfo.user.live
            )
              this.setState({
                ...this.state,
                networkProblems: true,
              });
          };
        });
      }
    );
  }

  /**
   * Get stream if modal is displayed while profile is already streaming
   */
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.modalShown && this.props.modalShown && !this.state.stream) ||
      (this.props.modalShown &&
        this.props.profileInfo.user.live?.id !==
          prevProps.profileInfo.user.live?.id)
    ) {
      if (this.state.peerOpen) this.setStream();
    }
  }

  /**
   * Initiate peer connection with stream server
   */
  setStream = () => {
    this.setState(
      {
        ...this.state,
        dataConnection: this.state.peer.connect(
          this.props.profileInfo.user.live.id
        ),
      },
      () =>
        this.state.dataConnection.on("data", (data) => {
          if (data.event === "clip") {
            if (this.state.peerConnection)
              this.state.peerConnection.oniceconnectionstatechange = () => {};
            this.props.stream_start({
              ...this.props.profileInfo.user.live,
              id: data.peerID,
            });
          }
        })
    );
  };

  /**
   * Stream video meant to fit the container
   * Larger dimension will span 100% of the container, smaller one auto
   *
   * @returns String - CSS class
   */
  getVideoClasses = () => {
    const windowRatio = window.innerWidth / window.innerHeight;
    const cameraRatio =
      this.state.videoDimensions.width / this.state.videoDimensions.height;
    if (windowRatio > cameraRatio) return "h-100";
    else return "w-100";
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="fullscreen">
              <MDBModalContent>
                <MDBModalBody className="position-relative p-0 h-100 w-100 overflow-x-hidden overflow-y-hidden">
                  {this.state.stream ? (
                    <>
                      {this.state.stream.getVideoTracks().length ? (
                        <>
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out}
                            animate={t.normalize}
                            initial={t.fade_out}
                            className="h-100 w-100 position-absolute"
                            dangerouslySetInnerHTML={{
                              __html: `
                                                <video style="object-fit: cover;" class="h-100 w-100" muted autoplay id="streamer-viewer-background" />
                                            `,
                            }}
                            id="streamer-background-container"
                          ></motion.div>
                          {this.state.networkProblems ? (
                            <motion.section
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                              className="h-100 w-100 position-absolute d-flex justify-content-center align-items-center"
                            >
                              <MDBContainer className="text-white">
                                <h5 className="text-center mb-5 display-6">
                                  Disconnected from stream server. Attempting to
                                  reconnect
                                </h5>
                                <LinearProgress
                                  className="mt-5"
                                  color="inherit"
                                />
                              </MDBContainer>
                            </motion.section>
                          ) : (
                            <motion.div
                              transition={t.transition}
                              exit={t.fade_out}
                              animate={t.normalize}
                              initial={t.fade_out}
                              className="h-100 w-100 d-flex justify-content-center align-items-center position-absolute"
                              id="streamer-video-container"
                              dangerouslySetInnerHTML={{
                                __html: `
                                                <video class="${this.getVideoClasses()}" autoplay playsinline id="streamer-viewer" />
                                            `,
                              }}
                            ></motion.div>
                          )}
                        </>
                      ) : (
                        <motion.section
                          transition={t.transition}
                          exit={t.fade_out}
                          animate={t.normalize}
                          initial={t.fade_out}
                          className="h-100 w-100 d-flex justify-content-center align-items-center m-0"
                        >
                          <audio autoPlay id="streamer-viewer"></audio>
                          <i class="fas fa-volume-up fa-10x d-block mx-auto text-center"></i>
                        </motion.section>
                      )}
                    </>
                  ) : (
                    <MDBContainer>
                      <h5 className="text-center display-6 my-4">Connecting</h5>
                      <LinearProgress />
                    </MDBContainer>
                  )}
                  {this.state.stream ? <Chat chat="other" /> : <></>}
                  <div className="position-absolute d-flex live-viewers-close">
                    <div>
                      <div className="rounded-pill d-flex align-items-center me-4 p-3 bg-viewers w-max-content mb-4">
                        <h5 className="m-0">
                          {h.compiledNumber(this.props.viewers)}
                        </h5>
                        <i className="fas fa-eye d-block ms-2 text-primary fa-lg"></i>
                      </div>
                      <small
                        style={{ backgroundColor: "rgba(238, 238, 238, 0.5)" }}
                        className="rounded-4 text-wrap p-2 text-dark"
                      >
                        {this.props.profileInfo?.user?.live?.streamTitle}
                      </small>
                    </div>
                    <MDBBtn
                      color="none"
                      className="btn-close"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </div>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  clear_stream_chat,
  stream_end,
  stream_start,
})(LiveStreamModal);
