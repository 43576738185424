import React from "react";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import { MDBRipple } from "mdb-react-ui-kit";

class File extends React.Component {
  constructor(props) {
    super();
    this.file = props.file;
    this.state = {
      /**
       * playing: Boolean - Whether the file is an audio or video file and being played
       * playButtonSize: Number - The height and width of the video play button in pixels
       * videoDimensions: Object - The height and width of the video in pixels
       */
      playing: false,
      playButtonSize: 0,
      videoDimensions: {
        height: 0,
        width: 0,
      },
    };
  }

  // If the file is a video, set video dimensions
  componentDidMount() {
    if (this.file.type === "video")
      document
        .getElementById(this.file.main.split(".")[0])
        .addEventListener("loadeddata", this.setVideoDimensions);
  }

  /**
   * Sets the video dimensions
   * Play button will be 75x75 pixels unless the video's smallest dimension is less than 150 pixels
   * If less than 150 but larger than 10, half smallest dimension
   * If smaller than 10, 5 pixels
   */
  setVideoDimensions = () => {
    const video = document.getElementById(this.file.main.split(".")[0]);
    const smallerDimension =
      video.clientWidth > video.clientHeight
        ? video.clientHeight
        : video.clientWidth;
    this.setState({
      ...this.state,
      playButtonSize:
        smallerDimension > 10
          ? smallerDimension < 150
            ? smallerDimension / 2
            : 75
          : 5,
      videoDimensions: {
        height: video.clientHeight,
        width: video.clientWidth,
      },
    });
  };

  /**
   * Triggered when the user clicks the play button on a video
   * Plays the video
   */
  playMedia = () => {
    if (!this.state.playing)
      this.setState(
        {
          ...this.state,
          playing: true,
        },
        () => document.getElementById(this.file.main.split(".")[0]).play()
      );
  };

  /**
   * Triggered when the user stops playing an audio or video file
   * Stops the media
   */
  stopMedia = () =>
    this.setState({
      ...this.state,
      playing: false,
    });

  /**
   * Triggered when the user plays or pauses an audio file
   * Toggles the play
   *
   */
  toggleMedia = () =>
    this.setState(
      {
        ...this.state,
        playing: !this.state.playing,
      },
      () => {
        if (this.state.playing)
          document.getElementById(this.file.main.split(".")[0]).play();
        else document.getElementById(this.file.main.split(".")[0]).pause();
      }
    );

  render() {
    return (
      <motion.div
        className="h-100 d-flex justify-content-center align-items-center file-modal-blank-space"
        transition={t.transition}
        initial={this.props.enter}
        animate={t.normalize}
        exit={this.props.exit}
      >
        {(() => {
          switch (this.file.type) {
            case "audio":
              return (
                <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100 file-modal-blank-space">
                  <div className="d-flex justify-content-center align-items-center file-modal-blank-space">
                    <MDBRipple
                      rippleColor="light"
                      onClick={this.toggleMedia}
                      className="d-flex justify-content-center align-items-center cursor-pointer p-4 rounded-6"
                    >
                      <div className="h-max-content w-max-content">
                        {this.state.playing ? (
                          <motion.i
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                            className="fas fa-pause fa-10x text-light"
                          />
                        ) : (
                          <motion.i
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                            className="fas fa-play fa-10x text-light"
                          />
                        )}
                      </div>
                    </MDBRipple>
                  </div>
                  <motion.audio
                    src={`${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/audio/${this.file.main}`}
                    id={this.file.main.split(".")[0]}
                    controls={this.state.playing}
                    onPause={this.stopMedia}
                    transition={t.transition}
                    exit={t.fade_out_minimize}
                    animate={t.normalize}
                    initial={t.fade_out_minimize}
                  />
                </div>
              );
            case "video":
              return (
                <div className="d-flex justify-content-center align-items-center h-100 w-100 position-relative file-modal-blank-space">
                  <video
                    className="max-w-100 max-h-100 rounded-6"
                    src={`${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/videos/${this.file.main}`}
                    id={this.file.main.split(".")[0]}
                    controls={this.state.playing}
                    onPause={this.stopMedia}
                  />
                  {this.state.playing ? (
                    <></>
                  ) : (
                    <div className="position-absolute mx-auto top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center file-modal-blank-space">
                      <MDBRipple
                        style={{
                          height: `${this.state.videoDimensions.height}px`,
                          width: `${this.state.videoDimensions.width}px`,
                        }}
                        rippleColor="light"
                        onClick={this.playMedia}
                        className="d-flex justify-content-center align-items-center cursor-pointer"
                      >
                        <motion.i
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                          style={{
                            color: "rgba(255, 255, 255, 0.5)",
                            zIndex: 30,
                            fontSize: `${this.state.playButtonSize}px`,
                          }}
                          className="fas fa-play text-light"
                        ></motion.i>
                      </MDBRipple>
                    </div>
                  )}
                </div>
              );
            case "image":
              return (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center file-modal-blank-space">
                  <img
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${this.file.main}`
                      )
                    }
                    src={`${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${this.file.main}`}
                    className="d-block max-h-100 max-w-100 cursor-pointer rounded-6"
                  />
                </div>
              );
          }
        })()}
      </motion.div>
    );
  }
}

export default File;
