import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import GoLiveScreen from "./GoLiveScreen";
import axios from "axios";
import { set_live } from "../../redux/actions";
import { live_title_schema } from "../../utilities/validations";
import Spinner from "../Spinner";

/**
 * IMPORTANT: Local media stream (state.stream) is a completely different object from the stream that is being broadcast live via RTC
 * You can completely nuke all the tracks in the state.stream, and they will still be broadcast
 * To alter the stream that is broadcast, you must manipulate the senders in state.peerConnection
 */

class GoLiveModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * videoDevices: Array - All of the video devices returned by enumerateDevices()
       * stream: false | MediaStream - The user's current media stream
       * cameraDirection: String - "user" | "environment" - The direction of the camera
       * showCameraToggleButton: Boolean - Whether the user has more than one video device
       * screen: String - "setup" | "main" | "error" - The current view
       * error: String - The error message to be displayed on the error page
       * microphone: Boolean - Whether the user has enabled their microphone
       * cameraEnabled: Boolean - Whether the user has enabled their camera
       * videoDimensions: Object - height and width of the user's camera video if used
       * live: Boolean - Whether the user is broadcasting live
       * working: Boolean - Whether the stream is in the process of starting
       * peerID: false | Peer _id
       * peerOpen: Boolean - Whether the Peer has opened a connection with the peer server
       * dataConnection: Peer dataConnection between user and stream server
       * peerconnection: Peer peerConnection between user and stream server
       * logoBitmap: false | ImageBitmap - Bitmap of the instance logo that can be drawn onto a canvas and used to make a dummy video track if necessary
       * streamTitle: Object - The stream title data (value, error, etc)
       */
      videoDevices: [],
      audioDevices: [],
      stream: false,
      cameraDirection: "user",
      showCameraToggleButton: false,
      screen: "setup",
      error: "An error occurred. Refresh the page and try again.",
      microphoneEnabled: true,
      cameraEnabled: true,
      videoDimensions: {
        height: 0,
        width: 0,
      },
      live: false,
      working: false,
      peerID: false,
      peerOpen: false,
      dataConnection: false,
      peerConnection: false,
      logoBitmap: false,
      streamTitle: {
        id: "streamTitle",
        invalid: false,
        value: "Going Live",
        error: "",
      },
      clipCount: 1,
      networkProblems: false,
    };
  }

  /**
   * Set bitmap
   * Open a peer connection
   *
   */
  componentDidMount() {
    this.setBitmap();
    this.setState(
      {
        ...this.state,
        peer: new window.Peer(undefined, {
          host: process.env.REACT_APP_PEER_HOST,
          port: process.env.REACT_APP_PEER_PORT,
          secure: true,
        }),
      },
      () => {
        /**
         * When peer connection opens, set peerOpen true so that the user can start their stream
         */
        this.state.peer.on("open", () =>
          this.setState({
            ...this.state,
            peerOpen: true,
          })
        );

        /**
         * Connections will be initiated by the stream server
         * At this point, the user has already initiated a live stream and the main server has told the stream server to start
         *
         * Create a new MediaStream, streamToSend, from state.stream to send to the server
         * streamToSend is separate from state.stream
         * Stream must start with an audio track and video track to avoid buggy renegotiation
         * When stream is saved, dummy streams will not be used (i.e. audio-only will be saved as webm audio)
         * If audio but no video:
         * * Create <canvas>
         * * Draw state.logoBitmap onto canvas
         * * Capture canvas stream (cannot be passed into RTC stream)
         * * Create <video>
         * * Set src to canvas stream
         * * Capture video stream
         * * Add video track to streamToSend
         *
         * If video but no audio:
         * * Grab blank-audio <audio> element loaded in background
         * * Play
         * * Capture stream
         * * Add to streamToSend
         *
         * If audio and video, add each track to stream to send
         *
         * Call stream server with streamToSend
         * If call fails or gets disconnected, end stream
         * Ping server to activate stream, which will make user's media stream public to all
         */
        this.setPeerConnection();
      }
    );
  }

  /**
   * Set user devices when modal is shown
   * If modal is closed but user is still live, pipe stream to mini video player
   * If modal is closed and user is not live but modal is collecting devices, close state.stream and reset
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.networkProblems && !this.state.networkProblems) {
      const video = document.getElementById(
        this.props.goLiveModalShown
          ? "streamer-video-main"
          : "streamer-video-mini"
      );
      if (video) {
        video.srcObject = this.state.stream;
        video.play();
      } else
        this.setState({
          ...this.state,
          screen: "error",
          error: "An unknown error occurred",
        });
    }
    if (prevProps.userInfo.live && !this.props.userInfo.live) {
      this.setState(
        {
          ...this.state,
          live: false,
          working: false,
          clipCount: 1,
          networkProblems: false,
        },
        () => {
          if (this.state.peerConnection && this.state.peerConnection.close)
            this.state.peerConnection.close();
        }
      );
    }
    if (
      !prevProps.goLiveModalShown &&
      this.props.goLiveModalShown &&
      !this.state.stream
    )
      this.setDevices();
    if (
      this.state.live &&
      prevProps.goLiveModalShown !== this.props.goLiveModalShown
    ) {
      if (this.state.cameraEnabled) {
        setTimeout(() => {
          try {
            if (this.props.goLiveModalShown) {
              const oldVideo = document.getElementById("streamer-video-mini");
              if (oldVideo?.stop) oldVideo.stop();
              if (!this.state.networkProblems) {
                const video = document.getElementById("streamer-video-main");
                video.srcObject = this.state.stream;
                video.play();
                const backgroundVideo = document.getElementById(
                  "streamer-video-background"
                );
                backgroundVideo.srcObject = this.state.stream;
                backgroundVideo.play();
              }
            } else {
              const oldBackground = document.getElementById(
                "streamer-video-background"
              );
              if (oldBackground?.stop) oldBackground.stop();
              const oldVideo = document.getElementById("streamer-video-main");
              if (oldVideo?.stop) oldVideo.stop();
              const video = document.getElementById("streamer-video-mini");
              video.srcObject = this.state.stream;
              video.play();
            }
          } catch (err) {
            console.log("video error", err);
            this.setState({
              ...this.state,
              screen: "error",
              error: "An unknown error occurred",
            });
          }
        }, 350);
      }
    }
    if (
      !this.state.live &&
      this.state.stream &&
      this.state.stream.getTracks &&
      !this.state.working &&
      !this.props.goLiveModalShown
    ) {
      this.state.stream.getTracks().forEach((track) => track.stop());
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            videoDevices: [],
            audioDevices: [],
            stream: false,
            cameraDirection: "user",
            showCameraToggleButton: false,
            screen: "setup",
            error: "An error occurred. Refresh the page and try again.",
            microphoneEnabled: true,
            cameraEnabled: true,
            videoDimensions: {
              height: 0,
              width: 0,
            },
            live: false,
            working: false,
            peerID: false,
            networkProblems: false,
            clipCount: 1,
          }),
        500
      );
    }
  }

  setPeerConnection = () => {
    this.state.peer.on("connection", (connection) => {
      try {
        connection.on("data", async (data) => {
          if (data === "clip") {
            this.state.peerConnection.oniceconnectionstatechange = () => {};
            this.setState(
              {
                ...this.state,
                peer: new window.Peer(undefined, {
                  host: process.env.REACT_APP_PEER_HOST,
                  port: process.env.REACT_APP_PEER_PORT,
                  secure: true,
                }),
                clipCount: this.state.clipCount + 1,
              },
              () =>
                this.state.peer.on("open", () =>
                  this.setState(
                    {
                      ...this.state,
                      peerOpen: true,
                    },
                    () => {
                      this.setPeerConnection();
                      this.props.socket.emit("start-stream", {
                        peerID: this.state.peer._id,
                        streamTitle: this.state.streamTitle.value,
                        clipCount: this.state.clipCount,
                      });
                    }
                  )
                )
            );
          }
        });
        if (!this.state.stream)
          this.setState({
            ...this.state,
            screen: "error",
            error: "An unknown error occurred",
          });
        else {
          const streamToSend = new MediaStream();
          if (!this.state.stream.getVideoTracks().length) {
            const video = document.createElement("canvas");
            video.getContext("2d").drawImage(this.state.logoBitmap, 0, 0);
            const dummyTrack = video.captureStream(1000);
            const videoElement = document.createElement("video");
            videoElement.srcObject = dummyTrack;
            videoElement.play();
            const videoTrack = videoElement.captureStream(1000);
            streamToSend.addTrack(videoTrack.getVideoTracks()[0]);
          } else streamToSend.addTrack(this.state.stream.getVideoTracks()[0]);
          if (!this.state.stream.getAudioTracks().length) {
            const audio = document.getElementById("blank-audio");
            audio.play();
            const audioTrack = audio.captureStream(1000);
            streamToSend.addTrack(audioTrack.getAudioTracks()[0]);
          } else streamToSend.addTrack(this.state.stream.getAudioTracks()[0]);
          const call = this.state.peer.call(connection.peer, streamToSend);
          if (this.state.clipCount > 1) {
            this.state.dataConnection.send({
              event: "clip",
              peerID: connection.peer,
            });
          }
          call.peerConnection.oniceconnectionstatechange = (e) => {
            if (
              this.state.networkProblems &&
              call.peerConnection.iceConnectionState === "connected"
            )
              this.setState({
                ...this.state,
                networkProblems: false,
              });
            if (
              ["failed", "disconnected"].indexOf(
                call.peerConnection.iceConnectionState
              ) > -1 &&
              this.state.live
            )
              this.setState({
                ...this.state,
                networkProblems: true,
              });
          };
          axios
            .post("/profile/activate-stream", {
              peerID: connection.peer,
              streamTitle: this.state.streamTitle.value,
              clipCount: this.state.clipCount,
            })
            .then(() =>
              this.setState(
                {
                  ...this.state,
                  live: true,
                  working: false,
                  peerID: connection.peer,
                  peerConnection: call.peerConnection,
                  dataConnection: connection,
                },
                this.props.set_live
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again.");
                }
              )
            );
        }
      } catch (err) {
        console.log(err);
        this.setState({
          ...this.state,
          error: String(err),
          screen: "error",
        });
      }
    });
  };

  /**
   *
   * @param {Event} e - Keyboard event
   *
   * Change handler for the stream title
   *
   * Set value into state
   * Validate input
   * Notify user of any errors
   */
  streamTitleChange = (e) =>
    this.setState(
      {
        ...this.state,
        streamTitle: {
          ...this.state.streamTitle,
          value: e.target.value,
        },
      },
      () => {
        try {
          live_title_schema.validateSync(
            {
              streamTitle: this.state.streamTitle.value,
            },
            {
              abortEarly: false,
            }
          );
          this.setState(
            {
              ...this.state,
              streamTitle: {
                ...this.state.streamTitle,
                invalid: false,
                error: "",
              },
            },
            () => document.getElementById("streamTitle").setCustomValidity("")
          );
        } catch (err) {
          this.setState(
            {
              ...this.state,
              streamTitle: {
                ...this.state.streamTitle,
                invalid: true,
                error: err.inner[0].message,
              },
            },
            () =>
              document
                .getElementById("streamTitle")
                .setCustomValidity(this.state.streamTitle.error)
          );
        }
      }
    );

  /**
   * @returns a placeholder image to user for a dummy video stream if needed
   */
  getBitmap = () =>
    new Promise((resolve, reject) =>
      axios({
        method: "get",
        responseType: "blob",
        url: "/icons/android-chrome-96x96.png",
      })
        .then(async (res) => {
          try {
            const bitmap = await createImageBitmap(res.data);
            resolve(bitmap);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject)
    );

  setBitmap = async () => {
    try {
      const bitmap = await this.getBitmap();
      this.setState({
        ...this.state,
        logoBitmap: bitmap,
      });
    } catch (err) {
      console.log("set bitmap error", err);
    }
  };

  /**
   * Fetch bitmap if not already done on mount
   * Get user media stream
   * Set media stream and devices into state
   * If camera enabled, pipe video stream to <video> elements (setTimeout to wait for framer-motion animation to complete)
   */
  setDevices = async () => {
    try {
      let bitmap = this.state.logoBitmap;
      if (!bitmap) bitmap = await this.getBitmap();
      if (!navigator.mediaDevices)
        this.setState({
          ...this.state,
          error:
            "Could not access any media devices. Make sure your camera and mic are not in use by another program, then refresh.",
          screen: "error",
        });
      else {
        navigator.mediaDevices
          .getUserMedia({
            audio: this.state.microphoneEnabled,
            video: this.state.cameraEnabled
              ? {
                  facingMode: this.state.cameraDirection,
                  width: { max: 1945 },
                  height: { max: 1945 },
                  frameRate: { max: 60 },
                }
              : false,
          })
          .then(async (mediaStream) => {
            try {
              const devices = await navigator.mediaDevices.enumerateDevices();
              const videoDevices = devices.filter(
                (d) => d.kind === "videoinput"
              );
              const audioDevices = devices.filter(
                (d) => d.kind === "audioinput"
              );
              let tracks = [];
              const audioTrack = mediaStream.getAudioTracks()[0];
              const videoTrack = mediaStream.getVideoTracks()[0];
              if (audioTrack) tracks.push(audioTrack);
              if (videoTrack) tracks.push(videoTrack);
              if (!tracks.length)
                this.setState({
                  ...this.state,
                  error:
                    "Could not access any media devices. Make sure your camera and mic are not in use by another program, then refresh.",
                });
              else
                this.setState(
                  {
                    ...this.state,
                    logoBitmap: bitmap,
                    stream: new MediaStream(tracks),
                    videoLabel: videoTrack ? videoTrack.label : "Camera",
                    audioLabel: audioTrack ? audioTrack.label : "Microphone",
                    videoDevices: videoDevices,
                    showCameraToggleButton: videoDevices.length > 1,
                    screen: "main",
                    videoDimensions: {
                      height:
                        videoDevices.length &&
                        this.state.cameraEnabled &&
                        videoTrack
                          ? videoTrack.getSettings().height
                          : 0,
                      width:
                        videoDevices.length &&
                        this.state.cameraEnabled &&
                        videoTrack
                          ? videoTrack.getSettings().width
                          : 0,
                    },
                    audioDevices: audioDevices,
                    cameraEnabled: videoDevices.length
                      ? this.state.cameraEnabled
                      : false,
                    microphoneEnabled: audioDevices.length
                      ? this.state.microphoneEnabled
                      : false,
                  },
                  () => {
                    try {
                      if (this.state.cameraEnabled)
                        setTimeout(() => {
                          try {
                            if (!this.state.networkProblems) {
                              const video = document.getElementById(
                                "streamer-video-main"
                              );
                              if (video) {
                                video.srcObject = this.state.stream;
                                video.play();
                              }
                            }
                            const secondaryVideo = document.getElementById(
                              "streamer-video-background"
                            );
                            secondaryVideo.srcObject = this.state.stream;
                            secondaryVideo.play();
                          } catch (err) {
                            console.log("no video", err);
                            this.setState({
                              ...this.state,
                              screen: "error",
                              error: "An unknown error occurred",
                            });
                          }
                        }, 350);
                    } catch (err) {
                      this.setState({
                        ...this.state,
                        error: String(err),
                        screen: "error",
                      });
                    }
                  }
                );
            } catch (err) {
              this.setState({
                ...this.state,
                error: String(err),
                screen: "error",
              });
            }
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              error: String(err),
              screen: "error",
            });
          });
      }
    } catch (err) {
      console.log("Set devices error", err);
      this.setState({
        ...this.state,
        error: "A network error occurred. Please try again later.",
        screen: "error",
      });
    }
  };

  /**
   * Triggered when the user clicks the Try Again button on the error page
   * Initializes everything then attempts to set the devices again
   */
  reset = () => {
    this.setState(
      {
        ...this.state,
        videoDevices: [],
        stream: false,
        cameraDirection: "user",
        showCameraToggleButton: false,
        screen: "setup",
        microphoneEnabled: true,
        cameraEnabled: true,
        videoDimensions: {
          height: 0,
          width: 0,
        },
        live: false,
        working: false,
        peerID: false,
        clipCount: 1,
        networkProblems: false,
      },
      this.setDevices
    );
  };

  /**
   * Triggered when the user toggles their camera
   *
   * If camera is turned off:
   * * If streaming live, disable sender
   * * Stop and remove track from local media stream
   *
   * If camera is turned on:
   * * getUserMedia according to the new restrictions
   * * Get video track from stream
   * * Set track characteristics into state
   * * Add track to local media stream
   * * If user is broadcasting, enable and replace track in peer connection
   */
  toggleCamera = () =>
    this.setState(
      {
        ...this.state,
        cameraEnabled: !this.state.cameraEnabled,
      },
      () => {
        if (!this.state.cameraEnabled) {
          if (this.state.peerConnection)
            this.state.peerConnection.getSenders().forEach((sender) => {
              if (sender.track.kind === "video") {
                const video = document.createElement("canvas");
                video.getContext("2d").drawImage(this.state.logoBitmap, 0, 0);
                const dummyTrack = video.captureStream(1000);
                const videoElement = document.createElement("video");
                videoElement.srcObject = dummyTrack;
                videoElement.play();
                const videoTrack = videoElement.captureStream(1000);
                sender.replaceTrack(videoTrack.getVideoTracks()[0]);
              }
            });
          this.state.stream.getVideoTracks().forEach((track) => {
            track.stop();
            this.state.stream.removeTrack(track);
          });
        } else
          navigator.mediaDevices
            .getUserMedia({
              audio: false,
              video: {
                facingMode: this.state.cameraDirection,
                width: { max: 1945 },
                height: { max: 1945 },
                frameRate: { max: 60 },
              },
            })
            .then((stream) => {
              const videoTrack = stream.getVideoTracks()[0];
              this.setState(
                {
                  ...this.state,
                  videoLabel: videoTrack ? videoTrack.label : "Camera",
                  videoDimensions: {
                    height:
                      this.state.cameraEnabled && videoTrack
                        ? videoTrack.getSettings().height
                        : 0,
                    width:
                      this.state.cameraEnabled && videoTrack
                        ? videoTrack.getSettings().width
                        : 0,
                  },
                },
                () => {
                  this.state.stream.addTrack(videoTrack);
                  if (this.state.peerConnection) {
                    this.state.peerConnection.getSenders().forEach((sender) => {
                      if (sender.track.kind === "video") {
                        sender.replaceTrack(videoTrack);
                      }
                    });
                  }
                  setTimeout(() => {
                    try {
                      if (!this.state.networkProblems) {
                        const video = document.getElementById(
                          "streamer-video-main"
                        );
                        video.srcObject = this.state.stream;
                        video.play();
                      }
                      const secondaryVideo = document.getElementById(
                        "streamer-video-background"
                      );
                      secondaryVideo.srcObject = this.state.stream;
                      secondaryVideo.play();
                    } catch (err) {
                      console.log("no video", err);
                      this.setState({
                        ...this.state,
                        screen: "error",
                        error: "An unknown error occurred",
                      });
                    }
                  }, 350);
                }
              );
            })
            .catch((err) => {
              this.setState({
                ...this.state,
                error: String(err),
                screen: "error",
              });
            });
      }
    );

  /**
   * Triggered when the user rotates their camera
   *
   * Stop all video tracks in local stream
   * Toggle camera direction
   * getUserMedia according to the new specifications
   * Set new video track and properties into state
   * Replace video track in local media stream
   * If streaming live, replace video track in stream
   */
  rotateCamera = () => {
    this.state.stream.getVideoTracks().forEach((track) => track.stop());
    this.setState(
      {
        ...this.state,
        cameraDirection:
          this.state.cameraDirection === "user" ? "environment" : "user",
      },
      () =>
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              facingMode: this.state.cameraDirection,
              width: { max: 1945 },
              height: { max: 1945 },
              frameRate: { max: 60 },
            },
          })
          .then((stream) => {
            const videoTrack = stream.getVideoTracks()[0];
            this.setState(
              {
                ...this.state,
                videoLabel: videoTrack ? videoTrack.label : "Camera",
                videoDimensions: {
                  height:
                    this.state.cameraEnabled && videoTrack
                      ? videoTrack.getSettings().height
                      : 0,
                  width:
                    this.state.cameraEnabled && videoTrack
                      ? videoTrack.getSettings().width
                      : 0,
                },
              },
              () => {
                this.state.stream
                  .getVideoTracks()
                  .forEach((track) => this.state.stream.removeTrack(track));
                this.state.stream.addTrack(videoTrack);
                if (this.state.peerConnection) {
                  this.state.peerConnection.getSenders().forEach((sender) => {
                    if (sender.track.kind === "video") {
                      sender.track.enabled = true;
                      sender.replaceTrack(videoTrack);
                    }
                  });
                }
              }
            );
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              error: String(err),
              screen: "error",
            });
          })
    );
  };

  /**
   * Triggered when the user toggles their microphone
   *
   * If microphone is turned off:
   * * If streaming live, disable sender
   * * Stop and remove track from local media stream
   *
   * If microphone is turned on:
   * * getUserMedia according to the new restrictions
   * * Get video track from stream
   * * Set track characteristics into state
   * * Add track to local media stream
   * * If user is broadcasting, enable and replace track in peer connection
   */
  toggleMicrophone = () =>
    this.setState(
      {
        ...this.state,
        microphoneEnabled: !this.state.microphoneEnabled,
      },
      () => {
        if (!this.state.microphoneEnabled) {
          if (this.state.peerConnection)
            this.state.peerConnection.getSenders().forEach((sender) => {
              if (sender.track.kind === "audio") {
                sender.track.enabled = false;
              }
            });
          this.state.stream.getAudioTracks().forEach((track) => {
            this.state.stream.removeTrack(track);
          });
        } else
          navigator.mediaDevices
            .getUserMedia({
              audio: true,
              video: false,
            })
            .then((stream) => {
              this.state.stream.addTrack(stream.getAudioTracks()[0]);
              if (this.state.peerConnection) {
                this.state.peerConnection.getSenders().forEach((sender) => {
                  if (sender.track.kind === "audio") {
                    sender.track.enabled = true;
                    sender.replaceTrack(stream.getAudioTracks()[0]);
                  }
                });
              }
            })
            .catch((err) => {
              this.setState({
                ...this.state,
                error: String(err),
                screen: "error",
              });
            });
      }
    );

  /**
   * Triggered when the user clicks the Start Stream button
   * Emits start-stream socket event
   *
   */
  startStream = () => {
    document.getElementById("stream-title").classList.add("was-validated");
    if (
      !this.state.working &&
      !this.state.live &&
      !this.state.streamTitle.error
    ) {
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () =>
          this.props.socket.emit("start-stream", {
            peerID: this.state.peer._id,
            streamTitle: this.state.streamTitle.value,
            clipCount: this.state.clipCount,
          })
      );
    }
  };

  /**
   * Triggered when the user clicks the End Stream button
   * Emits the end-stream button
   */
  endStream = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () => this.props.socket.emit("end-stream")
      );
  };

  /**
   * Stream video meant to fit the container
   * Larger dimension will span 100% of the container, smaller one auto
   *
   * @returns String - CSS class
   */
  getVideoClasses = () => {
    const cameraRatio =
      this.state.videoDimensions.width / this.state.videoDimensions.height;
    if (1 > cameraRatio) return "h-100";
    else return "w-100";
  };

  render() {
    return (
      <>
        {this.state.live && !this.props.modalShown ? (
          <motion.div
            onClick={this.props.toggleShowModal}
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            style={{ bottom: "0.5rem", left: "6rem" }}
            className="position-fixed cursor-pointer live-mini"
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="rounded-pill d-flex align-items-center p-2 bg-viewers">
                <h6 className="m-0">{h.compiledNumber(this.props.viewers)}</h6>
                <i className="fas fa-eye d-block ms-2 text-primary"></i>
              </div>
              {this.state.networkProblems ? (
                <h6 className="text-danger m-0">Reconnecting</h6>
              ) : (
                <h6 className="text-success m-0">You are live</h6>
              )}
            </div>
            {this.state.cameraEnabled ? (
              <div className="square-10 position-relative">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                            <video class="${this.getVideoClasses()}" muted autoplay playsinline id="streamer-video-mini" />
                        `,
                  }}
                  className={`h-100 w-100 d-flex justify-content-center align-items-center ${
                    this.state.networkProblems && "blur"
                  }`}
                ></div>
                {this.state.networkProblems ? (
                  <div className="position-absolute d-flex justify-content-center align-items-center top-0 bottom-0 left-0 right-0 h-100 w-100">
                    <Spinner color="light" />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center square-10">
                <i class="fas fa-volume-up fa-7x d-block mx-auto text-center"></i>
              </div>
            )}
          </motion.div>
        ) : (
          <></>
        )}
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="fullscreen">
              <MDBModalContent>
                <MDBModalBody className="position-relative p-0 h-100 w-100">
                  {process.env.REACT_APP_STREAMING_VERIFICATION_REQUIRED !==
                    "true" ||
                  h.checkJanny(this.props.userInfo) ||
                  this.props.userInfo.verified ? (
                    <>
                      <audio
                        src="/assets/blank/empty.mp3"
                        id="blank-audio"
                        muted
                      />
                      <StaticRouter location={this.state.screen}>
                        <AnimatePresence exitBeforeEnter>
                          <Switch key={this.state.screen}>
                            <Route exact path=":screen">
                              <GoLiveScreen
                                videoDevices={this.state.videoDevices}
                                stream={this.state.stream}
                                screen={this.state.screen}
                                error={this.state.error}
                                reset={this.reset}
                                videoDimensions={this.state.videoDimensions}
                                cameraEnabled={this.state.cameraEnabled}
                                showCameraToggleButton={
                                  this.state.showCameraToggleButton
                                }
                                cameraDirection={this.state.cameraDirection}
                                microphoneEnabled={this.state.microphoneEnabled}
                                toggleCamera={this.toggleCamera}
                                toggleMicrophone={this.toggleMicrophone}
                                audioDevices={this.state.audioDevices}
                                rotateCamera={this.rotateCamera}
                                startStream={this.startStream}
                                working={this.state.working}
                                live={this.state.live}
                                endStream={this.endStream}
                                videoLabel={this.state.videoLabel}
                                audioLabel={this.state.audioLabel}
                                peerOpen={this.state.peerOpen}
                                streamTitleChange={this.streamTitleChange}
                                streamTitle={this.state.streamTitle}
                                networkProblems={this.state.networkProblems}
                              />
                            </Route>
                          </Switch>
                        </AnimatePresence>
                      </StaticRouter>
                      {this.state.live ? (
                        <>
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                            style={{ top: "1rem", left: "1rem" }}
                            className="position-absolute rounded-pill d-flex align-items-center me-4 p-3 bg-viewers"
                          >
                            <h5 className="m-0">
                              {Number(this.props.viewers)
                                ? h.numberWithCommas(Number(this.props.viewers))
                                : 0}
                            </h5>
                            <i className="fas fa-eye d-block ms-2 text-primary fa-lg"></i>
                          </motion.div>
                          <MDBBtn
                            className="btn-close-modal position-absolute"
                            color="link"
                            style={{ top: "1rem", right: "1rem" }}
                            onClick={this.props.toggleShowModal}
                          >
                            <i className="fas fa-window-minimize fa-lg"></i>
                          </MDBBtn>
                        </>
                      ) : (
                        <div
                          style={{ right: "1rem", top: "1rem" }}
                          className="position-absolute px-2 w-max-content"
                        >
                          <MDBBtn
                            className="btn-close-modal"
                            color="link"
                            onClick={this.props.toggleShowModal}
                          >
                            <i className="fas fa-times fa-lg"></i>
                          </MDBBtn>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <i className="fas fa-exclamation-triangle fa-6x d-block mx-auto text-center mt-5 text-danger"></i>
                      <h5 className="display-6 text-center my-4">
                        You must be verified in order to live stream.
                      </h5>
                      <p className="text-center">
                        Please contact the {process.env.REACT_APP_ADMIN_PLURAL}{" "}
                        for more information.
                      </p>
                      <div
                        style={{ right: "1rem", top: "1rem" }}
                        className="position-absolute live-viewers-close w-max-content"
                      >
                        <MDBBtn
                          className="btn-close-modal"
                          color="link"
                          onClick={this.props.toggleShowModal}
                        >
                          <i className="fas fa-times fa-lg"></i>
                        </MDBBtn>
                      </div>
                    </>
                  )}
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_live })(GoLiveModal);
