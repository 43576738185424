import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { route, update_user } from "../../redux/actions";
import TextInput from "../textInput/TextInput";
import axios from "axios";
import SampleEmission from "../emission/SampleEmission";

const maxChars = Number(process.env.REACT_APP_MAX_EMISSION_CHARS);

class SignalBoostModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the signal boost is in the process of being submitted
       */
      working: false,
      reset: false,
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Get captcha key
   * Make request to server
   * Add signal boost to application state
   * Hide modal
   * Notify user
   */
  submit = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          try {
            const emissionData = document.getElementById("input-boost");
            const length = String(emissionData.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (length > maxChars)
              throw `Character limit exceeded (Max: ${maxChars} characters)`;
            const fd = new FormData();
            const captchaKey = await this.getRecaptcha();
            fd.append("captchaKey", captchaKey);
            fd.append("emission", emissionData.innerHTML);
            fd.append("signalBoost", this.props.emission.emissionID);
            axios
              .post("/emissions/signalboost", fd)
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    reset: !this.state.reset,
                  },
                  () => {
                    this.props.signalBoost(
                      this.props.emission.emissionID,
                      res.data.emission.emissionID,
                      true
                    );
                    setTimeout(() => {
                      this.props.update_user({
                        ...this.props.userInfo,
                        boosts: [
                          ...this.props.userInfo.boosts,
                          {
                            boostID: this.props.emission.emissionID,
                            emissionID: res.data.emission.emissionID,
                          },
                        ],
                      });
                      this.props.notify(
                        <i className="fas fa-retweet me-2 text-success"></i>,
                        <>
                          Signal Boosted{" "}
                          <span className="text-pkmn">
                            #{this.props.emission.emissionID}
                          </span>
                        </>
                      );
                    }, 250);
                  }
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => alert(err)
            );
          }
        }
      );
  };

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            staticBackdrop={this.state.working}
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    <span className="text-capitalize">
                      {process.env.REACT_APP_SIGNALBOOST_CURRENT}{" "}
                    </span>
                    #
                    <span className="text-pkmn">
                      {this.props.emission.emissionID}
                    </span>
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <TextInput
                    working={this.state.working}
                    submit={this.submit}
                    key={this.state.reset}
                    flavor="boost"
                    maxChars={maxChars}
                    label="Quote Reply (Optional)"
                  />
                  {this.props.emission ? (
                    <SampleEmission
                      emission={this.props.emission}
                      toggleModal={this.props.toggleShowModal}
                    />
                  ) : (
                    <></>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="bg-gray"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, update_user })(
  SignalBoostModal
);
