import React from "react";
import { motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import { connect } from "react-redux";
import TextInput from "../../components/textInput/TextInput";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { route } from "../../redux/actions";
import { v4 as uuid } from "uuid";

const maxChars = 5000;

class ChatBox extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a private chat is in the process of being sent
       * scrollToBottom: Boolean - Whether an incoming chat message will cause the chatbox to scroll to the bottom
       * cooldown: Number - Seconds left in cooldown before the user can send another message
       * cooldownInterval: false | Interval that decrements cooldown by 1 every second
       */
      working: false,
      scrollToBottom: true,
      cooldown: 0,
      cooldownInterval: false,
      reset: false,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  // Execute this.scrollToBottom upon receiving a new message
  componentDidUpdate(prevProps) {
    if (
      this.props.conversation.messages &&
      prevProps.conversation.messages &&
      prevProps.conversation.messages.length !==
        this.props.conversation.messages.length
    )
      this.scrollToBottom();
  }

  /**
   * Triggered when the user submits a new message
   *
   * Submit only if a message is not already in the process of being submitted
   * Validate message
   * Emit message via socket
   * Add message to application state
   * Place user on 3 second cooldown
   */
  submit = () => {
    try {
      if (!this.state.cooldown) {
        this.forceParse();
        const user = this.props.conversation.users.find(
          (p) => p._id !== this.props.userInfo._id
        );
        const emissionData = document.getElementById("input-message");
        const length = String(emissionData.textContent)
          .split("")
          .filter((c) => {
            const checkWhiteSpace = c.match(/[\s]/);
            if (!checkWhiteSpace) return true;
            else {
              return [" ", "\n"].indexOf(c) > -1;
            }
          }).length;
        if (length > maxChars)
          throw `Character limit exceeded (Max: ${maxChars} characters)`;
        if (!length || emissionData.innerHTML === "<div><p><br /></p></div>")
          throw "Please enter a message";
        const messageID = uuid();
        this.props.socket.emit("message", {
          to: {
            userID: user._id,
            username: user.username,
          },
          message: emissionData.innerHTML,
          id: messageID,
        });
        this.props.addMessage({
          timestamp: new Date(),
          author: this.props.userInfo._id,
          message: h.sanitizeHTML(emissionData.innerHTML),
          party: user._id,
          id: messageID,
        });
        this.setState(
          {
            ...this.state,
            text: "",
            cooldown: 3,
            working: false,
            reset: !this.state.reset,
          },
          () => {
            this.reset();
            setTimeout(this.decrementCooldown, 1000);
          }
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * If state.scrollToBottom, scroll to bottom
   * scrollBehavior is normal for first scroll, set to smooth for subsequent scrolls
   * Execute after 0.1s
   */
  scrollToBottom = () =>
    setTimeout(() => {
      if (this.state.scrollToBottom) {
        const container = document.getElementById("message-container");
        container.scrollTop = container.scrollHeight;
        container.style.scrollBehavior = "smooth";
      }
    }, 100);

  /**
   * Triggered when the user scrolls through the chatbox
   * If the scrollbar is not at the bottom, set state.scrollToBottom to false so that it won't scroll to the bottom when new messages come in
   */
  scrollHandler = (e) => {
    if (
      e.currentTarget.scrollHeight ===
      e.currentTarget.scrollTop + e.currentTarget.clientHeight
    ) {
      if (!this.state.scrollToBottom)
        this.setState({
          ...this.state,
          scrollToBottom: true,
        });
    } else if (this.state.scrollToBottom)
      this.setState({
        ...this.state,
        scrollToBottom: false,
      });
  };

  /**
   * Triggered by the cooldown interval
   * Decrements the cooldown by 1
   * If cooldown reaches zero, clear the interval
   *
   */
  decrementCooldown = () =>
    this.setState(
      {
        ...this.state,
        cooldown: this.state.cooldown > 0 ? this.state.cooldown - 1 : 0,
      },
      () => {
        if (this.state.cooldown > 0) setTimeout(this.decrementCooldown, 1000);
      }
    );

  /**
   * Triggered when the user clicks the chat partner's profile
   * Override native navigation and use redux route method
   */
  clickUser = (e) => {
    e.preventDefault();
    this.props.route(
      `/${
        this.props.conversation.users.find(
          (p) => p._id !== this.props.userInfo._id
        ).username
      }`
    );
  };

  render() {
    const user = this.props.conversation.users.find(
      (p) => p._id !== this.props.userInfo._id
    );
    return (
      <MDBCard className="h-100">
        <MDBCardBody className="h-100 d-flex flex-column">
          <h5 className="text-center">
            Chatting with{" "}
            <Link
              onClick={this.clickUser}
              to={`/${user.username}`}
              className="links-generic"
            >
              @{user.username}
            </Link>
          </h5>
          <div className="fg-1 border border-gray d-flex flex-column justify-content-end">
            <div
              onTouchMove={this.scrollHandler}
              onWheel={this.scrollHandler}
              style={{ scrollBehavior: "auto" }}
              id="message-container"
              className="h-100 overflow-y-auto"
            >
              {this.props.conversation.messages &&
              this.props.conversation.messages.length ? (
                <ul className="list-group list-group-flush">
                  {this.props.conversation.messages
                    .sort(
                      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                    )
                    .map((message) => {
                      if (message.author === this.props.userInfo._id)
                        return (
                          <motion.li
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                            key={String(message.timestamp)}
                            className="list-group-item"
                          >
                            <div className="d-flex justify-content-end max-w-100 text-break">
                              <div>
                                <p className="m-0 text-blusteel text-end">
                                  {h.makeDateHR(new Date(message.timestamp))} @{" "}
                                  {h.getTimeHR(new Date(message.timestamp))}
                                </p>
                                <div
                                  className="d-flex justify-content-end"
                                  dangerouslySetInnerHTML={{
                                    __html: message.message,
                                  }}
                                ></div>
                              </div>
                              <div className="chat-avatars ms-2">
                                <div
                                  className="fit-images chat-avatars fit-round"
                                  style={{
                                    backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${this.props.userInfo.avatar.thumbnail}")`,
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </motion.li>
                        );
                      else {
                        return (
                          <motion.li
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                            key={String(message.timestamp)}
                            className="list-group-item"
                          >
                            <div className="d-flex">
                              <div className="chat-avatars me-2">
                                <div
                                  className="fit-images chat-avatars fit-round"
                                  style={{
                                    backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}")`,
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                              <div className="max-w-100 text-break">
                                <p className="m-0 text-blusteel">
                                  {h.makeDateHR(new Date(message.timestamp))} @{" "}
                                  {h.getTimeHR(new Date(message.timestamp))}
                                </p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: message.message,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </motion.li>
                        );
                      }
                    })}
                </ul>
              ) : (
                <>
                  <i className="far fa-comments d-block mx-auto mt-5 fa-6x text-center" />
                  <h5 className="mt-4 display-6 text-center">
                    Send a message to begin
                  </h5>
                </>
              )}
            </div>
          </div>
          <TextInput
            submit={this.submit}
            setForceParse={(e) => (this.forceParse = e)}
            cooldown={this.state.cooldown}
            clearMessage={this.props.clearMessage}
            flavor="message"
            maxChars={5000}
            key={this.state.reset}
            label="Message"
          />
        </MDBCardBody>
      </MDBCard>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route })(ChatBox);
