import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import h from '../utilities/helpers';
import t from '../utilities/transitions';
import { route, set_unread_mod_logs } from '../redux/actions';
import {
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem,
    MDBCard,
    MDBCardBody,
    MDBBtn
} from 'mdb-react-ui-kit';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';

class ModLogs extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * loaded: Boolean - Whether the initial data has been loaded
             * modLogs: Array - List of mod logs
             * endReached: Boolean - Whether the last mod log is listed on the page
             * loadingMore: Boolean - Whether the user is currently loading more mod logs
             */
            loaded: false,
            modLogs: [],
            endReached: true,
            loadingMore: false
        }
    }

    /**
     * If the user is not logged in, redirect to the login page
     * Load the initial data
     */
    componentDidMount(){
        if (!this.props.userInfo._id && this.props.history && !this.state.redirecting) this.setState({
            ...this.state,
            redirecting: true
        }, () => this.props.route('/login'));
        else this.load();
    }

    /**
     * If the user logs out, redirect to the login page
     * If a new mod log is received, load updates from the server
     */
    componentDidUpdate(prevProps){
        if (!this.props.userInfo._id && this.props.history && !this.state.redirecting) this.setState({
            ...this.state,
            redirecting: true
        }, () => this.props.route('/login'));
        if (prevProps.unreadModLogs !== this.props.unreadModLogs) this.update();
    }

    /**
     * 
     * @param {Click Event} e 
     * @param {String} path - href/URL
     * 
     * Triggered when the user clicks a link
     * Override default behavior and use redux props.route method
     */
    route = (e, destination) => {
        e.preventDefault();
        this.props.route(destination);
    }

    /**
     * Loads the initial list of mod logs
     * If there are fewer than 100, then the end has already been reached
     */
    load = () => axios.get('/api/reports/mod-logs').then(res => this.setState({
        ...this.state,
        modLogs: res.data.modLogs,
        loaded: true,
        endReached: res.data.modLogs.length < 100
    }, this.readAll)).catch(err => {
        console.log('mod log load err', err);
        setTimeout(this.load, 1000);
    });

    /**
     * Triggered when the user clicks the "View More" button at the bottom of the page
     * Loads the next 100 mod logs
     */
    more = () => {
        if (!this.state.loadingMore) this.setState({
            ...this.state,
            loadingMore: true
        }, () => axios.post('/api/reports/mod-logs-more', {
            logIDs: this.state.modLogs.map(log => log._id)
        }).then(res => this.setState({
            ...this.state,
            modLogs: [
                ...this.state.modLogs,
                ...res.data.modLogs,
            ],
            endReached: res.data.modLogs.length < 100,
            loadingMore: false
        }, this.readAll)).catch(err => {
            console.log('mod log more err', err);
            setTimeout(this.more, 1000);
        }));
    }

    /**
     * Triggered when notified of a mod log update via socket
     * Pings the server for the update
     * Updates state
     */
    update = () => axios.post('/api/reports/mod-logs-update', {
        logIDs: this.state.modLogs.map(log => log._id),
        timestamp: this.state.modLogs.length ? this.state.modLogs[0].timestamp : new Date(new Date().setMinutes(new Date().getMinutes() - 1))
    }).then(res => this.setState({
        ...this.state,
        modLogs: [
            ...this.state.modLogs,
            ...res.data.modLogs
        ]
    }, this.readAll)).catch(err => {
        console.log('update err', err);
        setTimeout(this.update, 1000);
    });

    /**
     * Triggered any time the user loads the page or receives new mod logs
     * After 1 second, marks all mod logs as read
     */
    readAll = () => setTimeout(() => axios.get('/profile/read-mod-logs').then(() => {
        this.setState({
            ...this.state,
            modLogs: this.state.modLogs.map(m => ({
                ...m,
                unread: false
            }))
        }, () => this.props.set_unread_mod_logs(0));
    }), 1000);

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} className="py-4 h-100 mod-log-container">
                <MDBContainer>
                    {this.state.loaded ?
                    <>
                        {this.state.modLogs.length ?
                        <MDBCard>
                            <MDBCardBody>
                                <h5 className="text-center display-6">Mod Logs</h5>
                                <hr></hr>
                                <MDBListGroup light>
                                    {this.state.modLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(log => {
                                        const details = {
                                            mod: <Link className="text-primary" to={`/${log.modInfo.username}`} onClick={e => this.route(e, `/${log.modInfo.username}`)}>@{log.modInfo.username}</Link>,
                                            time: (
                                                <div className="me-2">
                                                    <p className="text-blusteel m-0">{h.makeDateHR(log.timestamp)}</p>
                                                    <p className="text-blusteel m-0">{h.getTimeHR(log.timestamp)}</p>
                                                </div>                                                
                                            )
                                        }
                                        if (log.type === 'user'){
                                            details.recipient = <Link className="text-primary" to={`/${log.userInfo.username}`} onClick={e => this.route(e, `/${log.userInfo.username}`)}>@{log.userInfo.username}</Link>;
                                            switch(log.action){
                                                case 'ban':
                                                    details.icon = <i className="fas fa-gavel text-danger"></i>;
                                                    details.action = <span className="text-default"> banned </span>
                                                    break;
                                                case 'dismiss':
                                                    case 'dismiss':
                                                    details.icon = <i className="far fa-check-circle text-primary"></i>;
                                                    details.action = <span className="text-default"> dismissed all reports against </span>
                                                    break;
                                                case 'edit':
                                                    details.icon = <i className="fas fa-edit text-primary"></i>
                                                    details.action = <span className="text-default"> edited the user information of </span>
                                                    break;
                                                case 'restore':
                                                    details.icon = <i className="far fa-check-circle text-success"></i>;
                                                    details.action = <span className="text-default"> lifted the ban of </span>
                                                    break;
                                                case 'verify':
                                                    details.icon = <i className="far fa-check-circle text-success"></i>;
                                                    details.action = <span className="text-default"> verified </span>
                                                    break;
                                                case 'unverify':
                                                    details.icon = <i className="far fa-times-circle text-danger"></i>;
                                                    details.action = <span className="text-default"> removed verification from </span>
                                                    break;
                                                default:
                                                    console.log('oob log action', log);
                                                    details.icon = <i className="far fa-check-circle text-primary"></i>;
                                                    details.action = <span className="text-default"> took action against </span>
                                                    break;
                                            }
                                        } else {
                                            details.recipient = <><span className="text-capitalize">{process.env.REACT_APP_EMISSION_NAME}</span> <Link to={`/e/${log.emissionID}`} onClick={e => this.route(e, `/e/${log.emissionID}`)} className="text-primary">#{log.emissionID}</Link></>;
                                            switch(log.action){
                                                case 'remove':
                                                    details.icon = <i className="fas fa-gavel text-danger"></i>;
                                                    details.action = <span className="text-default"> removed </span>
                                                    break;
                                                case 'dismiss':
                                                    case 'dismiss':
                                                    details.icon = <i className="far fa-check-circle text-primary"></i>;
                                                    details.action = <span className="text-default"> dismissed all reports against </span>
                                                    break;
                                                case 'restore':
                                                    details.icon = <i className="far fa-check-circle text-success"></i>;
                                                    details.action = <span className="text-default"> restored </span>
                                                    break;
                                                default:
                                                    console.log('oob log action', log);
                                                    details.icon = <i className="far fa-check-circle text-primary"></i>;
                                                    details.action = <span className="text-default"> took action against </span>
                                                    break;
                                            }
                                        }
                                        return (
                                            <MDBListGroupItem className={`${log.unread ? 'bg-litepink': ''} transition-1 px-2`} key={log._id}>
                                                <motion.div className="d-flex align-items-center" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                                                    <h5 style={{width: '30px'}} className="me-2">{details.icon}</h5>
                                                    <div className="d-flex align-items-start">
                                                        {details.time}
                                                        <h5 className="mod-log-text">
                                                            {details.mod}
                                                            {details.action}
                                                            {details.recipient}
                                                        </h5>
                                                    </div>
                                                </motion.div>
                                            </MDBListGroupItem>
                                        );
                                    })}
                                </MDBListGroup>
                                {this.state.endReached ? <></> : 
                                <div className="pt-4 pb-2">
                                    {this.state.loadingMore ?
                                    <MDBBtn
                                        size="lg" 
                                        disabled 
                                        className="d-block mx-auto w-50"  
                                        rippleColor="primary" 
                                        outline 
                                        color="primary"
                                        rounded
                                    ><Spinner color="primary" size="sm" className="me-2" />Please Wait</MDBBtn> :
                                    <MDBBtn 
                                        size="lg" 
                                        onClick={this.more} 
                                        className="d-block mx-auto w-50" 
                                        rippleColor="primary" 
                                        outline 
                                        color="primary"
                                        rounded
                                    >See More</MDBBtn>}
                                </div>}
                            </MDBCardBody>
                        </MDBCard> : 
                        <h5 className="text-center display-4 mb-4 mt-5">There are no mod logs</h5>}
                    </> : 
                    <>
                        <h5 className="text-center display-6 mb-4 mt-5">Fetching Logs</h5>
                        <LinearProgress />
                    </>
                    }
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route, set_unread_mod_logs })(ModLogs);