import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { route, set_poll_modal } from "../redux/actions";
import axios from "axios";
import EmissionPage from "../components/emission/EmissionPage";

class Tag extends React.Component {
  constructor(props) {
    super();
    /**
     * this.tag: String - The hashtag of the page
     */
    this.tag = props.match.params.tag;
    this.state = {
      /**
       * loaded: Boolean - Whether the initial emission data has been loaded
       * loadingMore: false | Array - List of emissions with replies that are currently loading more replies
       * emissions: Array - All emissions tied to the main emission which have been loaded (replies, signalboosted, etc)
       * endReached: Boolean - Whether there are no more emissions to load
       */
      loaded: false,
      loadingMore: false,
      endReached: false,
    };
  }

  /**
   * Leave the tag's socket room
   *
   */
  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.emit("leave-tag");
    }
  }

  /**
   * Loads the initial data
   * Sets the emissions into state
   * If there are fewer than 20 results, the end has been reached
   */
  load = () => {
    return new Promise((resolve) =>
      axios
        .get("/api/emissions/tag/" + this.tag)
        .then((res) =>
          this.setState(
            {
              ...this.state,
              endReached: res.data.endReached,
            },
            () =>
              resolve({
                emissions: res.data.emissions,
              })
          )
        )
        .catch((err) => {
          console.log("load tags err", err);
          setTimeout(async () => {
            const data = await this.load();
            resolve(data);
          }, 1000);
        })
    );
  };

  /**
   * Triggered when the user clicks the View More button at the bottom of a comment thread on an emission
   * Loads more replies in that comment thread
   */
  loadMore = (emissions, callback) => {
    if (!this.state.loadingMore)
      this.setState(
        {
          ...this.state,
          loadingMore: true,
        },
        () =>
          axios
            .post("/api/emissions/tag/more", {
              tag: this.tag,
              emissions: emissions.map((e) => e.emissionID),
            })
            .then((res) => {
              this.setState(
                {
                  ...this.state,
                  endReached: res.data.endReached,
                  loadingMore: false,
                },
                () =>
                  callback({
                    emissions: res.data.emissions,
                  })
              );
            })
            .catch((err) => {
              console.log("load more err", err);
              setTimeout(this.loadMore, 1000);
            })
      );
  };

  render() {
    return (
      <EmissionPage
        load={this.load}
        loadMore={this.loadMore}
        flavor="tag"
        loading={this.state.loading}
        loadingMore={this.state.loadingMore}
        endReached={this.state.endReached}
        tag={this.tag}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route, set_poll_modal })(
  withRouter(Tag)
);
