import React from 'react';
import EditInfo from './EditInfo';
import ChangePassword from './ChangePassword';

class EditProfileForm extends React.Component{
    constructor(props){
        super();
        this.form = props.form;
    };

    componentDidMount(){
        if (this.form === 'info'){
            this.forceParse = this.forceParse.bind(this);
            this.props.setForceParse(this.forceParse);
        }
    }
    
    render(){
        switch(this.form){
            case 'info':
                return <EditInfo 
                            form={this.props.formShown} 
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            pressEnter={this.props.pressEnter}
                            submit={this.props.submit}
                            changeHandler={this.props.changeHandler}
                            inputs={this.props.inputs}
                            fields={this.props.fields}
                            modalShown={this.props.modalShown}
                            working={this.props.working}
                            setForceParse={f => this.forceParse = f}
                        />
            case 'password':
                return <ChangePassword 
                            form={this.props.formShown} 
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            pressEnter={this.props.pressEnter}
                            submit={this.props.submit}
                            changeHandler={this.props.changeHandler_password}
                            inputs={this.props.changePasswordInputs}
                            changePassword={this.props.changePassword}
                            modalShown={this.props.modalShown}
                        />
            default:
                return <EditInfo />
        }
    }
}

export default EditProfileForm;