import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import SampleEmission from "../emission/SampleEmission";
import axios from "axios";
import { route } from "../../redux/actions";
import TextInput from "../textInput/TextInput";

const maxChars = Number(process.env.REACT_APP_MAX_EMISSION_CHARS);

class ReplyModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the reply is in the process of being submitted
       */
      working: false,
      reset: false,
    };
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Validate files
   * Get captcha key
   * Make request to server
   * Hide modal
   * Navigate to new comment
   */
  submit = (files) => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          try {
            if (
              files.reduce((prev, curr) => prev + curr.size, 0) >
              Number(process.env.REACT_APP_MAX_TOTAL_FILE_SIZE)
            )
              throw `Max total file size exceeded. (Max: ${Math.round(
                Number(process.env.REACT_APP_MAX_TOTAL_FILE_SIZE) /
                  (1024 * 1024)
              )}MB)`;
            const emissionData = document.getElementById("input-reply");
            const length = String(emissionData.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (
              (!emissionData.textContent ||
                emissionData.innerHTML === "<div><p><br /></p></div>") &&
              !files.length
            )
              throw "Please enter text or at least one file";
            if (length > maxChars)
              throw `Character limit exceeded (Max: ${maxChars} characters)`;
            const fd = new FormData();
            const captchaKey = await this.getRecaptcha();
            fd.append("captchaKey", captchaKey);
            fd.append("emission", emissionData.innerHTML);
            fd.append("replyID", this.props.emission.emissionID);
            files.forEach((file) => {
              fd.append("files", file.file, file.name);
              if (file.file.type.includes("video"))
                fd.append("thumbnails", file.thumbnail, file.name);
            });
            axios
              .post("/emissions/reply", fd)
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    reset: !this.state.reset,
                  },
                  () => {
                    this.props.toggleShowModal();
                    this.props.route(`/e/${res.data.emissionID}`);
                  }
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => alert(err)
            );
          }
        }
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                {this.props.emission ? (
                  <>
                    <MDBModalHeader>
                      <MDBModalTitle>
                        Replying to{" "}
                        <span className="text-pkmn">
                          #{this.props.emission.emissionID}
                        </span>
                      </MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={this.props.toggleShowModal}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <TextInput
                        key={this.props.emission._id + String(this.state.reset)}
                        submit={this.submit}
                        working={this.state.working}
                        flavor="reply"
                        maxChars={maxChars}
                        label="Reply"
                      />
                      <SampleEmission
                        emission={this.props.emission}
                        toggleModal={this.props.toggleShowModal}
                        test="hello"
                      />
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        className="bg-gray"
                        onClick={this.props.toggleShowModal}
                      >
                        Close
                      </MDBBtn>
                    </MDBModalFooter>
                  </>
                ) : (
                  <></>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(ReplyModal);
