import React from 'react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import { set_user, route } from '../redux/actions';
import t from '../utilities/transitions';
import LinearProgress from '@mui/material/LinearProgress';
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { withRouter } from 'react-router-dom';


class Validate extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether the validation is bring processed
             */
            working: true
        }
    }

    componentDidMount(){
        this.load();
    }

    /**
     * If validation succeeds and user is set, route to that user's profile page
     */
    componentDidUpdate(prevProps){
        if (!prevProps.userInfo._id && this.props.userInfo._id) this.props.route('/' + this.props.userInfo.username);
    }

    /**
     * Validates the request
     * If validation request is found and is successful, set user
     * If validation is found, but mod approval is still required, route to "Awaiting Approval" page
     */
    load = () => axios.get('/auth/validate/' + this.props.match.params.id).then(res => {
        if (res.data.username) this.props.set_user(res.data);
        else this.props.route('/awaiting-approval');
    }).catch(err => {
        console.log(err);
        if (err.response && err.response.status === 404) this.setState({
            ...this.state,
            working: false
        });
        else setTimeout(this.load, 1000);
    });

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <MDBContainer>
                    {this.state.working ?
                    <>
                        <h5 className="text-center mt-4 display-6">Validating</h5>
                        <LinearProgress />
                    </> :
                    <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                        <h5 className="text-center mt-4 display-6">Invalid Link</h5>
                    </motion.div>}
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route, set_user })(withRouter(Validate));