import React from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBContainer,
  MDBRipple,
  MDBBtn,
  MDBSelect,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import axios from "axios";
import { edit_user_mod_schema } from "../../../../../utilities/validations";
import { set_profile } from "../../../../../redux/actions";
import h from "../../../../../utilities/helpers";
import TextInput from "../../../../../components/textInput/TextInput";

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

const fields = [
  {
    label: "Display Name",
    id: "displayName",
  },
  {
    label: "Website",
    id: "website",
  },
  {
    label: "Location",
    id: "location",
  },
];

class Details extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * working: Boolean - Whether changes to the profile are in the process of being applied
       * inputs: Array - The input data (values, errors, etc)
       * reset: Boolean - Measures nothing, but when toggled fixes some MDB ui bugs
       * avatarClear: Boolean - Whether the profile's avatar has been cleared and reset to default avatar
       * backgroundClear: Boolean - Whether the profile's background has been cleared and reset to default background
       * avatar: String, Path to the user's avatar
       * avatarName: String, "Click to Change", or the name of the file the user has selected, if any
       * avatarFile: false | File object that contains an avatar file that the user selected
       * background: String, Path to the user's background
       * backgroundName: String, "Click to Change", or the name of the file the user has selected, if any
       * backgroundFile: false | File object that contains an background file that the user selected,
       * role: String - The user's role
       */
      working: false,
      inputs: fields.map((i) => ({
        id: i.id + "-details",
        error: "",
        invalid: false,
        value: props.profileInfo.user[i.id],
      })),
      reset: false,
      avatarClear: false,
      backgroundClear: false,
      avatar: `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${props.profileInfo.user.avatar.main}`,
      avatarName: "Click to Change",
      avatarFile: "",
      background: `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${props.profileInfo.user.background.main}`,
      backgroundName: "Click to Change",
      backgroundFile: "",
      role: props.profileInfo.user.role,
    };
    this.submit = this.submit.bind(this); // Allows the submit method to be called from the parent
  }

  /**
   * Fix mdb inputs
   * Set the submit() method in the parent
   * Run empty change handler
   */
  componentDidMount() {
    setTimeout(this.fixMDBInputs, 250);
    this.props.setSubmit(this.submit);
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userInfo.username !== this.props.userInfo.username &&
      this.props.modalShown
    )
      this.props.toggleShowModal();
    if (prevProps.modalShown !== this.props.modalShown) this.fixMDBInputs();
  }

  componentWillUnmount() {
    this.props.setWorking(false);
  }

  /**
   * Fixes MDB ui bug in which labels are not properly floating or are crossed out
   */
  fixMDBInputs = () => {
    [].slice
      .call(document.getElementsByClassName("details-inputs"))
      .forEach((e) => {
        if (!e.value && e.classList.contains("active"))
          e.classList.remove("active");
        else if (e.value && !e.classList.contains("active"))
          e.classList.add("active");
      });
    this.setState(
      {
        ...this.state,
        reset: !this.state.reset,
      },
      () => h.floatLabels()
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Update the profile
   * Hide the modal
   */
  submit = () => {
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    document.getElementById("details").classList.add("was-validated");
    console.log(this.state.working, invalidInputs);
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          this.props.setWorking(true);
          this.forceParse();
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [
              input.id.split("-details")[0],
              input.value,
            ])
          );
          try {
            edit_user_mod_schema.validateSync(data, {
              abortEarly: false,
            });
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            const bio = document.getElementById("input-bio-edit-other");
            const length = String(bio.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (length > 1000)
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => alert("Bio is too long (Max: 1000 chars)")
              );
            else {
              fd.append(
                "bio",
                bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "")
              );
              if (this.state.avatarFile)
                fd.append(
                  "avatar",
                  this.state.avatarFile,
                  this.state.avatarName
                );
              if (this.state.backgroundFile)
                fd.append(
                  "background",
                  this.state.backgroundFile,
                  this.state.backgroundName
                );
              fd.append("userID", this.props.profileInfo.user._id);
              fd.append("avatarClear", this.state.avatarClear);
              fd.append("backgroundClear", this.state.backgroundClear);
              if (h.checkChadmin(this.props.userInfo))
                fd.append("role", this.state.role);
              axios
                .post("/support/users/edit", fd)
                .then((res) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      console.log("res", res);
                      this.props.setWorking(false);
                      this.props.set_profile({
                        user: res.data.userInfo,
                      });
                      this.props.toggleShowModal();
                    }
                  )
                )
                .catch((err) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      this.props.setWorking(false);
                      console.log(err);
                      alert("An error occurred. Please try again later.");
                    }
                  )
                );
            }
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                this.props.setWorking(false);
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [
            input.id.split("-details")[0],
            input.value,
          ])
        );
        try {
          edit_user_mod_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find(
                    (error) => error.path + "-details" === input.id
                  ) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find(
                      (error) => error.path + "-details" === input.id
                    ).message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  /**
   * Fired when the user clicks their avatar or background
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  selectFile = (type) => {
    let input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.style.position = "fixed";
    document.body.appendChild(input);
    input.onchange = (e) => {
      let file = e.target.files[0];
      if (allowedExtensions.indexOf(file.type) !== -1) {
        if (file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE))
          alert(
            `Max individual file size exceeded. (Max: ${Math.round(
              Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE) /
                (1024 * 1024)
            )}MB)`
          );
        else
          this.setState(
            {
              ...this.state,
              [`${type}Name`]: e.target.files[0].name,
              [`${type}File`]: e.target.files[0],
              [type]: URL.createObjectURL(e.target.files[0]),
              [`${type}Clear`]: false,
            },
            () => document.body.removeChild(input)
          );
      } else {
        document.body.removeChild(input);
        alert("Please select a valid image file (png, jpg, gif, bmp, webp)");
      }
    };
    input.click();
  };

  /**
   * Triggered when the user clicks the Clear Avatar button
   * Resets the avatar to the default avatar
   */
  clearAvatar = () =>
    this.setState({
      ...this.state,
      avatarClear: true,
      avatar: `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/blank-avatar.png`,
      avatarName: "Click to Change",
      avatarFile: "",
    });

  /**
   * Triggered when the user clicks the Clear Background button
   * Resets the background to the default background
   */
  clearBackground = () =>
    this.setState({
      ...this.state,
      backgroundClear: true,
      background: `${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/default-background.webp`,
      backgroundName: "Click to Change",
      backgroundFile: "",
    });

  roleChange = (e) =>
    this.setState({
      ...this.state,
      role: e.value,
    });

  render() {
    return (
      <MDBValidation
        className="max-w-95 mx-auto"
        name="details"
        method="dialog"
        id="details"
        onSubmit={this.submit}
      >
        <MDBContainer className="px-0" fluid>
          {h.checkChadmin(this.props.userInfo) ? (
            <div className="row mb-4">
              <div className="col-12 col-md-6 col-lg-4">
                <MDBSelect
                  label="Role"
                  size="lg"
                  data={[
                    {
                      text: process.env.REACT_APP_ADMIN_NAME,
                      value: "Chadmin",
                      defaultSelected: this.state.role === "Chadmin",
                    },
                    {
                      text: process.env.REACT_APP_MOD_NAME,
                      value: "Janny",
                      defaultSelected: this.state.role === "Janny",
                    },
                    {
                      text: process.env.REACT_APP_CHILD_NAME,
                      value: "Child",
                      defaultSelected: this.state.role === "Child",
                    },
                  ]}
                  onValueChange={this.roleChange}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row mx-0">
            {fields.map((i) => (
              <MDBValidationItem
                className="pb-4 col-12 col-lg-6"
                feedback={
                  this.state.inputs.find(
                    (input) => input.id === i.id + "-details"
                  ).error
                }
                invalid={true}
                key={i.id}
              >
                <MDBInput
                  name={i.id + "-details"}
                  onChange={this.changeHandler}
                  id={i.id + "-details"}
                  label={i.label}
                  size="lg"
                  className={
                    !this.state.inputs.find(
                      (input) => input.id === i.id + "-details"
                    ).invalid
                      ? "mb-0"
                      : 0
                  }
                  onKeyPress={this.pressEnter}
                  reset={this.state.reset}
                  onKeyDown={this.pressTab}
                  defaultValue={
                    this.state.inputs.find(
                      (input) => input.id === i.id + "-details"
                    ).value
                  }
                />
              </MDBValidationItem>
            ))}
            <div className="col-12 col-lg-6">
              <TextInput
                setForceParse={(f) => (this.forceParse = f)}
                working={this.state.working}
                maxChars={1000}
                flavor="bio-edit-other"
                label="Bio"
                reset={false}
                prefilledHTML={this.props.profileInfo.user.bio}
              />
            </div>
          </div>
          <hr></hr>
          <div className="row mx-0 justify-content-center">
            <div className="col-12 col-lg-6 mt-2">
              <p
                style={{ fontSize: "1.5rem" }}
                className="text-center display-6"
              >
                Display Picture
              </p>
              <MDBBtn
                type="button"
                onClick={this.clearAvatar}
                className="d-block ms-auto"
                color="link"
                rippleColor="primary"
              >
                <i className="fas fa-times me-2"></i>Clear
              </MDBBtn>
              <div
                style={{ height: "250px" }}
                className="d-flex justify-content-center align-items-center w-100 mx-auto mt-1"
              >
                <MDBRipple
                  onClick={() => this.selectFile("avatar")}
                  tag="div"
                  rippleColor="primary"
                  className="fit-background cursor-pointer"
                  style={{ backgroundImage: `url("${this.state.avatar}")` }}
                ></MDBRipple>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-2">
              <p
                style={{ fontSize: "1.5rem" }}
                className="text-center display-6"
              >
                Background Picture
              </p>
              <MDBBtn
                type="button"
                onClick={this.clearBackground}
                className="d-block ms-auto"
                color="link"
                rippleColor="primary"
              >
                <i className="fas fa-times me-2"></i>Clear
              </MDBBtn>
              <div
                style={{ height: "250px" }}
                className="d-flex justify-content-center align-items-center w-100 mx-auto mt-1"
              >
                <MDBRipple
                  onClick={() => this.selectFile("background")}
                  tag="div"
                  rippleColor="light"
                  className="fit-background cursor-pointer"
                  style={{ backgroundImage: `url("${this.state.background}")` }}
                ></MDBRipple>
              </div>
            </div>
          </div>
        </MDBContainer>
      </MDBValidation>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_profile })(Details);
