import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import { connect } from "react-redux";
import Spinner from "../../../../components/Spinner";
import Form from "./userActionModal/Form";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { set_profile } from "../../../../redux/actions";
import { AnimatePresence, motion } from "framer-motion";
import t from "../../../../utilities/transitions";
import axios from "axios";

class UserActionModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the action on the form selected is executing
       * formSelected: String - The form selected - "details" | "ban"
       * restoreExpanded: Boolean - Whether the "Restore Banned User" MDBCollapse is expanded
       * restoring: Boolean - Whether the profile is in the process of being restored
       * verifying: Boolean - Whether the profile is in the process of being verified or unverified
       */
      working: false,
      formSelected: "details",
      restoreExpanded: false,
      restoring: false,
      verifying: false,
    };
  }

  /**
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   * Add mousedown event listener for closeModal method
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setModalBodyHeight();
      if (this.props.modalShown)
        document.addEventListener("mousedown", this.closeModal);
    }
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeModal);
    window.removeEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * Triggered by the mousedown event
   *
   * Close modal only if the user clicks one of the close buttons or the actual backdrop
   * Prevents bug that closes the modal when clicking anywhere while the modal is growing or shrinking
   */
  closeModal = (e) => {
    if (
      e.target.classList.contains("modal") &&
      !this.state.working &&
      this.props.modalShown
    ) {
      this.props.toggleShowModal();
      document.removeEventListener("mousedown", this.closeModal);
    }
  };

  /**
   * Changes the form
   * Resizes the body after the framer-motion exit animations have completed
   */
  changeForm = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          formSelected:
            this.state.formSelected === "details" ? "ban" : "details",
        },
        () => setTimeout(this.setModalBodyHeight, 333)
      );
  };

  setWorking = (option) =>
    this.setState({
      ...this.state,
      working: option,
    });

  /**
   * Resets the modal
   *
   */
  reset = () =>
    this.setState(
      {
        ...this.state,
        working: false,
        formSelected: "details",
      },
      this.props.toggleShowModal
    );

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () =>
    (document.getElementById("action-modal-body").style.height = `${
      document.getElementById("action-modal-body-child").clientHeight
    }px`);

  toggleRestore = () =>
    this.setState({
      ...this.state,
      restoreExpanded: !this.state.restoreExpanded,
    });

  /**
   * Triggered when the user restores a banned profile
   *
   * Submit to server
   * Update profile
   * Hide modal
   * Notify user that it was successful
   */
  restoreUser = () => {
    if (!this.state.restoring)
      this.setState(
        {
          ...this.state,
          restoring: true,
          restoreExpanded: false,
        },
        () =>
          axios
            .post("/support/users/restore", {
              userID: this.props.profileInfo.user._id,
            })
            .then((res) => {
              this.props.set_profile({
                user: res.data.userInfo,
              });
              this.props.toggleShowModal();
              this.setState(
                {
                  ...this.state,
                  restoring: false,
                },
                () =>
                  this.props.notify(
                    <i className="fas fa-user-plus text-success me-2"></i>,
                    <>{this.props.profileInfo.user.username} Restored</>
                  )
              );
            })
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  restoring: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user clicks the Verify or Unverify buttton
   *
   */
  toggleVerification = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          verifying: true,
        },
        () =>
          axios
            .get(
              `/support/users/toggle-verification/${this.props.profileInfo.user._id}`
            )
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  verifying: false,
                },
                () =>
                  this.props.set_profile({
                    user: {
                      ...this.props.profileInfo.user,
                      ...res.data.userInfo,
                    },
                  })
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  verifying: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            staticBackdrop={true}
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {this.state.formSelected === "details" ? (
                      <motion.div
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        User Details -{" "}
                        <span className="text-darkblu">
                          @{this.props.profileInfo.user.username}
                        </span>
                      </motion.div>
                    ) : (
                      <motion.p
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        Ban{" "}
                        <span className="text-darkblu">
                          @{this.props.profileInfo.user.username}
                        </span>
                      </motion.p>
                    )}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <div
                  id="action-modal-body"
                  className="transition-25 overflow-y-hidden"
                >
                  <MDBModalBody id="action-modal-body-child">
                    <StaticRouter location={this.state.formSelected}>
                      <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.formSelected}>
                          <Route exact path=":form">
                            <Form
                              form={this.state.formSelected}
                              googleReCaptchaProps={
                                this.props.googleReCaptchaProps
                              }
                              setSubmit={(f) => (this.submit = f)}
                              modalShown={this.props.modalShown}
                              setShowModal={this.props.setShowModal}
                              toggleShowModal={this.props.toggleShowModal}
                              setWorking={this.setWorking}
                              key={this.state.formSelected}
                              notify={this.props.notify}
                              reset={this.reset}
                              working={this.state.working}
                            />
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </StaticRouter>
                  </MDBModalBody>
                </div>
                <MDBModalFooter className="d-flex justify-content-between edit-profile-footer">
                  {this.state.formSelected === "details" ? (
                    <div className="d-flex align-items-start edit-profile-buttons-actions">
                      {this.props.profileInfo.user.ban.banned ? (
                        <div>
                          {this.state.restoring ? (
                            <MDBBtn disabled color="danger">
                              <Spinner className="me-2" size="sm" />
                              Restoring
                            </MDBBtn>
                          ) : (
                            <MDBBtn onClick={this.toggleRestore} color="danger">
                              <i className="fas fa-user-plus me-2"></i>
                              {this.state.restoreExpanded
                                ? "Are you sure?"
                                : "Restore User"}
                            </MDBBtn>
                          )}
                          <Collapse
                            className="mt-1"
                            in={this.state.restoreExpanded}
                          >
                            <MDBBtn
                              className="d-block"
                              color="link"
                              rippleColor="primary"
                              onClick={this.toggleRestore}
                            >
                              No
                            </MDBBtn>
                            <MDBBtn
                              className="d-block"
                              color="link"
                              rippleColor="primary"
                              onClick={this.restoreUser}
                            >
                              Yes
                            </MDBBtn>
                          </Collapse>
                        </div>
                      ) : (
                        <MDBBtn onClick={this.changeForm} color="danger">
                          <i className="fas fa-gavel me-2"></i>
                          Ban User
                        </MDBBtn>
                      )}
                      {this.props.profileInfo.user.role === "Child"}
                      {this.props.profileInfo.user.verified ? (
                        <>
                          {this.state.verifying ? (
                            <MDBBtn disabled className="ms-2 bg-purple">
                              <Spinner size="sm" className="me-2" />
                              Removing Verification
                            </MDBBtn>
                          ) : (
                            <MDBBtn
                              onClick={this.toggleVerification}
                              className="ms-2 bg-purple"
                            >
                              <i className="fas fa-user-times me-2"></i>
                              Remove Verification
                            </MDBBtn>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.verifying ? (
                            <MDBBtn color="primary" disabled className="ms-2">
                              <Spinner size="sm" className="me-2" />
                              Verifying
                            </MDBBtn>
                          ) : (
                            <MDBBtn
                              onClick={this.toggleVerification}
                              color="primary"
                              className="ms-2"
                            >
                              <i className="fas fa-user-check me-2"></i>
                              Verify
                            </MDBBtn>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <MDBBtn onClick={this.changeForm} color="primary">
                      <i className="fas fa-user me-2"></i>
                      User Details
                    </MDBBtn>
                  )}
                  <div className="d-flex edit-profile-buttons-actions">
                    {this.state.working ? (
                      <MDBBtn
                        rippleColor="light"
                        color="success"
                        disabled
                        className="me-2"
                      >
                        <Spinner size="sm" className="me-2" />
                        Working
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        onClick={this.submit}
                        rippleColor="light"
                        color="success"
                        className="me-2"
                      >
                        <i className="fas fa-paper-plane me-2"></i>Submit
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </div>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_profile })(UserActionModal);
