import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBListGroup,
    MDBListGroupItem,
    MDBRipple
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

class UserInteractionModal extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{this.props.description}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBListGroup light>
                                    {this.props.users.map(user => (
                                        <Link className="w-100" key={user._id} to={user.link} onClick={e => this.props.route(e, user.link)}>
                                            <MDBRipple rippleColor="primary" className="w-100">
                                                <MDBListGroupItem className="d-flex align-items-center w-100 p-2" action>
                                                    <div className="chat-avatars rounded-circle">
                                                        <div className="fit-images fit-round" style={{backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${user.avatar}")`, borderRadius: '50%'}}></div>
                                                    </div>
                                                    <h5 className="mb-0 ms-2">@{user.name}</h5>
                                                </MDBListGroupItem>
                                            </MDBRipple>
                                        </Link>
                                    ))}
                                </MDBListGroup>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

export default UserInteractionModal;