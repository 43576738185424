import React from "react";
import Spinner from "../../../components/Spinner";
import {
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import axios from "axios";

class Approval extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a decision is in the process of being submitted
       * approving: Boolean - Whether an approval is in the process of being submitted
       * rejecting: Boolean - Whether a rejection is in the process of being submitted
       * input: Object - The details data (value, error, etc)
       */
      working: false,
      approving: false,
      rejecting: false,
      input: {
        id: "approval-input",
        error: "",
        invalid: false,
        value: "",
      },
    };
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        input: {
          ...this.state.input,
          value: e.target.value,
        },
      },
      () => {
        if (this.state.input.value.length > 5000)
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: true,
                error: "Too long (Max: 5000 chars)",
              },
            },
            () =>
              document
                .getElementById("approval-input")
                .setCustomValidity(this.state.input.error)
          );
        else
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: false,
                error: "",
              },
            },
            () =>
              document.getElementById("approval-input").setCustomValidity("")
          );
      }
    );
  };

  /**
   * Triggered when ther user clicks Approve
   * Makes approval request to server
   * Resets form
   * Purges all reports from that Profile
   * Notifies the user
   */
  approve = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
          approving: true,
        },
        () =>
          axios
            .get("/support/users/approve/" + this.props.report.userInfo._id)
            .then(() =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                  approving: false,
                  input: {
                    id: "approval-input",
                    error: "",
                    invalid: false,
                    value: "",
                  },
                },
                () => {
                  document.getElementById("approval-form").reset();
                  this.props.purgeReports({
                    type: "approval",
                    text: <>@{this.props.report.userInfo.username} Approved</>,
                    icon: (
                      <i className="far fa-check-circle me-2 text-success"></i>
                    ),
                    reportID: this.props.report._id,
                  });
                }
              )
            )
      );
  };

  /**
   * Triggered when ther user clicks Reject
   * Makes reject request to server
   * Resets form
   * Purges all reports from that Profile
   * Notifies the user
   */
  reject = () => {
    document.getElementById("approval-form").classList.add("was-validated");
    if (this.state.input.value.length > 5000)
      document.getElementById("approval-input").setCustomValidity("hello");
    if (!this.state.working && this.state.input.value.length <= 5000)
      this.setState(
        {
          ...this.state,
          working: true,
          rejecting: true,
        },
        async () => {
          try {
            const submission = {
              details: this.state.input.value,
              userID: this.props.report.userInfo._id,
            };
            axios
              .post("/support/users/reject", submission)
              .then(() =>
                this.setState(
                  {
                    ...this.state,
                    ...this.state,
                    working: false,
                    approving: false,
                    input: {
                      id: "approval-input",
                      error: "",
                      invalid: false,
                      value: "",
                    },
                  },
                  () => {
                    document.getElementById("approval-form").reset();
                    this.props.purgeReports({
                      type: "approval",
                      text: (
                        <>@{this.props.report.userInfo.username} Rejected</>
                      ),
                      icon: (
                        <i className="far fa-times-circle me-2 text-danger"></i>
                      ),
                      reportID: this.props.report._id,
                    });
                  }
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  render() {
    return (
      <>
        <div className="d-flex">
          <MDBBtn
            color="success"
            disabled={this.state.working}
            onClick={this.approve}
          >
            {this.state.approving ? (
              <>
                <Spinner className="me-2" size="sm" />
                Approving
              </>
            ) : (
              <>
                <i className="far fa-check-circle me-2" />
                Approve
              </>
            )}
          </MDBBtn>
          <MDBBtn
            color="danger"
            disabled={this.state.working}
            onClick={this.reject}
            className="ms-2"
          >
            {this.state.rejecting ? (
              <>
                <Spinner className="me-2" size="sm" />
                Rejecting
              </>
            ) : (
              <>
                <i className="far fa-times-circle me-2" />
                Reject
              </>
            )}
          </MDBBtn>
        </div>
        <hr></hr>
        <MDBValidation
          className="h-100"
          method="dialog"
          id="approval-form"
          name="approval-form"
        >
          <MDBValidationItem
            className="pb-4 h-100 h-100-child"
            feedback={this.state.input.error}
            invalid={true}
          >
            <MDBTextArea
              name="approval-input"
              onChange={this.changeHandler}
              id="approval-input"
              label="Details"
              size="lg"
              className={`h-100 ${!this.state.input.invalid ? "mb-0" : 0}`}
            />
          </MDBValidationItem>
        </MDBValidation>
      </>
    );
  }
}

export default Approval;
