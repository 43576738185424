import React from 'react';

/**
 * 
 * @returns The ripple animation that is used in the Live Now button when a profile is streaming live
 */
const Ripple = () => (
    <div className="spinner-ripple">
        <div className="spinner-ripple-child">
            <div></div>
            <div></div>
        </div>
    </div>
)

export default Ripple;