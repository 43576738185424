import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../../../components/Spinner";
import {
  edit_user_schema,
  change_password_schema,
} from "../../../../utilities/validations";
import t from "../../../../utilities/transitions";
import { motion } from "framer-motion";
import axios from "axios";
import {
  change_user_details,
  toggle_private,
  toggle_disable_messages,
} from "../../../../redux/actions";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import EditProfileForm from "./EditProfileForm";

const fields = [
  {
    text: "Email Address",
    id: "email",
    type: "text",
  },
  {
    text: "Display Name",
    id: "displayName",
    type: "text",
  },
  {
    text: "Location (Optional)",
    id: "location",
    type: "text",
  },
  {
    text: "Website (Optional)",
    id: "website",
    type: "text",
  },
];

const changePassword = [
  {
    id: "current_password",
    text: "Current Password",
  },
  {
    id: "password1",
    text: "New Password",
  },
  {
    id: "password2",
    text: "Re-enter password",
  },
];

class EditProfileModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * inputs: Array - The Edit Profile form input data (values, errors, etc)
       * changePasswordInputs: arrray - The Change Password form input data (values, errors, etc)
       * working: Boolean - Whether the selected form is in the process of being submitted
       * privating: Boolean - Whether the user is in the process of privating their profile
       * disablingMessages: Boolean - Whether the user is in the process of disabling private messages
       * reset: Boolean - Measures nothing, but then flipped, fixes a MDB ui bug
       * formShown: String - The form that the user has open - "info" | "password",
       * staticBackdrop: Boolean - Whether the modal backdrop is static
       */
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: props.userInfo[field.id],
      })),
      changePasswordInputs: changePassword.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: "",
      })),
      working: false,
      privating: false,
      disablingMessages: false,
      reset: false,
      formShown: "info",
      staticBackdrop: false,
    };
  }

  /**
   * Run blank change handler
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   * Add mousedown event listener for closeModal method
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setModalBodyHeight();
      if (this.props.modalShown)
        document.addEventListener("mousedown", this.closeModal);
    }
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeModal);
    window.removeEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Triggered by the mousedown event
   *
   * Close modal only if the user clicks one of the close buttons or the actual backdrop
   * Prevents bug that closes the modal when clicking anywhere while the modal is growing or shrinking
   */
  closeModal = (e) => {
    if (
      e.target.classList.contains("modal") &&
      !this.state.working &&
      this.props.modalShown
    ) {
      this.props.toggleShowModal();
      document.removeEventListener("mousedown", this.closeModal);
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          edit_user_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document
                .getElementById(input.id + "-edit-profile")
                .setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          console.log(err);
          let errorsAdded = [];
          if (err.inner) {
            this.setState(
              {
                ...this.state,
                inputs: this.state.inputs.map((input) => {
                  if (
                    err.inner.find((error) => error.path === input.id) &&
                    errorsAdded.indexOf(input.id) === -1
                  ) {
                    errorsAdded.push(input.id);
                    return {
                      ...input,
                      invalid: true,
                      error: err.inner.find((error) => error.path === input.id)
                        .message,
                    };
                  } else
                    return {
                      ...input,
                      invalid: false,
                      error: "",
                    };
                }),
              },
              () =>
                this.state.inputs.forEach((input) =>
                  document
                    .getElementById(input.id + "-edit-profile")
                    .setCustomValidity(input.error)
                )
            );
          }
        }
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user to user account that the server responds with
   * If unsuccessful and error 401, alert invalid username or password
   * If unsuccessful and error 403, alert user is locked out
   * If unsuccessful and not error 401 or 403, general error alert
   */
  submit = () => {
    if (this.state.formShown === "info") {
      document
        .getElementById("form-edit-profile")
        .classList.add("was-validated");
      this.forceParse();
      let invalidInputs = this.state.inputs.filter((input) => input.invalid);
      invalidInputs.forEach((input) =>
        document
          .getElementById(input.id + "-edit-profile")
          .setCustomValidity(input.error)
      );
      if (!this.state.working && !invalidInputs.length)
        this.setState(
          {
            ...this.state,
            working: true,
          },
          async () => {
            const data = Object.fromEntries(
              this.state.inputs.map((input) => [input.id, input.value])
            );
            try {
              edit_user_schema.validateSync(data, {
                abortEarly: false,
              });
              const captchaKey = await this.getRecaptcha();
              const fd = new FormData();
              for (const key in data) {
                fd.append(key, data[key]);
              }
              fd.append("captchaKey", captchaKey);
              const bio = document.getElementById("input-bio-edit-self");
              const length = String(bio.textContent)
                .split("")
                .filter((c) => {
                  const checkWhiteSpace = c.match(/[\s]/);
                  if (!checkWhiteSpace) return true;
                  else {
                    return [" ", "\n"].indexOf(c) > -1;
                  }
                }).length;
              if (length > 1000)
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => alert("Your bio is too long (Max: 1000 chars)")
                );
              else {
                fd.append(
                  "bio",
                  bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "")
                );
                axios
                  .post("/profile/update", fd)
                  .then((res) =>
                    this.setState(
                      {
                        ...this.state,
                        working: false,
                      },
                      () => {
                        document
                          .getElementById("form-edit-profile")
                          .classList.remove("was-validated");
                        this.props.change_user_details(res.data.userInfo);
                        this.props.toggleShowModal();
                        setTimeout(
                          () =>
                            this.props.notify(
                              <i className="fas fa-user me-2 text-success"></i>,
                              "User Info Updated"
                            ),
                          200
                        );
                      }
                    )
                  )
                  .catch((err) =>
                    this.setState(
                      {
                        ...this.state,
                        working: false,
                      },
                      () => {
                        if (err.response && err.response.status === 400)
                          alert(err.response.data.message);
                        else {
                          console.log(err);
                          alert("An error occurred. Please try again later.");
                        }
                      }
                    )
                  );
              }
            } catch (err) {
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              );
            }
          }
        );
    } else {
      document
        .getElementById("form_change_password")
        .classList.add("was-validated");
      let invalidInputs = this.state.changePasswordInputs.filter(
        (input) => input.invalid
      );
      invalidInputs.forEach((input) =>
        document.getElementById(input.id).setCustomValidity(input.error)
      );
      if (!this.state.working && !invalidInputs.length)
        this.setState(
          {
            ...this.state,
            working: true,
          },
          async () => {
            const data = Object.fromEntries(
              this.state.changePasswordInputs.map((input) => [
                input.id,
                input.value,
              ])
            );
            try {
              change_password_schema.validateSync(data, {
                abortEarly: false,
              });
              const captchaKey = await this.getRecaptcha();
              const fd = new FormData();
              for (const key in data) {
                fd.append(key, data[key]);
              }
              fd.append("captchaKey", captchaKey);
              axios
                .post("/profile/change-password", fd)
                .then(() =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                      changePasswordInputs: changePassword.map((field) => ({
                        id: field.id,
                        error: "",
                        invalid: false,
                        value: "",
                      })),
                    },
                    () => {
                      this.changeForm();
                      this.props.notify(
                        <i className="fas fa-lock me-2 text-success"></i>,
                        "Password Changed"
                      );
                    }
                  )
                )
                .catch((err) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      if (err.response && err.response.status === 400)
                        alert(err.response.data.message);
                      else {
                        console.log(err);
                        alert("An error occurred. Please try again later.");
                      }
                    }
                  )
                );
            } catch (err) {
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              );
            }
          }
        );
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler_password = (e) =>
    this.setState(
      {
        ...this.state,
        changePasswordInputs: this.state.changePasswordInputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.changePasswordInputs.map((input) => [
            input.id,
            input.value,
          ])
        );
        try {
          change_password_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            changePasswordInputs: this.state.changePasswordInputs.map(
              (input) => {
                document.getElementById(input.id).setCustomValidity("");
                return {
                  ...input,
                  invalid: false,
                  error: "",
                };
              }
            ),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              changePasswordInputs: this.state.changePasswordInputs.map(
                (input) => {
                  if (
                    err.inner.find((error) => error.path === input.id) &&
                    errorsAdded.indexOf(input.id) === -1
                  ) {
                    errorsAdded.push(input.id);
                    return {
                      ...input,
                      invalid: true,
                      error: err.inner.find((error) => error.path === input.id)
                        .message,
                    };
                  } else
                    return {
                      ...input,
                      invalid: false,
                      error: "",
                    };
                }
              ),
            },
            () => {
              this.state.changePasswordInputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              );
            }
          );
        }
      }
    );

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   * Toggles the form
   * Sets modal body height
   * Runs a blank changeHandler on the new form
   */
  changeForm = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          formShown: this.state.formShown === "info" ? "password" : "info",
        },
        () =>
          setTimeout(() => {
            this.setModalBodyHeight();
            if (this.state.formShown === "info")
              this.changeHandler({
                target: {
                  name: "",
                },
              });
            else
              this.changeHandler_password({
                target: {
                  name: "",
                },
              });
          }, 333)
      );
  };

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () =>
    (document.getElementById("edit-profile-modal-body").style.height = `${
      document.getElementById("edit-profile-modal-body-child").clientHeight
    }px`);

  /**
   * Triggered when the user clicks Private Profile
   * Makes request to server
   * Notifies the user
   * Updates user info in application state
   */
  privateProfile = () => {
    if (!this.state.privating)
      this.setState(
        {
          ...this.state,
          privating: true,
        },
        () =>
          axios
            .get("/profile/private")
            .then(() =>
              this.setState(
                {
                  ...this.state,
                  privating: false,
                },
                () => {
                  this.props.notify(
                    <i className="fas fa-lock text-danger me-2"></i>,
                    "Profile is now private"
                  );
                  this.props.toggle_private();
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  privating: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user clicks Unprivate Profile
   * Makes request to server
   * Notifies the user
   * Updates user info in application state
   */
  unprivate = () => {
    if (!this.state.privating)
      this.setState(
        {
          ...this.state,
          privating: true,
        },
        () =>
          axios
            .get("/profile/unprivate")
            .then(() =>
              this.setState(
                {
                  ...this.state,
                  privating: false,
                },
                () => {
                  this.props.notify(
                    <i className="fas fa-unlock text-success me-2"></i>,
                    "Profile is now public"
                  );
                  this.props.toggle_private();
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  privating: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when ther user clicks Disable Private Messages or Enable Private Messages
   */
  toggleDisableMessages = () => {
    if (!this.state.disablingMessages)
      this.setState(
        {
          ...this.state,
          disablingMessages: true,
        },
        () =>
          axios
            .get("/profile/disable-messages")
            .then(() =>
              this.setState(
                {
                  ...this.state,
                  disablingMessages: false,
                },
                () => {
                  if (this.props.userInfo.messagesDisabled)
                    this.props.notify(
                      <i className="fas fa-comment text-success me-2"></i>,
                      "Private messages enabled."
                    );
                  else
                    this.props.notify(
                      <i className="fas fa-comment-slash text-danger me-2"></i>,
                      "Private messages disabled"
                    );
                  this.props.toggle_disable_messages();
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  disablingMessages: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            staticBackdrop={true}
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {this.state.formShown === "info" ? (
                      <motion.div
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        Edit Profile Info
                      </motion.div>
                    ) : (
                      <motion.p
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        Change Password
                      </motion.p>
                    )}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <div id="edit-profile-modal-body" className="transition-25">
                  <MDBModalBody id="edit-profile-modal-body-child">
                    <StaticRouter location={this.state.formShown}>
                      <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.formShown}>
                          <Route exact path=":form">
                            <EditProfileForm
                              form={this.state.formShown}
                              pressEnter={this.pressEnter}
                              submit={this.submit}
                              changeHandler={this.changeHandler}
                              inputs={this.state.inputs}
                              fields={fields}
                              modalShown={this.props.modalShown}
                              changePassword={changePassword}
                              changeHandler_password={
                                this.changeHandler_password
                              }
                              changePasswordInputs={
                                this.state.changePasswordInputs
                              }
                              working={this.state.working}
                              setForceParse={(f) => (this.forceParse = f)}
                            />
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </StaticRouter>
                  </MDBModalBody>
                </div>
                <MDBModalFooter
                  style={{ zIndex: 999 }}
                  className="d-flex justify-content-between align-items-start edit-profile-footer"
                >
                  <div className="d-flex edit-profile-buttons-actions">
                    <MDBBtn onClick={this.changeForm} className="bg-purple">
                      {this.state.formShown === "info" ? (
                        <>
                          <i className="fas fa-key me-2"></i>
                          Change Password
                        </>
                      ) : (
                        <>
                          <i className="fas fa-user me-2"></i>
                          Edit Profile
                        </>
                      )}
                    </MDBBtn>
                    {this.state.privating ? (
                      <MDBBtn disabled className="mx-2" color="danger">
                        <Spinner className="me-2" size="sm" />
                        {this.props.userInfo.private
                          ? "Unprivating"
                          : "Privating"}
                      </MDBBtn>
                    ) : (
                      <>
                        {this.props.userInfo.private ? (
                          <MDBBtn
                            onClick={this.unprivate}
                            className="mx-2"
                            color="danger"
                          >
                            <i className="fas fa-unlock me-2"></i>
                            Unprivate Profile
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            onClick={this.privateProfile}
                            className="mx-2"
                            color="danger"
                          >
                            <i className="fas fa-lock me-2"></i>
                            Private Profile
                          </MDBBtn>
                        )}
                      </>
                    )}
                    {this.state.disablingMessages ? (
                      <MDBBtn disabled color="dark">
                        <Spinner className="me-2" size="sm" />
                        {this.props.userInfo.messagesDisabled
                          ? "Enabling Messages"
                          : "Disable Messages"}
                      </MDBBtn>
                    ) : (
                      <MDBBtn onClick={this.toggleDisableMessages} color="dark">
                        {this.props.userInfo.messagesDisabled ? (
                          <>
                            <i className="fas fa-comment me-2"></i>
                            Enable Private Messages
                          </>
                        ) : (
                          <>
                            <i className="fas fa-comment-slash me-2"></i>
                            Disable Private Messages
                          </>
                        )}
                      </MDBBtn>
                    )}
                  </div>
                  <div className="d-flex align-items-start edit-profile-buttons-decisions">
                    {this.state.working ? (
                      <MDBBtn disabled className="me-2" color="primary">
                        <Spinner size="sm" className="me-2" />
                        Saving
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        className="me-2"
                        onClick={this.submit}
                        color="primary"
                      >
                        <i className="fas fa-save me-2"></i>Save Changes
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </div>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  change_user_details,
  toggle_private,
  toggle_disable_messages,
})(EditProfileModal);
