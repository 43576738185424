import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EmissionPage from "../components/emission/EmissionPage";
class Emission extends React.Component {
  constructor(props) {
    super();
    this.emissionID = Number(props.match.params.emissionID.split("#")[0]);
    this.state = {
      /**
       * loaded: Boolean - Whether the initial emission data has been loaded
       * loadingMore: Array - List of emissions with replies that are currently loading more replies
       */
      loaded: false,
      loadingMore: [],
    };
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("new-emission");
      this.props.socket.off("vote");
      this.props.socket.off("like");
      this.props.socket.off("signalboost");
      this.props.socket.off("update-user");
      this.props.socket.off("remove-emission");
      this.props.socket.off("restore-emission");
      this.props.socket.off("ban");
      this.props.socket.off("restore-user");
      this.props.socket.off("private");
      this.props.socket.off("reply");
      this.props.socket.off("view");
      this.props.socket.off("views");
      this.props.socket.off("unprivate");
      this.props.socket.off("block");
      this.props.socket.off("unblock");
    }
  }

  /**
   * Loads the initial data
   * Sets the emissions into state
   * If there are fewer than 20 results, the end has been reached
   */
  load = () => {
    return new Promise((resolve) =>
      axios
        .get("/api/emissions/" + this.emissionID)
        .then((res) => {
          setTimeout(() => {
            const emission = res.data.emissions.find(
              (e) => e.emissionID === this.emissionID
            );
            const element = document.getElementById(
              `emission-${this.emissionID}`
            );
            if (element && emission.replyEmission) element.scrollIntoView();
          }, 333);
          resolve({
            emissions: res.data.emissions,
          });
        })
        .catch((err) => {
          console.log("load emission err", err);
          setTimeout(async () => {
            const data = await this.load();
            resolve(data);
          }, 1000);
        })
    );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks the View More button in a reply thread
   * Loads more replies from the thread
   */
  seeMore = (emissionID, callback) => {
    if (this.state.loadingMore.indexOf(emissionID) === -1)
      this.setState(
        {
          ...this.state,
          loadingMore: [...this.state.loadingMore, emissionID],
        },
        () =>
          axios
            .get("/api/emissions/more-replies/" + emissionID)
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  loadingMore: this.state.loadingMore.filter(
                    (m) => m !== emissionID
                  ),
                },
                () =>
                  callback({
                    emissions: res.data.emissions,
                  })
              )
            )
            .catch((err) => {
              console.log("see more error", err);
              setTimeout(this.seeMore, 1000);
            })
      );
  };

  render() {
    return (
      <EmissionPage
        load={this.load}
        seeMore={this.seeMore}
        flavor="emission"
        loaded={this.state.loaded}
        loadingMore={this.state.loadingMore}
        emissionID={this.emissionID}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(withRouter(Emission));
