import React from "react";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBRipple,
  MDBTooltip,
  MDBBadge,
  MDBBtn,
  MDBInput,
} from "mdb-react-ui-kit";
import ToastMisc from "../components/notifications/ToastMisc";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  route,
  toggle_dark_mode,
  reconnect_socket,
  update_user,
  purge_user,
  new_follow_self,
  new_stream_chat,
  new_viewer,
  leave_viewer,
  set_unread_messages,
  set_notification_count,
  set_open_reports,
  set_unread_mod_logs,
  set_search_term,
  search_change,
  viewers,
  clear_stream_chat,
} from "../redux/actions";
import { motion } from "framer-motion";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import axios from "axios";

class SideNav extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * searchOpen: Boolean - Whether the searchbox is open
       * notificationText: String - Notification text
       * notificationIcon: JSX - Notification icon
       * reset: Boolean - Measures nothing, but when toggled, fixes MDB ui bug with search box
       * navOpen: Boolean - Whether the navigation is open on mobile screens
       * screenSize: Number - Width of the screen in pixels
       */
      searchOpen: false,
      notificationText: "",
      notificationIcon: <></>,
      reset: false,
      navOpen: false,
      screenSize: 0,
    };
    this.toastRef = React.createRef(null); // Displays the MDB toast notification when clicked
  }

  /**
   * If socket already initiated, emit the "login" signal
   * Add resize event listener, and run the resize function to get the screen size
   */
  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  /**
   * If theme changes, swap the css files
   * If the user is demoted and on a page they are no longer authorized to be on, route to their profile
   * Initialize socket once set
   * Fix MDB bug that adds scrollbars to the side nav for no reason
   * Reset message sockets if the user navigates to a page that is not the Messages page
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.userInfo.userSettings.theme !==
      this.props.userInfo.userSettings.theme
    ) {
      [].slice
        .call(document.head.getElementsByTagName("link"))
        .forEach((link, l) => {
          if (link.href.includes("/bootstrap/")) {
            link.href =
              link.href.split("/bootstrap/")[0] +
              `/bootstrap/${this.props.userInfo.userSettings.theme}.css`;
          }
          if (link.href.includes("custom-")) {
            link.href =
              link.href.split("custom-")[0] +
              "custom-" +
              this.props.userInfo.userSettings.theme +
              ".css";
          }
        });
    }

    if (
      prevProps.userInfo.role !== this.props.userInfo.role &&
      ((!h.checkJanny(this.props.userInfo) &&
        this.props.history.location.pathname.split("/")[1].split("#")[0] ===
          "logs") ||
        (!h.checkChadmin(this.props.userInfo) &&
          this.props.history.location.pathname.split("/")[1].split("#")[0] ===
            "reports"))
    )
      this.props.route(`/${this.props.userInfo.username}`);

    if (!prevProps.socket && this.props.socket) this.initializeSocket();

    this.fixMDB();

    if (prevProps.historyStack.length !== this.props.historyStack.length)
      this.setMessageSockets();

    // if (
    //   prevProps.socket.id &&
    //   this.props.socket.id &&
    //   prevProps.socket.id !== this.props.socket.id
    // )
    //   this.props.socket.emit("login");
  }

  /**
   * Remove resize event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  /**
   * Update screen size in state
   *
   */
  resize = () =>
    this.setState({
      ...this.state,
      screenSize: window.innerWidth,
    });

  /**
   * Once socket is reconnected, re-initialize
   * Reconnecting by itself sometimes turns off all the methods, so need to do this
   *
   */
  socketDisconnect = () =>
    setTimeout(() => {
      if (this.props.socket.connected) this.initializeSocket();
      else this.socketDisconnect();
    }, 1500);

  /**
   * Turn off all methods
   * Tell other components that rely on the socket to re-initialize with redux reconnect_socket function
   * Emit login event
   * Turn on all methods
   */
  initializeSocket = () => {
    this.props.socket.off("disconnect");
    this.props.socket.off("mod-profile-update");
    this.props.socket.off("ban-self");
    this.props.socket.off("restore-self");
    this.props.socket.off("follow-self");
    this.props.socket.off("stream-chat");
    this.props.socket.off("stream-chat-self");
    this.props.socket.off("new-viewer");
    this.props.socket.off("new-viewer-self");
    this.props.socket.off("leave-viewer");
    this.props.socket.off("leave-viewer-self");
    this.props.socket.off("report-count");
    this.props.socket.off("unread-modlog-count");
    this.props.socket.off("new-mod-log");
    this.props.socket.off("viewers");
    this.props.socket.off("viewers-self");
    this.props.socket.off("stream-terminated");
    this.props.socket.off("initialized");

    this.props.reconnect_socket();

    this.props.socket.on("initialized", () => {
      // console.log("initialized");
    });

    this.props.socket.on("stream-terminated", this.props.clear_stream_chat);
    this.props.socket.on("new-mod-log", () =>
      this.props.set_unread_mod_logs(this.props.unreadModLogs + 1)
    );
    this.props.socket.on("unread-modlog-count", this.props.set_unread_mod_logs);
    this.props.socket.on("report-count", this.props.set_open_reports);

    /**
     * Triggered when a mod updates the user's profile
     * Notify the user
     * Update the user
     */
    this.props.socket.on("mod-profile-update", (data) => {
      if (data.ban.banned && !this.props.userInfo.ban.banned) {
        this.notify(
          <i className="fas fa-gavel text-danger me-2"></i>,
          "You have been banned"
        );
      } else if (!data.ban.banned && this.props.userInfo.ban.banned) {
        this.notify(
          <i className="fas fa-gavel text-success me-2"></i>,
          "Your ban has been lifted"
        );
      } else
        this.notify(
          <i className="fas fa-gavel text-success me-2"></i>,
          "Your profile has been updated by a Janny"
        );
      this.props.update_user(data);
    });

    this.props.socket.on("disconnect", this.socketDisconnect);
    this.props.socket.on("follow-self", this.props.new_follow_self);

    this.props.socket.on("viewers", (viewers) => {
      if (!this.props.userInfo.live) this.props.viewers(viewers);
    });
    this.props.socket.on("viewers-self", this.props.viewers);

    this.props.socket.on("new-viewer", () => {
      if (!this.props.userInfo.live) this.props.new_viewer();
    });
    this.props.socket.on("new-viewer-self", this.props.new_viewer);

    this.props.socket.on("leave-viewer", () => {
      if (!this.props.userInfo.live) this.props.leave_viewer();
    });
    this.props.socket.on("leave-viewer", this.props.leave_viewer);

    this.props.socket.on("stream-chat", (message) => {
      if (!this.props.userInfo.live) this.props.new_stream_chat(message);
    });

    this.props.socket.on("stream-chat-self", this.props.new_stream_chat);
    this.setMessageSockets();
  };

  /**
   * Initializes the message and notification sockets only
   * If user is not on the Messages page, initialize the message socket events
   * If user is not on the Notifications page, initialize the notification socket events
   */
  setMessageSockets = () => {
    if (
      this.props.socket &&
      [
        "",
        "notifications",
        "logs",
        "reports",
        "search",
        this.props.userInfo.username,
      ].indexOf(
        this.props.history.location.pathname.split("/")[1].split("#")[0]
      ) > -1
    ) {
      this.props.socket.off("message-count");
      this.props.socket.off("new-message");

      this.props.socket.on("message-count", this.props.set_unread_messages);
      this.props.socket.on("new-message", (message) => {
        if (message.author !== this.props.userInfo._id)
          this.props.set_unread_messages(
            this.props.userInfo.unreadMessages + 1
          );
      });
    }
    if (
      this.props.history.location.pathname.split("/")[1].split("#")[0] !==
      "notifications"
    ) {
      this.props.socket.off("notification");
      this.props.socket.off("remove-notification");
      this.props.socket.off("notification-count");

      this.props.socket.on(
        "notification-count",
        this.props.set_notification_count
      );
      this.props.socket.on("remove-notification", () =>
        this.props.set_notification_count(this.props.userInfo.notifications - 1)
      );
      this.props.socket.on("notification", () =>
        this.props.set_notification_count(this.props.userInfo.notifications + 1)
      );
    }
  };

  /**
   * Removes the persistent classes that keep getting added to the side nav which create scrollbars
   *
   */
  fixMDB = () =>
    setTimeout(
      () =>
        [].slice
          .call(document.getElementById("sidenav-main").childNodes)
          .forEach((node) => {
            if (node.classList.contains("ps--active-y"))
              node.classList.remove("ps--active-y");
          }),
      500
    );

  /**
   * Toggles the search box
   *
   * If search box is opened:
   * * Focus on the text input
   * * Add click event to close the search box if clicking outside the sidenav
   * * Toggle state.reset (Fixes floating label MDB issue)
   *
   * If search box is closed, remove click event listener
   */
  toggleSearch = () =>
    this.setState(
      {
        ...this.state,
        searchOpen: !this.state.searchOpen,
      },
      () => {
        if (this.state.searchOpen) {
          document.getElementById("nav-search-input").focus();
          setTimeout(
            () => document.addEventListener("click", this.clickOutsideNav),
            100
          );
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                reset: !this.state.reset,
              }),
            100
          );
        } else document.removeEventListener("click", this.clickOutsideNav);
      }
    );

  /**
   *
   * @param {Click Event} e
   *
   * If user clicks outside the side nav while the search box is open, close the search box
   */
  clickOutsideNav = (e) => {
    if (
      !document.getElementById("sidenav-main").contains(e.target) &&
      this.state.searchOpen
    )
      this.setState(
        {
          ...this.state,
          searchOpen: false,
        },
        () => document.removeEventListener("click", this.clickOutsideNav)
      );
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - Path that the user wishes to navigate to
   *
   * Triggered when the user clicks on one of the nav items
   * Routes to the desired path, closes the nav on mobile devices, closes the search box, and removes the click event
   */
  nav = (e, path) => {
    e.preventDefault();
    this.props.route(path);
    this.setState(
      {
        ...this.state,
        searchOpen: false,
        navOpen: false,
      },
      () => {
        if (this.state.searchOpen)
          document.removeEventListener("click", this.clickOutsideNav);
      }
    );
  };

  /**
   * Clicked when the user clicks the Toggle Dark Mode button
   * Toggles dark mode
   */
  toggleDarkMode = () => {
    this.props.toggle_dark_mode();
    axios.get("/auth/toggle-dark-mode");
  };

  /**
   *
   * @param {JSX} icon - Notification icon
   * @param {String} text - Notification text
   *
   * Sets notification text and icon into state, then clicks the MDB toast ref element
   */
  notify = (icon, text) =>
    this.setState(
      {
        ...this.state,
        notificationText: text,
        notificationIcon: icon,
      },
      () => this.toastRef.current.click()
    );

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const searchTop = document.getElementById("search-top");
      if (searchTop) searchTop.scrollIntoView();
      this.setState(
        {
          ...this.state,
          searchOpen: false,
          navOpen: false,
        },
        this.props.set_search_term
      );
    }
  };

  /**
   * Triggered when users on mobile screens open or close the side nav
   * Toggles the side nav
   */
  toggleNavOpen = () =>
    this.setState({
      ...this.state,
      navOpen: !this.state.navOpen,
    });

  render() {
    const totalNotifications =
      this.props.userInfo.notifications +
      this.props.userInfo.unreadMessages +
      this.props.openReports +
      this.props.unreadModLogs;
    return (
      <motion.div
        className="position-relative"
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBBtn
          id="toast-trigger-side-nav"
          className="d-none"
          color="primary"
          ref={this.toastRef}
        >
          Toast
        </MDBBtn>
        <ToastMisc
          triggerRef={this.toastRef}
          id={"side-nav-toast"}
          icon={this.state.notificationIcon}
          text={this.state.notificationText}
        />
        <MDBBtn
          color="link"
          className={`position-fixed text-primary d-flex flex-column align-items-center menu-toggle text-darkblu ${
            this.state.navOpen ? "left-86" : "left-0"
          }`}
          size="sm"
          onClick={this.toggleNavOpen}
          rippleColor="#1976d2"
          style={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          {totalNotifications && !this.state.navOpen ? (
            <div className="w-max-content">
              <i
                className={`fas fa-${
                  this.state.navOpen ? "chevron-left" : "bars"
                } text-center d-block mx-auto`}
              ></i>
              <MDBBadge
                className="position-absolute"
                style={{
                  top: "2px",
                  right: "15px",
                }}
                color="danger"
                notification
                pill
              >
                {totalNotifications}
              </MDBBadge>
            </div>
          ) : (
            <i
              className={`fas fa-${
                this.state.navOpen ? "chevron-left" : "bars"
              } text-center d-block mx-auto`}
            ></i>
          )}
          <p className="mb-0 mt-2 text-center">Menu</p>
        </MDBBtn>
        <MDBSideNav
          backdrop={false}
          closeOnEsc={false}
          slim={!this.state.searchOpen}
          mode="side"
          slimCollapsed
          id="sidenav-main"
          isOpen={this.state.navOpen || this.state.screenSize > 992}
        >
          <div
            className={`d-flex flex-column h-100 ${
              this.props.userInfo.ban.banned ? "bg-litepink" : ""
            }`}
          >
            <MDBRipple
              rippleColor={
                this.props.userInfo.userSettings.theme === "default"
                  ? "dark"
                  : "light"
              }
              className={`w-100 cursor-pointer`}
            >
              <Link onClick={(e) => this.nav(e, "/")} to="/">
                <div
                  style={{ width: "86px", height: "86px" }}
                  className="d-flex justify-content-center align-items-center mx-auto"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: 'url("/icons/favicon-96x96.png")',
                    }}
                  ></div>
                </div>
              </Link>
            </MDBRipple>

            <hr className="my-0" />
            <MDBSideNavMenu className="px-0">
              <MDBSideNavItem onClick={this.toggleSearch}>
                <MDBSideNavLink
                  active={this.state.searchOpen}
                  className="d-inline-block p-0 w-100"
                  style={{ height: "max-content" }}
                >
                  <motion.div
                    transition={t.transition}
                    exit={t.fade_out_minimize}
                    animate={t.normalize}
                    initial={t.fade_out_minimize}
                  >
                    <div
                      style={{ transition: "0.25s" }}
                      className={`d-flex ${
                        this.state.searchOpen ? "" : "flex-column"
                      } align-items-center py-${this.state.searchOpen ? 4 : 3}`}
                    >
                      <i
                        style={{ transition: "0.25s" }}
                        className={`fas fa-search ${
                          this.state.searchOpen ? "fa-lg" : "nav-icons"
                        } d-block ${
                          this.state.searchOpen ? "mx-3" : "mx-auto"
                        }`}
                      ></i>
                      {this.state.searchOpen ? (
                        <div className="me-2">
                          <MDBInput
                            defaultValue={this.props.searchText}
                            onChange={(e) =>
                              this.props.search_change(e, "sideNav")
                            }
                            id="nav-search-input"
                            onClick={(e) => e.stopPropagation()}
                            onKeyPress={this.pressEnter}
                            type="text"
                            label="Search"
                            className="form-control"
                            size="lg"
                            key={this.props.sideNavSearchKey + "search"}
                          />
                          {this.state.searchOpen && this.props.searchText ? (
                            <div className="position-relative">
                              <small className="mb-0 text-default position-absolute">
                                Press ENTER to search
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <p
                          style={{ fontSize: "0.75rem", transition: "0.25s" }}
                          className="mb-0 mt-2"
                        >
                          Search
                        </p>
                      )}
                    </div>
                  </motion.div>
                </MDBSideNavLink>
              </MDBSideNavItem>
              {this.props.userInfo.username || this.props.loggedIn ? (
                <>
                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) => this.nav(e, "/notifications")}
                      to={"/notifications"}
                    >
                      <MDBSideNavLink
                        active={
                          this.props.history.location.pathname
                            .split("/")[1]
                            .split("#")[0] === "notifications"
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            {this.props.userInfo.notifications ? (
                              <div
                                className={`w-max-content ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              >
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "notifications"
                                      ? "fas"
                                      : "far"
                                  } fa-bell ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } `}
                                ></i>
                                <MDBBadge color="danger" notification pill>
                                  {this.props.userInfo.notifications}
                                </MDBBadge>
                              </div>
                            ) : (
                              <i
                                style={{ transition: "0.25s" }}
                                className={`${
                                  this.props.history.location.pathname
                                    .split("/")[1]
                                    .split("#")[0] === "notifications"
                                    ? "fas"
                                    : "far"
                                } fa-bell ${
                                  this.state.searchOpen ? "fa-lg" : "nav-icons"
                                } d-block ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              ></i>
                            )}
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.75rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Notifications
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>

                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) => this.nav(e, "/messages")}
                      to={"/messages"}
                    >
                      <MDBSideNavLink
                        active={
                          this.props.history.location.pathname
                            .split("/")[1]
                            .split("#")[0] === "messages"
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            {this.props.userInfo.unreadMessages ? (
                              <div
                                className={`w-max-content ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              >
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "messages"
                                      ? "fas"
                                      : "far"
                                  } fa-envelope ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } `}
                                ></i>
                                <MDBBadge color="danger" notification pill>
                                  {this.props.userInfo.unreadMessages}
                                </MDBBadge>
                              </div>
                            ) : (
                              <i
                                style={{ transition: "0.25s" }}
                                className={`${
                                  this.props.history.location.pathname
                                    .split("/")[1]
                                    .split("#")[0] === "messages"
                                    ? "fas"
                                    : "far"
                                } fa-envelope ${
                                  this.state.searchOpen ? "fa-lg" : "nav-icons"
                                } d-block ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              ></i>
                            )}
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.75rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Messages
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>

                  {h.checkJanny(this.props.userInfo) ? (
                    <MDBSideNavItem>
                      <Link
                        className="text-default"
                        onClick={(e) => this.nav(e, "/reports")}
                        to={"/reports"}
                      >
                        <MDBSideNavLink
                          active={
                            this.props.history.location.pathname
                              .split("/")[1]
                              .split("#")[0] === "reports"
                          }
                          className="d-inline-block p-0 w-100"
                          style={{ height: "max-content" }}
                        >
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                          >
                            <div
                              style={{ transition: "0.25s" }}
                              className={`nav-item-containers d-flex ${
                                this.state.searchOpen ? "" : "flex-column"
                              } align-items-center`}
                            >
                              {this.props.openReports ? (
                                <div
                                  className={`w-max-content ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                >
                                  <i
                                    style={{ transition: "0.25s" }}
                                    className={`${
                                      this.props.history.location.pathname
                                        .split("/")[1]
                                        .split("#")[0] === "reports"
                                        ? "fas"
                                        : "far"
                                    } fa-flag ${
                                      this.state.searchOpen
                                        ? "fa-lg"
                                        : "nav-icons"
                                    } `}
                                  ></i>
                                  <MDBBadge color="danger" notification pill>
                                    {this.props.openReports}
                                  </MDBBadge>
                                </div>
                              ) : (
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "reports"
                                      ? "fas"
                                      : "far"
                                  } fa-flag ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } d-block ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                ></i>
                              )}
                              <p
                                style={{
                                  fontSize: this.state.searchOpen
                                    ? "1rem"
                                    : "0.75rem",
                                  transition: "0.25s",
                                }}
                                className={`mb-0 ${
                                  this.state.searchOpen ? "" : "mt-2"
                                }`}
                              >
                                Reports
                              </p>
                            </div>
                          </motion.div>
                        </MDBSideNavLink>
                      </Link>
                    </MDBSideNavItem>
                  ) : (
                    <></>
                  )}

                  {h.checkChadmin(this.props.userInfo) ? (
                    <MDBSideNavItem>
                      <Link
                        className="text-default"
                        onClick={(e) => this.nav(e, "/logs")}
                        to={"/logs"}
                      >
                        <MDBSideNavLink
                          active={
                            this.props.history.location.pathname
                              .split("/")[1]
                              .split("#")[0] === "logs"
                          }
                          className="d-inline-block p-0 w-100"
                          style={{ height: "max-content" }}
                        >
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                          >
                            <div
                              style={{ transition: "0.25s" }}
                              className={`nav-item-containers d-flex ${
                                this.state.searchOpen ? "" : "flex-column"
                              } align-items-center`}
                            >
                              {this.props.unreadModLogs ? (
                                <div
                                  className={`w-max-content ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                >
                                  <i
                                    style={{ transition: "0.25s" }}
                                    className={`${
                                      this.props.history.location.pathname
                                        .split("/")[1]
                                        .split("#")[0] === "logs"
                                        ? "fas"
                                        : "far"
                                    } fa-list-alt ${
                                      this.state.searchOpen
                                        ? "fa-lg"
                                        : "nav-icons"
                                    } `}
                                  ></i>
                                  <MDBBadge color="danger" notification pill>
                                    {this.props.unreadModLogs}
                                  </MDBBadge>
                                </div>
                              ) : (
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "logs"
                                      ? "fas"
                                      : "far"
                                  } fa-list-alt ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } d-block ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                ></i>
                              )}
                              <p
                                style={{
                                  fontSize: this.state.searchOpen
                                    ? "1rem"
                                    : "0.75rem",
                                  transition: "0.25s",
                                }}
                                className={`mb-0 ${
                                  this.state.searchOpen ? "" : "mt-2"
                                }`}
                              >
                                Mod Logs
                              </p>
                            </div>
                          </motion.div>
                        </MDBSideNavLink>
                      </Link>
                    </MDBSideNavItem>
                  ) : (
                    <></>
                  )}

                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) =>
                        this.nav(e, `/${this.props.userInfo.username}`)
                      }
                      to={`/${this.props.userInfo.username}`}
                    >
                      <MDBSideNavLink
                        active={
                          this.props.history.location.pathname
                            .split("/")[1]
                            .split("#")[0] === this.props.userInfo.username
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            <i
                              style={{ transition: "0.25s" }}
                              className={`${
                                this.props.history.location.pathname
                                  .split("/")[1]
                                  .split("#")[0] ===
                                this.props.userInfo.username
                                  ? "fas"
                                  : "far"
                              } fa-user ${
                                this.state.searchOpen ? "fa-lg" : "nav-icons"
                              } d-block ${
                                this.state.searchOpen ? "mx-3" : "mx-auto"
                              }`}
                            ></i>
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.75rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Profile
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>
                </>
              ) : (
                <>
                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) => this.nav(e, "/login")}
                      to={"/login"}
                    >
                      <MDBSideNavLink
                        active={
                          [
                            "login",
                            "create-account",
                            "forgot-password",
                          ].indexOf(
                            this.props.history.location.pathname
                              .split("/")[1]
                              .split("#")[0]
                          ) > -1
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            <i
                              style={{ transition: "0.25s" }}
                              className={`${
                                [
                                  "login",
                                  "create-account",
                                  "forgot-password",
                                ].indexOf(
                                  this.props.history.location.pathname
                                    .split("/")[1]
                                    .split("#")[0]
                                ) > -1
                                  ? "fas"
                                  : "far"
                              } fa-user ${
                                this.state.searchOpen ? "fa-lg" : "nav-icons"
                              } d-block ${
                                this.state.searchOpen ? "mx-3" : "mx-auto"
                              }`}
                            ></i>
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.7rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Login / Signup
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>
                </>
              )}
            </MDBSideNavMenu>
            <div className="fg-1 d-flex flex-column justify-content-end">
              <MDBTooltip
                placement="right"
                tag="div"
                title="Toggle Dark Mode"
                className="ms-2"
                wrapperProps={{ onClick: this.toggleDarkMode }}
              >
                <MDBRipple className="d-block p-4 cursor-pointer mx-auto rounded">
                  <i className="fas fa-sun nav-icons mx-auto d-block text-center"></i>
                </MDBRipple>
              </MDBTooltip>
            </div>
          </div>
        </MDBSideNav>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  toggle_dark_mode,
  reconnect_socket,
  update_user,
  purge_user,
  new_follow_self,
  new_stream_chat,
  new_viewer,
  leave_viewer,
  set_unread_messages,
  set_notification_count,
  set_open_reports,
  set_unread_mod_logs,
  set_search_term,
  search_change,
  viewers,
  clear_stream_chat,
})(withRouter(SideNav));
