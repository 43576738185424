import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';

class SideNav extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <Tabs className="w-max-content mt-5" orientation="vertical" value={this.props.tab} onChange={this.props.selectTab}>
                {this.props.userInfo._id ?
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-at text-success"></i>} 
                    iconPosition="start" 
                    label={process.env.REACT_APP_FOLLOW_CURRENT}
                    value="following"
                    id="tab-following"
                /> : <span className="d-none"></span>}
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-chart-line text-danger"></i>} 
                    iconPosition="start" 
                    label="Popular" 
                    value="popular" 
                    id="tab-popular"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: '#FFAB00'}} className="far fa-clock"></i>} 
                    iconPosition="start" 
                    label="Recent" 
                    value="recent" 
                    id="tab-recent"
                />
                {process.env.REACT_APP_STREAMING_ENABLED === 'true' ?
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: '#fc45df'}} className="fas fa-broadcast-tower"></i>} 
                    iconPosition="start" 
                    label="Live Now" 
                    value="live" 
                    id="tab-live"
                /> : <span className="d-none"></span>}
            </Tabs>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(SideNav);