import React from 'react';
import { motion } from 'framer-motion';
import t from '../../../../utilities/transitions';
import {
    MDBInput,
    MDBValidation,
    MDBValidationItem
} from 'mdb-react-ui-kit';

class ChangePassword extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    /**
     * 
     * @param {Event} e - Keypress event
     * 
     * Triggered when the user presses the Tab key
     * Moves cursor to next input (MDB is bugged)
     * Removed when MDB fixes
     */
    pressTab = e => {
        if (e.key === 'Tab'){
            e.preventDefault();
            const input = this.props.inputs.find(f => f.id === e.target.id);
            if (input){
                const nextField = this.props.inputs[this.props.inputs.indexOf(input) + 1];
                if (nextField){
                    const element = document.getElementById(nextField.id);
                    if (element){
                        setTimeout(() => {
                            element.focus();
                            element.select();
                        }, 100);
                    }
                }
            }
        }
    }

    render(){
        return (
            <motion.div className="max-w-95 mx-auto" style={{width: '500px'}} transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <MDBValidation className="py-4 mx-0" id="form_change_password" name="form_change_password" method="dialog">
                    {this.props.changePassword.map(field => (
                    <MDBValidationItem key={field.id} className="col-12 pb-4" feedback={this.props.inputs.find(input => input.id === field.id).error} invalid={true} >
                        <MDBInput
                            name={field.id}
                            onChange={this.props.changeHandler}
                            id={field.id}
                            label={field.text}
                            size="lg"
                            className={`change-password-inputs ${!this.props.inputs.find(input => input.id === field.id).invalid ? 'mb-0' : 0}`}
                            type="password"
                            onKeyPress={this.props.pressEnter}
                            onKeyDown={this.pressTab}
                        />
                    </MDBValidationItem>
                    ))}
                </MDBValidation>
            </motion.div>
        );
    }
}

export default ChangePassword;