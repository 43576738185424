import React from "react";
import Setup from "./Setup";
import Main from "./Main";
import Error from "./Error";

class GoLiveScreen extends React.Component {
  constructor(props) {
    super();
    this.screen = props.screen;
  }

  render() {
    switch (this.screen) {
      case "setup":
        return (
          <Setup
            videoDevices={this.props.videoDevices}
            stream={this.props.stream}
            peerOpen={this.props.peerOpen}
          />
        );
      case "main":
        return (
          <Main
            videoDevices={this.props.videoDevices}
            audioDevices={this.props.audioDevices}
            stream={this.props.stream}
            showCameraToggleButton={this.props.showCameraToggleButton}
            cameraDirection={this.props.cameraDirection}
            videoDimensions={this.props.videoDimensions}
            cameraEnabled={this.props.cameraEnabled}
            microphoneEnabled={this.props.microphoneEnabled}
            toggleCamera={this.props.toggleCamera}
            toggleMicrophone={this.props.toggleMicrophone}
            rotateCamera={this.props.rotateCamera}
            startStream={this.props.startStream}
            working={this.props.working}
            live={this.props.live}
            endStream={this.props.endStream}
            videoLabel={this.props.videoLabel}
            audioLabel={this.props.audioLabel}
            peerOpen={this.props.peerOpen}
            streamTitleChange={this.props.streamTitleChange}
            streamTitle={this.props.streamTitle}
            networkProblems={this.props.networkProblems}
          />
        );
      case "error":
        return (
          <Error
            videoDevices={this.props.videoDevices}
            stream={this.props.stream}
            error={this.props.error}
            reset={this.props.reset}
          />
        );
      default:
        console.log("oob go live screen", this.screen);
        return (
          <Error
            videoDevices={this.props.videoDevices}
            stream={this.props.stream}
            error="An unknown error occurred."
            reset={this.props.reset}
          />
        );
    }
  }
}

export default GoLiveScreen;
