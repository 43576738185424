import React from 'react';
import LinearProgress from '@mui/material/LinearProgress'
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import { connect } from 'react-redux';
import { route } from '../redux/actions';
import { withRouter } from 'react-router-dom';

class Number extends React.Component{
    constructor(props){
        super();
        this.number = props.match.params.number;
    }

    componentDidMount(){
        this.load();
    }

    /**
     * Fetches the username from the user_id
     * Routes to that user profile
     */
    load = () => axios.get('/users/number/' + this.number).then(res => this.props.route(`/${res.data.user}`)).catch(err => {
        if (err.response && err.response.status === 404) this.props.route('/not-found');
        else setTimeout(this.load, 1000);
    });

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} className="pt-4 h-100">
                <MDBContainer>
                    <h5 className="text-center display-6 mb-4 mt-5">Fetching User</h5>
                    <LinearProgress />
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route })(withRouter(Number));