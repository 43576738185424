import React from "react";
import { connect } from "react-redux";
import { set_poll_modal, update_emission } from "../../redux/actions";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import EmissionDetailed from "../../components/emission/EmissionDetailed";
import { MDBBtn } from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";

class Likes extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loaded: true,
    });
  }

  render() {
    const {
      profileInfo,
      clickEmissionBody,
      vote,
      polls,
      submitVotes,
      pollsSubmitting,
      selectFile,
      signalBoost,
      like,
      copyEmissionLink,
      emissionCopied,
      reply,
      userInfo,
      route,
      report,
      remove,
      restore,
      seeMore,
      loadingMore,
      set_poll_modal,
      update_emission,
      handlePin,
    } = this.props;
    const { loaded } = this.state;
    return (
      <>
        {profileInfo.likes.items.length ? (
          <>
            {profileInfo.likes.items.map((emission, e) => (
              <EmissionDetailed
                emission={emission}
                loaded={loaded}
                animation={t.fade_out_right}
                clickEmissionBody={clickEmissionBody}
                vote={vote}
                polls={polls}
                submitVotes={submitVotes}
                pollsSubmitting={pollsSubmitting}
                selectFile={selectFile}
                signalBoost={signalBoost}
                like={like}
                copyEmissionLink={copyEmissionLink}
                emissionCopied={emissionCopied}
                reply={reply}
                index={e}
                userInfo={userInfo}
                route={route}
                key={emission._id}
                remove={remove}
                restore={restore}
                report={report}
                profileInfo={profileInfo}
                updateEmission={update_emission}
                setPollModal={set_poll_modal}
                handlePin={handlePin}
              />
            ))}
            {profileInfo.likes.items.length >= profileInfo.likes.total ? (
              <></>
            ) : (
              <div className="py-4">
                {loadingMore ? (
                  <MDBBtn
                    size="lg"
                    disabled
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    <Spinner color="primary" size="sm" className="me-2" />
                    Please Wait
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    size="lg"
                    onClick={seeMore}
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    See More
                  </MDBBtn>
                )}
              </div>
            )}
          </>
        ) : (
          <motion.h5
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            className="text-center display-6 mt-4"
          >
            {profileInfo.user._id === userInfo._id
              ? "You have "
              : `${profileInfo.user.displayName} has `}
            not liked any{" "}
            <span className="text-lowercase">
              {process.env.REACT_APP_EMISSION_PLURAL}
            </span>
          </motion.h5>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { update_emission, set_poll_modal })(
  Likes
);
