import React from "react";
import { connect } from "react-redux";
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import {
  route,
  new_emission,
  vote,
  signal_boost,
  set_user,
  remove_emission,
  restore_emission,
  update_emission,
  like_other,
  set_profile,
  ban_other,
  new_follow,
  private_profile,
  disable_messages,
  stream_start,
  stream_end,
  reply,
  user_edit,
  restore_user,
  unprivate,
  block_other,
  unblock_other,
  view,
  views,
  add_message_profile,
  set_unread_messages,
  clear_profile,
} from "../redux/actions";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import { motion } from "framer-motion";
import axios from "axios";
import TextInput from "../components/textInput/TextInput";
import ToastMisc from "../components/notifications/ToastMisc";
import LoginModal from "../components/loginModal/LoginModal";
import { LinearProgress } from "@mui/material";
import ProfileInfoSelf from "./profile/self/ProfileInfo";
import ProfileInfoOther from "./profile/other/ProfileInfo";
import ProfileFeed from "./profile/ProfileFeed";
import { withRouter } from "react-router-dom";

const tabs = ["emissions", "emissionsAndReplies", "media", "likes"];

class Profile extends React.Component {
  constructor(props) {
    super();
    /**
     * this.profile: String - The username of the profile
     * this.username: String - The username of the user currently logged in
     */
    this.profile = props.match.params.profile
      .replace(/^[\W_]+/g, "")
      .toLowerCase();
    this.toastRef = React.createRef();
    this.username = props.userInfo.username;
    this.state = {
      /**
       * socketConnected: Boolean - Whether the main socket has connected
       */
      socketConnected: false,
      tabSelected:
        props.tempAction && props.tempAction.tab
          ? props.tempAction.tab
          : "emissions",
      tabExit: t.fade_out,
      tabEnter: t.fade_out,
      notificationIcon: <></>,
      notificationText: "",
      loginModalShown: false,
      updating: false,
      reset: false,
    };
  }

  /**
   * Connect socket
   */
  componentDidMount() {
    this.props.clear_profile();
    this.load();
    if (this.props.socket && !this.state.socketConnected) this.connectSocket();
  }

  /**
   * If socket is reconnected, re-initialize
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) this.connectSocket();
    if (
      prevProps.socketReconnect !== this.props.socketReconnect ||
      prevProps.userInfo._id !== this.props.userInfo._id
    )
      this.initializeSocket();
    if (
      (prevProps.userInfo._id &&
        this.props.userInfo._id &&
        h.checkJanny(prevProps.userInfo) !==
          h.checkJanny(this.props.userInfo)) ||
      (prevProps.userInfo._id &&
        this.props.userInfo._id &&
        h.checkChadmin(prevProps.userInfo) !==
          h.checkChadmin(this.props.userInfo)) ||
      (prevProps.profileInfo.user &&
        this.props.profileInfo.user &&
        (prevProps.profileInfo.user.ban.banned !==
          this.props.profileInfo.user.ban.banned ||
          prevProps.profileInfo.user.private !==
            this.props.profileInfo.user.private ||
          prevProps.profileInfo.blocksMe !== this.props.profileInfo.blocksMe ||
          prevProps.profileInfo.isBlocked !== this.props.profileInfo.isBlocked))
    )
      this.update();
  }

  // Leave the profile socket room when leaving the profile
  componentWillUnmount() {
    this.props.socket.emit("leave");
    this.props.clear_profile();
  }

  load = () => {
    axios
      .get(`/api/emissions/profile/${this.profile}`)
      .then((res) =>
        this.setState(
          {
            ...this.state,
            loaded: true,
          },
          () => {
            this.props.set_profile(res.data.profileInfo);
          }
        )
      )
      .catch((err) => {
        if (err.response && err.response.status === 404)
          this.props.route("/not-found");
        else {
          console.log(err);
          setTimeout(this.load, 1500);
        }
      });
  };

  update = () =>
    this.setState(
      {
        ...this.state,
        updating: true,
      },
      () =>
        axios
          .post("/profile/update-external", {
            profile: this.profile,
            emissions: [
              ...new Set([
                ...this.props.profileInfo.emissions.items.map(
                  (e) => e.emissionID
                ),
                ...this.props.profileInfo.likes.items.map((e) => e.emissionID),
              ]),
            ],
          })
          .then((res) =>
            this.setState(
              {
                ...this.state,
                updating: false,
              },
              () => this.props.set_profile(res.data.profileInfo)
            )
          )
          .catch((err) => {
            console.log("update error", err);
            this.update();
          })
    );

  connectSocket = () =>
    this.setState(
      {
        ...this.state,
        socketConnected: true,
      },
      this.initializeSocket
    );

  setTab = (tab, callback) =>
    this.setState(
      {
        ...this.state,
        tabExit: this.props.profileInfo.loaded
          ? tabs.indexOf(tab) > tabs.indexOf(this.state.tabSelected)
            ? t.fade_out_left
            : t.fade_out_right
          : t.fade_out,
        tabEnter: this.props.profileInfo.loaded
          ? tabs.indexOf(tab) > tabs.indexOf(this.state.tabSelected)
            ? t.fade_out_right
            : t.fade_out_left
          : t.fade_out,
      },
      () =>
        this.setState(
          {
            ...this.state,
            tabSelected: tab,
          },
          () => {
            if (callback) callback();
          }
        )
    );

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    this.props.socket.off("new-emission");
    this.props.socket.off("vote");
    this.props.socket.off("like");
    this.props.socket.off("signalboost");
    this.props.socket.off("update-user");
    this.props.socket.off("remove-emission");
    this.props.socket.off("restore-emission");
    this.props.socket.off("ban");
    this.props.socket.off("restore-user");
    this.props.socket.off("follow");
    this.props.socket.off("private");
    this.props.socket.off("unprivate");
    this.props.socket.off("disable-messages");
    this.props.socket.off("stream-start");
    this.props.socket.off("stream-end");
    this.props.socket.off("reply");
    this.props.socket.off("view");
    this.props.socket.off("views");
    this.props.socket.off("message-count");
    this.props.socket.off("pin");

    this.props.socket.emit("visit", this.profile);

    this.props.socket.on("pin", (emission) => {
      if (emission.userID !== this.props.userInfo._id) {
        if (
          this.props.profileInfo.emissions.items.find(
            (e) => e.emissionID === emission.emissionID
          )
        )
          this.props.update_emission(emission);
        else
          this.props.new_emission(h.setMetadata(emission, this.props.userInfo));
      }
    });
    this.props.socket.on("view", this.props.view);
    this.props.socket.on("views", this.props.views);
    this.props.socket.on("new-emission", (emission) => {
      if (!(emission.userID === this.props.userInfo._id && emission.ignoreSelf))
        this.props.new_emission(h.setMetadata(emission, this.props.userInfo));
    });
    this.props.socket.on("vote", this.props.vote);
    this.props.socket.on("like", (likeData) => {
      if (likeData.userID !== this.props.userInfo._id)
        this.props.like_other({
          ...likeData,
          emission: h.setMetadata(likeData.emission, this.props.userInfo),
        });
    });
    this.props.socket.on("signalboost", (data) => {
      if (data.userID !== this.props.userInfo._id)
        this.props.signal_boost(data.emissionID, data.boostID);
    });
    this.props.socket.on("remove-emission", (emission) => {
      console.log(emission);
      if (emission.remove.user.userID !== this.props.userInfo._id) {
        if (
          h.checkJanny(this.props.userInfo) ||
          this.props.userInfo._id === emission.userID
        )
          this.props.update_emission(emission);
        else this.props.remove_emission(emission);
      }
    });
    this.props.socket.on("restore-emission", (emission) => {
      if (emission.remove.user.userID !== this.props.userInfo._id)
        this.props.update_emission(emission);
    });
    this.props.socket.on("update-user", this.props.user_edit);
    this.props.socket.on("ban", this.props.ban_other);

    /**
     * When banned user has their account restored, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("restore-user", async (userInfo) => {
      const emissions = h.getUserProfileEmissions(
        userInfo,
        this.props.profileInfo
      );
      const getEmissions = () =>
        new Promise((resolve) =>
          axios
            .post("/api/emissions/by-id", {
              emissions: emissions,
            })
            .then((res) => resolve(res.data.emissions))
            .catch((err) => {
              console.log("restored emissions err", err);
              setTimeout(async () => {
                const restoredEmissions = await getEmissions();
                resolve(restoredEmissions);
              }, 1500);
            })
        );
      let restoredEmissions = [];
      if (emissions.length) restoredEmissions = await getEmissions();
      this.props.restore_user(userInfo, restoredEmissions);
    });
    this.props.socket.on("follow", this.props.new_follow);
    this.props.socket.on("private", this.props.private_profile);

    /**
     * When user unprivates their account, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("unprivate", async (userInfo) => {
      const emissions = h.getUserProfileEmissions(
        userInfo,
        this.props.profileInfo
      );
      const getEmissions = () =>
        new Promise((resolve) =>
          axios
            .post("/api/emissions/by-id", {
              emissions: emissions,
            })
            .then((res) => resolve(res.data.emissions))
            .catch((err) => {
              console.log("restored emissions err", err);
              setTimeout(async () => {
                const restoredEmissions = await getEmissions();
                resolve(restoredEmissions);
              }, 1500);
            })
        );
      let restoredEmissions = [];
      if (emissions.length) restoredEmissions = await getEmissions();
      this.props.unprivate(userInfo, restoredEmissions);
    });
    this.props.socket.on("disable-messages", this.props.disable_messages);
    this.props.socket.on("stream-start", this.props.stream_start);
    this.props.socket.on("stream-end", this.props.stream_end);
    this.props.socket.on("reply", this.props.reply);
    this.props.socket.on("block", this.props.block_other);

    /**
     * When user is unblocked by another user, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("unblock", async (userInfo) => {
      const emissions = h.getUserProfileEmissions(
        userInfo,
        this.props.profileInfo
      );
      const getEmissions = () =>
        new Promise((resolve) =>
          axios
            .post("/api/emissions/by-id", {
              emissions: emissions,
            })
            .then((res) => resolve(res.data.emissions))
            .catch((err) => {
              console.log("restored emissions err", err);
              setTimeout(async () => {
                const restoredEmissions = await getEmissions();
                resolve(restoredEmissions);
              }, 1500);
            })
        );
      let restoredEmissions = [];
      if (emissions.length) restoredEmissions = await getEmissions();
      this.props.unblock_other(userInfo, restoredEmissions);
    });
    this.props.socket.on("message-count", this.props.set_unread_messages);
  };

  /**
   *
   * @param {JSX} icon - Notification icon
   * @param {String} text - Notification text
   *
   * Clicks the toastRef to notify the user with the text and icon supplied
   */
  notify = (icon, text) =>
    this.setState(
      {
        ...this.state,
        notificationText: text,
        notificationIcon: icon,
      },
      () => this.toastRef.current.click()
    );

  toggleLoginModal = () =>
    this.setState({
      ...this.state,
      loginModalShown: !this.state.loginModalShown,
    });

  setLoginModal = (option) =>
    this.setState({
      ...this.state,
      loginModalShown: option,
    });

  newEmission = (emission, files) => {
    setTimeout(() => {
      const pinnedFound = this.props.profileInfo.emissions.items.find(
        (emission) => emission.pinned
      );
      document
        .getElementById(pinnedFound ? "scroll-top" : "feed-top")
        .scrollIntoView();
    }, 333);
    this.setState(
      {
        ...this.state,
        reset: !this.state.reset,
      },
      () => {
        switch (this.state.tabSelected) {
          case "emissionsAndReplies":
            this.setTab("emissions", () => this.props.new_emission(emission));
            break;
          case "media":
            if (!files)
              this.props.setTab("emissions", () =>
                this.props.new_emission(emission)
              );
            break;
          case "likes":
            this.props.setTab("emissions", () =>
              this.props.new_emission(emission)
            );
            break;
          default:
            this.props.new_emission(emission);
        }
      }
    );
  };

  render() {
    return (
      <motion.div
        className="profile-container"
        transition={t.fade_out}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out}
      >
        <MDBContainer className="pt-4 h-100">
          <MDBBtn
            id="toast-trigger-profile-info"
            className="d-none"
            color="primary"
            ref={this.toastRef}
          >
            Toast
          </MDBBtn>
          <ToastMisc
            triggerRef={this.toastRef}
            id={"profile-info-toast"}
            icon={this.state.notificationIcon}
            text={this.state.notificationText}
          />
          <LoginModal
            modalShown={this.state.loginModalShown}
            setShowModal={this.setLoginModal}
            toggleShowModal={this.toggleLoginModal}
            tempAction={this.props.tempAction}
            notify={this.notify}
            profile={this.props.profileInfo.user?.username}
            emissions={[
              ...new Set([
                ...this.props.profileInfo.emissions.items.map(
                  (e) => e.emissionID
                ),
                ...this.props.profileInfo.likes.items.map((e) => e.emissionID),
              ]),
            ].sort((a, b) => a.emissionID - b.emissionID)}
          />
          {this.props.profileInfo.loaded ? (
            <motion.div
              transition={t.fade_out}
              initial={t.fade_out}
              animate={t.normalize}
              exit={t.fade_out}
            >
              {this.props.profileInfo.user._id === this.props.userInfo._id ? (
                <ProfileInfoSelf updating={this.state.updating} />
              ) : (
                <ProfileInfoOther
                  tabSelected={this.state.tabSelected}
                  notify={this.notify}
                  toggleLoginModal={this.toggleLoginModal}
                  updating={this.state.updating}
                />
              )}
              {!this.props.userInfo.ban.banned &&
                this.props.userInfo.username?.toLowerCase() ===
                  this.profile && (
                  <motion.div
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out}
                    className="mx-auto mt-4"
                  >
                    <MDBCard>
                      <MDBCardBody>
                        <h5 className="display-6 fs-4 m-0">
                          New{" "}
                          <span className="text-capitalize">
                            {process.env.REACT_APP_EMISSION_NAME}
                          </span>
                        </h5>
                        <hr></hr>
                        <TextInput
                          tabSelected={this.state.tabSelected}
                          selectTab={this.setTab}
                          flavor="main"
                          maxChars={Number(
                            process.env.REACT_APP_MAX_EMISSION_CHARS
                          )}
                          newEmission={this.newEmission}
                          key={this.state.reset}
                          label="Enter Text"
                        />
                      </MDBCardBody>
                    </MDBCard>
                  </motion.div>
                )}
              {(!(
                this.props.profileInfo.user?.ban?.banned ||
                this.props.profileInfo.blocksMe ||
                this.props.profileInfo.isBlocked ||
                this.props.profileInfo.user.private ||
                this.props.profileInfo.user.deleted
              ) ||
                this.props.userInfo.username?.toLowerCase() === this.profile ||
                h.checkJanny(this.props.userInfo)) && (
                <ProfileFeed
                  tabSelected={this.state.tabSelected}
                  selectTab={this.setTab}
                  notify={this.notify}
                  toggleLoginModal={this.toggleLoginModal}
                  tabExit={this.state.tabExit}
                  tabEnter={this.state.tabEnter}
                  updating={this.state.updating}
                />
              )}
            </motion.div>
          ) : (
            <>
              <h5 className="my-4 text-center display-6">Loading Profile</h5>
              <div className="d-flex justify-content-center px-5">
                <LinearProgress className="w-100" />
              </div>
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  new_emission,
  vote,
  signal_boost,
  set_user,
  remove_emission,
  restore_emission,
  update_emission,
  like_other,
  set_profile,
  ban_other,
  new_follow,
  private_profile,
  disable_messages,
  stream_start,
  stream_end,
  reply,
  user_edit,
  restore_user,
  unprivate,
  block_other,
  unblock_other,
  view,
  views,
  add_message_profile,
  set_unread_messages,
  clear_profile,
})(withRouter(Profile));
