import React from 'react';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBContainer,
    MDBBtn
} from 'mdb-react-ui-kit';
import { set_verification_details } from '../redux/actions';
import Spinner from '../components/Spinner';
import axios from 'axios';

class ValidateEmail extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether another verification email is being requested
             * validationSent: Boolean - Whether a verification email has been sent
             * reset: Boolean - Measures nothing, but flipping this will cause the countdown to re-render
             * resetInterval: false | Interval that toggles state every second
             */
            working: false,
            validationSent: false,
            reset: false,
            resetInterval: false
        }
    }

    // Set resetInterval
    componentDidMount(){
        this.setState({
            ...this.state,
            resetInterval: setInterval(this.reset, 1000)
        });
    }

    /**
     * Clear reset interval
     * Clear verification details
     */
    componentWillUnmount(){
        clearInterval(this.state.resetInterval);
        this.props.set_verification_details(false);
    }

    reset = () => this.setState({
        ...this.state,
        reset: !this.state.reset
    });

    /**
     * Triggered when the user clicks the Resend button
     * Sends another verification email
     * Updates application state with new verification details
     */
    resend = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => axios.post('/auth/resend-verification', {
            email: this.props.verificationDetails.email
        }).then(res => this.setState({
            ...this.state,
            validationSent: true,
            working: false
        }, () => this.props.set_verification_details(res.data))).catch(err => {
            console.log(err);
            this.setState({
                ...this.state,
                working: false
            }, () => alert('An error occurred. Please try again later.'));
        }));
    }

    render(){
        return (
            <motion.div className="my-4" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <MDBContainer>
                    <h5 className="text-center display-6">An email has been sent from {process.env.REACT_APP_VERIFICATION_EMAIL} to verify your email address. Please click the link in this email to verify your account.</h5>
                    {this.props.verificationDetails ?
                    <>
                        {new Date(new Date().setMinutes(new Date().getMinutes() - 1)) > new Date(this.props.verificationDetails.timestamp) ?
                        <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} className="mt-4">
                            <h5 className="text-center">Didn't get it?</h5>
                            {this.state.working  ?
                            <MDBBtn disabled className="d-block mx-auto" color="primary">
                                <Spinner size="sm" className="me-2" />
                                Resending
                            </MDBBtn> :
                            <MDBBtn onClick={this.resend} className="d-block mx-auto" color="primary">
                                <i className="fas fa-paper-plane me-2"></i>
                                Resend Email
                            </MDBBtn>}
                        </motion.div> : 
                        <>
                            {this.state.validationSent ?
                            <motion.h5 transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} className="text-center mt-4">Validation resent</motion.h5> : <></>}
                        </>}
                    </> : <></>}
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { set_verification_details })(ValidateEmail);