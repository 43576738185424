import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import { MDBBtn } from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import SampleProfile from "../../components/SampleProfile";
import { v4 as uuid } from "uuid";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.socket.onAny((event, data) => {
      if (
        [
          "new-emission",
          "vote",
          "like",
          "signalboost",
          "update-user",
          "remove-emission",
          "restore-emission",
          "ban",
          "restore-user",
          "private",
          "unprivate",
          "reply",
          "block",
          "unblock",
          "view",
          "views",
        ].indexOf(event) > -1
      ) {
        const socketEvent = {
          event: event,
          data: data,
          id: uuid(),
        };
        switch (event) {
          case "update-user":
            this.props.userEdit(data, socketEvent);
            break;
          case "ban":
            this.props.ban(data, socketEvent);
            break;
          case "restore-user":
            this.props.restoreUser(data, socketEvent);
            break;
          case "unblock":
            this.props.unblockOther(data, socketEvent);
            break;
          case "unprivate":
            this.props.unprivate(data, socketEvent);
            break;
          case "block":
            this.props.blockOther(data, socketEvent);
            break;
          case "private":
            this.props.privateProfile(data, socketEvent);
            break;
          default:
            this.props.pushSocketEvent(socketEvent);
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.socket.onAny(() => {});
  }

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_right}
        animate={t.normalize}
        initial={t.fade_out_right}
        className="py-4 h-100"
      >
        {this.props.users.length ? (
          <>
            {this.props.users.map((user, u) => (
              <SampleProfile profile={user} index={u} />
            ))}
            {this.props.userEndReached ? (
              <></>
            ) : (
              <>
                {this.props.loadingMore.indexOf("users") > -1 ? (
                  <MDBBtn
                    size="lg"
                    disabled
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    <Spinner color="primary" size="sm" className="me-2" />
                    Please Wait
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    size="lg"
                    onClick={() => this.props.loadMore("users")}
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    See More
                  </MDBBtn>
                )}
              </>
            )}
          </>
        ) : (
          <h5 className="text-center display-6 mt-4">No users found</h5>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(Users);
