import React from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { link_schema } from "../../utilities/validations";

class LinkModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * inputs: Array - The input data (values, errors, etc)
       */
      inputs: [
        {
          id: "link",
          error: "",
          invalid: true,
          value: "",
          text: "Link",
        },
        {
          id: "text",
          error: "",
          invalid: true,
          value: "",
          text: "Text",
        },
      ],
    };
  }

  /**
   * Run blank change handler
   */
  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  /**
   * If inputs are valid, run insertLink method from parent
   * Reset inputs
   */
  submit = () => {
    document.getElementById("link_form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    const data = Object.fromEntries(
      this.state.inputs.map((input) => [input.id, input.value])
    );
    try {
      link_schema.validateSync(data, {
        abortEarly: false,
      });
      this.props.toggleShowModal();
      this.props.insertLink(
        data.link.startsWith("http://") || data.link.startsWith("https://")
          ? data.link
          : `//${data.link}`,
        data.text
      );
      document.getElementById("link_form").classList.remove("was-validated");
      this.setState(
        {
          ...this.state,
          inputs: [
            {
              id: "link",
              error: "",
              invalid: true,
              value: "",
              text: "Link",
            },
            {
              id: "text",
              error: "",
              invalid: true,
              value: "",
              text: "Text",
            },
          ],
        },
        () =>
          this.changeHandler({
            target: {
              name: "",
            },
          })
      );
    } catch (err) {
      console.log(err);
      alert("An error occurred. Please try again later");
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          link_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Insert Link</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBValidation
                    name="link_form"
                    method="dialog"
                    id="link_form"
                    onSubmit={this.submit}
                  >
                    {this.state.inputs.map((input) => (
                      <MDBValidationItem
                        className="pb-4"
                        feedback={input.error}
                        invalid={true}
                      >
                        <MDBInput
                          name={input.id}
                          onChange={this.changeHandler}
                          id={input.id}
                          label={input.text}
                          size="lg"
                          value={input.value}
                          className={!input.invalid ? "mb-0" : 0}
                          onKeyPress={this.pressEnter}
                          onKeyDown={this.pressTab}
                        />
                      </MDBValidationItem>
                    ))}
                  </MDBValidation>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="primary" onClick={this.submit}>
                    <i className="fas fa-link me-2"></i>Insert
                  </MDBBtn>
                  <MDBBtn
                    className="bg-gray"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default LinkModal;
