import React from "react";
import { motion } from "framer-motion";
import t from "../../../utilities/transitions";
import {
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Spinner from "../../../components/Spinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import h from "../../../utilities/helpers";
import axios from "axios";

// Reasons start
const reasons = [
  {
    id: "spam",
    label: "Spam",
  },
  {
    id: "porn",
    label: "Porn",
  },
  {
    id: "fed",
    label: "Illegal/Fedposting",
  },
  {
    id: "other",
    label: "Other",
  },
];
// Reasons end

class Remove extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the emission is in the process of being removed
       * reason: String - The reason for removal
       * input: Object - The text input data (values, errors, etc)
       */
      working: false,
      reason: reasons[0].id,
      input: {
        id: "remove-input",
        error: "",
        invalid: false,
        value: "",
      },
    };
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        input: {
          ...this.state.input,
          value: e.target.value,
        },
      },
      () => {
        if (this.state.input.value.length > 5000)
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: true,
                error: "Too long (Max: 5000 chars)",
              },
            },
            () =>
              document
                .getElementById("remove-input")
                .setCustomValidity(this.state.input.error)
          );
        else
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: false,
                error: "",
              },
            },
            () => document.getElementById("remove-input").setCustomValidity("")
          );
      }
    );
  };

  actions = () => {
    if (!this.state.working) this.props.setView("reports");
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Notify user that the emission has been removed
   */
  submit = () => {
    document.getElementById("remove-form").classList.add("was-validated");
    if (this.state.input.value.length > 5000)
      document
        .getElementById("remove-input")
        .setCustomValidity(this.state.input.error);
    if (!this.state.working && this.state.input.value.length <= 5000)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          try {
            const submission = {
              details: this.state.input.value,
              reason: this.state.reason,
              emissionID: this.props.report.emissionID,
            };
            axios
              .post("/support/emissions/remove", submission)
              .then(() =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    reason: reasons[0].id,
                    input: {
                      id: "remove-input",
                      error: "",
                      invalid: false,
                      value: "",
                    },
                  },
                  () =>
                    this.props.purgeReports({
                      type: "emission",
                      text: (
                        <>
                          <span className="text-capitalize">
                            {process.env.REACT_APP_EMISSION_NAME}
                          </span>{" "}
                          <span className="text-pkmn">
                            #{this.props.report.emissionID}
                          </span>{" "}
                          Removed
                        </>
                      ),
                      icon: (
                        <i className="far fa-trash-alt me-2 text-danger"></i>
                      ),
                      emissionID: this.props.report.emissionID,
                    })
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  reasonChange = (e) =>
    this.setState({
      ...this.state,
      reason: e.target.value,
    });

  render() {
    return (
      <motion.div
        className="mt-4"
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <div className="d-flex">
          <MDBBtn
            className="me-2"
            onClick={this.actions}
            color="link"
            rippleColor="primary"
          >
            <i className="fas fa-chevron-left me-2"></i>
            Actions
          </MDBBtn>
          {this.state.working ? (
            <MDBBtn disabled color="dark">
              <Spinner className="me-2" size="sm" />
              Removing
            </MDBBtn>
          ) : (
            <MDBBtn onClick={this.submit} color="dark">
              <i className="far fa-check-circle me-2"></i>
              Confirm Remove
            </MDBBtn>
          )}
        </div>
        <hr></hr>
        <div className="d-flex mb-4 remove-emission-reasons">
          <div className="px-4">
            <FormControl>
              <h5 className="fs-5 display-6 m-0">Reason</h5>
              <hr className="my-1" />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={this.state.reason}
                name="radio-buttons-group"
                onChange={this.reasonChange}
              >
                {reasons.map((reason) => (
                  <FormControlLabel
                    key={reason.id}
                    value={reason.id}
                    control={<Radio />}
                    label={reason.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex-grow-1">
            <MDBValidation
              className="h-100"
              method="dialog"
              id="remove-form"
              name="remove-form"
            >
              <MDBValidationItem
                className="pb-4 h-100 h-100-child"
                feedback={this.state.input.error}
                invalid={true}
              >
                <MDBTextArea
                  name="remove-input"
                  onChange={this.changeHandler}
                  id="remove-input"
                  label="Details"
                  size="lg"
                  className={`h-100 ${!this.state.input.invalid ? "mb-0" : 0}`}
                />
              </MDBValidationItem>
            </MDBValidation>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default Remove;
