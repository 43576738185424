import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import SampleEmission from "../emission/SampleEmission";
import axios from "axios";

class RestoreModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the emission if in the processed of being restored
       */
      working: false,
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Update emission
   * Hide modal
   */
  submit = () => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () =>
          axios
            .get(`/support/emissions/restore/${this.props.emission.emissionID}`)
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  this.props.updateEmission(res.data.emission, true);
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              {this.props.emission ? (
                <MDBModalContent key={this.props.emission.emissionID}>
                  <MDBModalHeader>
                    <MDBModalTitle>Are you sure?</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <h5 className="my-4 display-6 text-center">
                      You are about to restore{" "}
                      <span className="text-lowercase">
                        {process.env.REACT_APP_EMISSION_NAME}
                      </span>{" "}
                      <span className="text-pkmn">
                        #{this.props.emission.emissionID}
                      </span>
                    </h5>
                    {this.props.emission.remove.reason ? (
                      <div className="bg-emphasis mb-4 p-2 text-break">
                        <h5>
                          Removal reason: {this.props.emission.remove.reason}
                        </h5>
                        <p>{this.props.emission.remove.details}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <SampleEmission
                      emission={this.props.emission}
                      toggleModal={this.props.toggleShowModal}
                    />
                  </MDBModalBody>
                  <MDBModalFooter>
                    {this.state.working ? (
                      <MDBBtn disabled color="primary">
                        <Spinner className="me-2" size="sm" />
                        Restoring
                      </MDBBtn>
                    ) : (
                      <MDBBtn onClick={this.submit} color="primary">
                        <i className="far fa-check-circle me-2"></i>Confirm
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default RestoreModal;
