import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import {
  MDBContainer,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBAlert,
} from "mdb-react-ui-kit";
import { route } from "../redux/actions";
import SideNav from "./feed/SideNav";
import { StaticRouter, Switch, Route, Link } from "react-router-dom";
import FeedComponent from "./feed/FeedComponent";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";

class Feed extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * tab: String - The feed tab that the user is currently on - "following" | "popular" | "recent" | "live"
       * loaded: Boolean - Whether the initial data has been loaded
       * emissions: Array - List of all of the emissions that will go into any of the tabs
       * users: Array - List of all of the users that will go into the Live tab
       * loadingMore: Array - List of tabs that are in the process of loading more (older) emissions
       * checkingNewer: Array - List of tabs that are in the process of loading more (newer) emissions
       * followEnd: Boolean - Whether the user has reached the end of the list of emissions made by accounts that that user is following
       * totalEnd: Boolean - Whether the user has reached the end of the list of recent emissions
       * popularEnd: Boolean - Whether the user has reached the end of the list of popular emissions
       * notificationIcon: JSX - The notification icon
       * notificationText: String - The notification text
       * polls: Array - List of polls that the user is voting in
       * pollsSubmitting: Array - List of polls that are in the process of having their votes submitted
       * loginModalShown: Boolean - Whether the login modal is shown
       * fileModalShown: Boolean - Whether the file modal is shown
       * signalBoostModalShown: Boolean - Whether the signal boost modal is shown
       * replyModalShown: Boolean - Whether the reply modal is shown
       * removeModalShown: Boolean - Whether the remove modal is shown
       * reportModalShown: Boolean - Whether the report modal is shown
       * restoreModalShown: Boolean - Whether the restore modal is shown
       * fileSelected: false | Object - The file currently selected
       * fileList: Array - List of files on the emission that the fileSelected belongs to
       * emissionSelected: false | Object - The emission currently selected
       * emissionSignalBoosting: false | Object - The emission currently signal boosting
       * emissionReplying: false | Object - The emission currently replying
       * emissionCopied: false | Number - EmissionID of Emission copied
       * emissionRemoving: false | Object - The emission currently removing
       * emissionReporting: false | Object - The emission currently reporting
       * emissionRestoring: false | Object - The emission currently restoring
       * socketConnected: Boolean - Whether the socket is currently connected
       * tempAction: false | Object - Temporary action data that must be executed upon logging in
       * trending: false | Object - Trending emissions data
       */
      tab: props.userInfo._id ? "following" : "popular",
      loaded: false,
      emissions: [],
      users: [],
      loadingMore: [],
      checkingNewer: [],
      followEnd: false,
      totalEnd: false,
      popularEnd: false,
      notificationIcon: <></>,
      notificationText: "",
      polls: [],
      pollsSubmitting: [],
      loginModalShown: false,
      fileModalShown: false,
      signalBoostModalShown: false,
      replyModalShown: false,
      removeModalShown: false,
      reportModalShown: false,
      restoreModalShown: false,
      fileSelected: false,
      fileList: [],
      emissionSelected: false,
      emissionSignalBoosting: false,
      emissionReplying: false,
      emissionCopied: false,
      emissionRemoving: false,
      emissionReporting: false,
      emissionRestoring: false,
      socketConnected: false,
      reconnectInterval: false,
      tempAction: false,
      trending: false,
      checkingStreams: false,
      socketEvents: [],
    };
  }

  /**
   * Load emission data
   * Connect socket
   *
   */
  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.props.socket.off("new-emission");
    this.props.socket.off("vote");
    this.props.socket.off("like");
    this.props.socket.off("signalboost");
    this.props.socket.off("update-user");
    this.props.socket.off("remove-emission");
    this.props.socket.off("restore-emission");
    this.props.socket.off("ban");
    this.props.socket.off("restore-user");
    this.props.socket.off("private");
    this.props.socket.off("reply");
    this.props.socket.off("view");
    this.props.socket.off("views");
    this.props.socket.off("unprivate");
    this.props.socket.off("block");
    this.props.socket.off("unblock");
  }

  /**
   * Loads initial feed data, and applies the data into state
   * If there is a failure, try again after 1 second
   *
   */
  load = () =>
    axios
      .get("/api/emissions/feed/init")
      .then((res) => {
        let tab = this.state.tab;
        if (
          tab === "following" &&
          !res.data.emissions.find((emission) => emission.following)
        )
          tab = "popular";
        if (
          tab === "popular" &&
          !res.data.emissions.find((emission) => emission.popular)
        )
          tab = "recent";
        this.setState({
          ...this.state,
          loaded: true,
          emissions: res.data.emissions,
          followEnd: res.data.followEnd,
          totalEnd: res.data.totalEnd,
          popularEnd: res.data.popularEnd,
          trending: res.data.trending,
          users: res.data.profiles,
          liveEnd: res.data.liveEnd,
          tab: tab,
        });
      })
      .catch((err) => {
        console.log("load error", err);
        setTimeout(this.load, 1000);
      });

  selectTab = (e, tab) =>
    this.setState({
      ...this.state,
      tab: tab,
    });

  /**
   *
   * @param {String} type - The tab that the user wishes to load more data
   *
   * Triggered when the user clicks the View More button at the end of the list of emissions or users on any tab
   */
  loadMore = (type, emissionList, callback) => {
    this.setState(
      {
        ...this.state,
        loadingMore: [...this.state.loadingMore, type],
      },
      () => {
        switch (type) {
          case "following":
            axios
              .post("/api/emissions/feed/more/following", {
                firstEmission: emissionList.sort(
                  (a, b) => a.emissionID - b.emissionID
                )[0].emissionID,
              })
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: [...emissionList, ...res.data.emissions],
                    followEnd: res.data.emissions.length < 40,
                  },
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                )
              )
              .catch((err) => {
                console.log("load more follow error", err);
                setTimeout(() => this.loadMore(type), 1000);
              });
            break;
          case "popular":
            axios
              .post("/api/emissions/feed/more/popular", {
                emissions: emissionList
                  .filter((e) => e.popular)
                  .map((e) => e.emissionID),
              })
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: [
                      ...emissionList.filter(
                        (e) =>
                          !res.data.emissions.find(
                            (emission) => emission.emissionID === e.emissionID
                          )
                      ),
                      ...res.data.emissions,
                    ],
                    popularEnd: res.data.emissions.length < 80,
                  },
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                )
              )
              .catch((err) => {
                console.log("load more follow error", err);
                setTimeout(() => this.loadMore(type), 1000);
              });
            break;
          case "recent":
            axios
              .post("/api/emissions/feed/more/recent", {
                emissions: emissionList.map((e) => e.emissionID),
              })
              .then((res) => {
                const newEmissionList = [
                  ...emissionList,
                  ...res.data.emissions,
                ];
                const totalEnd =
                  newEmissionList.find(
                    (emission) => emission.emissionID === 1
                  ) &&
                  newEmissionList.length ===
                    newEmissionList.sort(
                      (a, b) => b.emissionID - a.emissionID
                    )[0].emissionID;
                this.setState(
                  {
                    ...this.state,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: newEmissionList,
                    totalEnd: totalEnd,
                  },
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                );
              })
              .catch((err) => {
                console.log("load more follow error", err);
                setTimeout(() => this.loadMore(type), 1000);
              });
            break;
          case "live":
            axios
              .post("/api/emissions/feed/more/live", {
                users: this.state.users.map((profile) => profile._id),
              })
              .then((res) =>
                this.setState({
                  ...this.state,
                  loadingMore: this.state.loadingMore.filter((m) => m !== type),
                  users: [...this.state.users, ...res.data.profiles],
                  liveEnd: res.data.profiles.length < 40,
                })
              )
              .catch((err) => {
                console.log("load more profiles error", err);
                setTimeout(() => this.loadMore(type), 1000);
              });
          default:
            console.log("oob load more", type);
        }
      }
    );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks the Check for Newer button in any of the tavs
   * Loads newer users/emissions from that tab
   */
  checkNewer = (type, emissions, callback) => {
    this.setState(
      {
        ...this.state,
        checkingNewer: [...this.state.checkingNewer, type],
      },
      () =>
        axios
          .post("/api/emissions/feed/newer/" + type, {
            lastEmission: emissions.sort(
              (a, b) => b.emissionID - a.emissionID
            )[0].emissionID,
          })
          .then((res) =>
            this.setState(
              {
                ...this.state,
                checkingNewer: this.state.checkingNewer.filter(
                  (c) => c !== type
                ),
                emissions: [...res.data.emissions, ...emissions],
              },
              () => {
                callback({
                  emissions: res.data.emissions,
                });
              }
            )
          )
          .catch((err) => {
            console.log("load more error", err);
            setTimeout(() => this.checkNewer(type), 1000);
          })
    );
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} tag - A hashtag
   *
   * Triggered when the user clicks on a hashtag
   * Routes to that tag page
   */
  clickTag = (e, tag) => {
    e.preventDefault();
    this.props.route(`/tag/${tag}`);
  };

  checkStreams = () => {
    if (!this.state.checkingStreams)
      this.setState(
        {
          ...this.state,
          checkingStreams: true,
        },
        () =>
          axios
            .get("/users/live")
            .then((res) =>
              this.setState({
                ...this.state,
                users: res.data.profiles,
                liveEnd: res.data.liveEnd,
                checkingStreams: false,
              })
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  checkingStreams: false,
                },
                () => {
                  console.log("Check streams error", err);
                  alert(
                    "An error occurred. Please check your connection and try again."
                  );
                }
              )
            )
      );
  };

  userEdit = (userInfo, socketEvent) => {
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...user,
            ...userInfo,
          };

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });
  };

  ban = (userInfo, socketEvent) =>
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });

  restoreUser = (userInfo, socketEvent) =>
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });

  unblockOther = (userInfo, socketEvent) =>
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });

  unprivate = (userInfo, socketEvent) =>
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });

  blockOther = (userInfo, socketEvent) =>
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id) user.blocked = true;

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });

  privateProfile = (userInfo, socketEvent) =>
    this.setState({
      ...this.state,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketEvents: socketEvent
        ? [...this.state.socketEvents, socketEvent]
        : this.state.socketEvents,
    });

  pushSocketEvent = (event) =>
    this.setState({
      ...this.state,
      socketEvents: [...this.state.socketEvents, event],
    });

  updateEmissions = (emissions) =>
    this.setState({
      ...this.state,
      emissions: emissions,
    });

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 d-flex"
      >
        {this.state.loaded ? (
          <>
            {this.state.emissions.length ? (
              <motion.div
                className="w-100 d-flex feed-container"
                transition={t.transition}
                exit={t.fade_out}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <div className="feed-emissions">
                  <MDBContainer className="feed-emission-container">
                    {process.env.REACT_APP_MAIN_INSTANCE === "true" ? (
                      <a href="https://carbonvalley.win/products">
                        <MDBAlert
                          show
                          className="w-100 text-center"
                          color="success"
                        >
                          Want to launch your own Pigger instance? Head over to
                          https://carbonvalley.win/products
                        </MDBAlert>
                      </a>
                    ) : (
                      <></>
                    )}
                    <StaticRouter location={this.state.tab}>
                      <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.tab}>
                          <Route exact path=":tab">
                            <FeedComponent
                              tab={this.state.tab}
                              selectTab={this.selectTab}
                              loaded={this.state.loaded}
                              clickEmissionBody={this.clickEmissionBody}
                              polls={this.state.polls}
                              pollsSubmitting={this.state.pollsSubmitting}
                              copyEmissionLink={this.copyEmissionLink}
                              emissionCopied={this.state.emissionCopied}
                              userInfo={this.props.userInfo}
                              route={this.props.route}
                              updateEmission={this.updateEmission}
                              loadingMore={this.state.loadingMore}
                              totalEnd={this.state.totalEnd}
                              followEnd={this.state.followEnd}
                              loadMore={this.loadMore}
                              emissions={this.state.emissions}
                              checkingNewer={this.state.checkingNewer}
                              checkNewer={this.checkNewer}
                              popularEnd={this.state.popularEnd}
                              profiles={this.state.users}
                              users={this.state.users}
                              liveEnd={this.state.liveEnd}
                              checkingStreams={this.state.checkingStreams}
                              checkStreams={this.checkStreams}
                              updateEmissions={this.updateEmissions}
                              userEdit={this.userEdit}
                              ban={this.ban}
                              restoreUser={this.restoreUser}
                              privateProfile={this.privateProfile}
                              unprivate={this.unprivate}
                              blockOther={this.blockOther}
                              unblockOther={this.unblockOther}
                              pushSocketEvent={this.pushSocketEvent}
                              socketEvents={this.state.socketEvents}
                            />
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </StaticRouter>
                  </MDBContainer>
                </div>
                <div className="feed-nav-stats mb-2">
                  <SideNav tab={this.state.tab} selectTab={this.selectTab} />
                  <hr></hr>
                  {this.state.trending &&
                  this.state.trending.tags &&
                  this.state.trending.tags.length ? (
                    <motion.div
                      className="feed-nav-trending"
                      transition={t.transition}
                      exit={t.fade_out}
                      animate={t.normalize}
                      initial={t.fade_out}
                    >
                      <MDBCard>
                        <MDBCardHeader>
                          <h5 className="m-0">
                            <i className="fas fa-chart-line text-success me-2"></i>
                            Trending
                          </h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                          <MDBListGroup light>
                            {this.state.trending.tags
                              .sort((a, b) => b.count - a.count)
                              .map((tag) => (
                                <MDBListGroupItem
                                  key={tag.tag}
                                  className="d-flex justify-content-between"
                                >
                                  <div className="max-w-90">
                                    <Link
                                      onClick={(e) => this.clickTag(e, tag.tag)}
                                      className="text-darkblu m-0 text-break"
                                      to={`/tag/${tag.tag}`}
                                    >
                                      <h6>#{h.veryShortString(tag.tag)}</h6>
                                    </Link>
                                  </div>
                                  <p className="m-0">
                                    {h.numberWithCommas(tag.count)}
                                  </p>
                                </MDBListGroupItem>
                              ))}
                          </MDBListGroup>
                        </MDBCardBody>
                      </MDBCard>
                    </motion.div>
                  ) : (
                    <></>
                  )}
                </div>
              </motion.div>
            ) : (
              <MDBContainer>
                <motion.h5
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                  className="text-center mt-4 display-6"
                >
                  Welcome to {process.env.REACT_APP_NAME}!
                </motion.h5>
              </MDBContainer>
            )}
          </>
        ) : (
          <MDBContainer>
            <h5 className="text-center display-6 mb-4 mt-5">Loading Feed</h5>
            <LinearProgress />
          </MDBContainer>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route })(Feed);
