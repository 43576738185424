import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

class FeedNav extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Tabs
        className="profile-tabs"
        value={this.props.tabSelected}
        onChange={(e, tab) => this.props.selectTab(tab)}
      >
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i
              style={{ color: "var(--mdb-danger)" }}
              className="fas fa-wifi"
            ></i>
          }
          iconPosition="start"
          label={process.env.REACT_APP_EMISSION_PLURAL}
          value="emissions"
          id="tab-emissions"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i style={{ color: "#FFAB00" }} className="fas fa-comments"></i>
          }
          iconPosition="start"
          label="Replies"
          value="emissionsAndReplies"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i
              style={{ color: "var(--mdb-success)" }}
              className="fas fa-images"
            ></i>
          }
          iconPosition="start"
          label="Media"
          value="media"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i
              style={{ color: "rgb(252, 69, 223)" }}
              className="fas fa-heart"
            ></i>
          }
          iconPosition="start"
          label="Likes"
          value="likes"
        />
      </Tabs>
    );
  }
}

export default FeedNav;
