import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import TextInput from "../../../../components/textInput/TextInput";
import { motion } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import {
  add_message_profile,
  set_unread_messages,
} from "../../../../redux/actions";
import { v4 as uuid } from "uuid";

const maxChars = 5000;

class MessageModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a private chat is in the process of being sent
       * scrollToBottom: Boolean - Whether an incoming chat message will cause the chatbox to scroll to the bottom
       * cooldown: Number - Seconds left in cooldown before the user can send another message
       * cooldownInterval: false | Interval that decrements cooldown by 1 every second
       * socketConnected: Boolean - Whether the chat socket has connected
       */
      reset: false,
      scrollToBottom: true,
      cooldown: 0,
      cooldownInterval: false,
      socketConnected: false,
    };
  }

  /**
   * Connect chat socket if main socket is connected
   */
  componentDidMount() {
    if (this.props.socket && !this.state.socketConnected) this.connectSocket();
  }

  /**
   * If new message is received, scroll to bottom. If modal shown, mark messages as read
   * If modal is shown, scroll to bottom and mark messages as read
   * If diconnected socket becomes reconnected, re-initialize
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.profileInfo.messages &&
      prevProps.profileInfo.messages &&
      prevProps.profileInfo.messages.length !==
        this.props.profileInfo.messages.length
    ) {
      this.scrollToBottom();
      if (this.props.modalShown)
        this.props.socket.emit(
          "read-messages",
          this.props.profileInfo.user._id
        );
    }
    if (prevProps.modalShown !== this.props.modalShown) this.scrollToBottom();
    if (!prevProps.modalShown && this.props.modalShown)
      this.props.socket.emit("read-messages", this.props.profileInfo.user._id);
    if (prevProps.socketReconnect !== this.props.socketReconnect)
      this.initializeSocket();
  }

  connectSocket = () =>
    this.setState(
      {
        ...this.state,
        socketConnected: true,
      },
      this.initializeSocket
    );

  /**
   * Turn off and then turn back on all socket events
   */
  initializeSocket = () => {
    this.props.socket.off("new-message");

    this.props.socket.on("new-message", (messageObj) => {
      console.log(messageObj, this.props.profileInfo.messages);
      if (
        !this.props.profileInfo.messages.find(
          (message) => message.id === messageObj.id
        )
      )
        this.props.add_message_profile({
          timestamp: new Date(),
          author: messageObj.author,
          message: messageObj.message,
          read: this.props.modalShown,
          id: messageObj.id,
        });
      if (
        this.props.profileInfo.user &&
        messageObj.author === this.props.profileInfo.user._id &&
        !this.props.modalShown
      ) {
        this.props.set_unread_messages(this.props.userInfo.unreadMessages + 1);
      } else
        this.props.set_unread_messages(this.props.userInfo.unreadMessages + 1);
    });
  };

  /**
   * Submit only if a message isn't already being sent
   * Set working
   * Validate inputs
   * Emit message
   * Add message via redux
   * Put user on 3 second cooldown
   */
  submit = () => {
    try {
      if (!this.state.cooldown) {
        this.forceParse();
        const emissionData = document.getElementById("input-message");
        const length = String(emissionData.textContent)
          .split("")
          .filter((c) => {
            const checkWhiteSpace = c.match(/[\s]/);
            if (!checkWhiteSpace) return true;
            else {
              return [" ", "\n"].indexOf(c) > -1;
            }
          }).length;
        if (length > maxChars)
          throw `Character limit exceeded (Max: ${maxChars} characters)`;
        if (!length || emissionData.innerHTML === "<div><p><br /></p></div>")
          throw "Please enter a message";
        const messageID = uuid();
        this.props.socket.emit("message", {
          to: {
            userID: this.props.profileInfo.user._id,
            username: this.props.profileInfo.user.username,
          },
          message: emissionData.innerHTML,
          id: messageID,
        });
        this.props.add_message_profile({
          timestamp: new Date(),
          author: this.props.userInfo._id,
          message: h.sanitizeHTML(emissionData.innerHTML),
          id: messageID,
        });
        this.setState(
          {
            ...this.state,
            text: "",
            cooldown: 3,
            reset: !this.state.reset,
          },
          () => {
            setTimeout(this.decrementCooldown, 1000);
          }
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * If state.scrollToBottom, scroll to bottom
   * scrollBehavior is normal for first scroll, set to smooth for subsequent scrolls
   * Execute after 0.1s
   */
  scrollToBottom = () =>
    setTimeout(() => {
      if (this.state.scrollToBottom) {
        const container = document.getElementById("message-container");
        container.scrollTop = container.scrollHeight;
        container.style.scrollBehavior = "smooth";
      }
    }, 100);

  /**
   * Fired when the user scrolls through the chatbox
   * If the scrollbar is not at the bottom, set state.scrollToBottom to false so that it won't scroll to the bottom when new messages come in
   */
  scrollHandler = (e) => {
    if (
      e.currentTarget.scrollHeight ===
      e.currentTarget.scrollTop + e.currentTarget.clientHeight
    ) {
      if (!this.state.scrollToBottom)
        this.setState({
          ...this.state,
          scrollToBottom: true,
        });
    } else if (this.state.scrollToBottom)
      this.setState({
        ...this.state,
        scrollToBottom: false,
      });
  };

  /**
   * Triggered by the cooldownInterval
   * Decrements the cooldown by 1
   */
  decrementCooldown = () =>
    this.setState(
      {
        ...this.state,
        cooldown: this.state.cooldown > 0 ? this.state.cooldown - 1 : 0,
      },
      () => {
        if (this.state.cooldown > 0) setTimeout(this.decrementCooldown, 1000);
      }
    );

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    Message{" "}
                    <span className="text-darkblu">
                      @{this.props.profileInfo.user.username}
                    </span>
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody
                  className="d-flex flex-column"
                  style={{ height: "75vh" }}
                >
                  <div className="fg-1 border border-gray d-flex flex-column justify-content-end">
                    <div
                      onTouchMove={this.scrollHandler}
                      onWheel={this.scrollHandler}
                      style={{ scrollBehavior: "auto" }}
                      id="message-container"
                      className="h-100 overflow-y-auto"
                    >
                      {this.props.profileInfo.messages &&
                      this.props.profileInfo.messages.length ? (
                        <ul className="list-group list-group-flush">
                          {this.props.profileInfo.messages
                            .sort(
                              (a, b) =>
                                new Date(a.timestamp) - new Date(b.timestamp)
                            )
                            .map((message) => {
                              if (message.author === this.props.userInfo._id)
                                return (
                                  <motion.li
                                    transition={t.transition}
                                    exit={t.fade_out_minimize}
                                    animate={t.normalize}
                                    initial={t.fade_out_minimize}
                                    key={String(message.timestamp)}
                                    className="list-group-item"
                                  >
                                    <div className="d-flex justify-content-end">
                                      <div>
                                        <p className="m-0 text-blusteel text-end">
                                          {h.makeDateHR(
                                            new Date(message.timestamp)
                                          )}{" "}
                                          @{" "}
                                          {h.getTimeHR(
                                            new Date(message.timestamp)
                                          )}
                                        </p>
                                        <div
                                          className="d-flex justify-content-end"
                                          dangerouslySetInnerHTML={{
                                            __html: message.message,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="d-flex justify-content-center align-items-center square-3 ms-2">
                                        <div
                                          className="fit-images fit-round"
                                          style={{
                                            backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${this.props.userInfo.avatar.thumbnail}")`,
                                            borderRadius: "50%",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </motion.li>
                                );
                              else
                                return (
                                  <motion.li
                                    transition={t.transition}
                                    exit={t.fade_out_minimize}
                                    animate={t.normalize}
                                    initial={t.fade_out_minimize}
                                    key={String(message.timestamp)}
                                    className="list-group-item"
                                  >
                                    <div className="d-flex">
                                      <div className="d-flex justify-content-center align-items-center square-3 me-2">
                                        <div
                                          className="fit-images fit-round"
                                          style={{
                                            backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${this.props.profileInfo.user.avatar.thumbnail}")`,
                                            borderRadius: "50%",
                                          }}
                                        ></div>
                                      </div>
                                      <div>
                                        <p className="m-0 text-blusteel">
                                          {h.makeDateHR(
                                            new Date(message.timestamp)
                                          )}{" "}
                                          @{" "}
                                          {h.getTimeHR(
                                            new Date(message.timestamp)
                                          )}
                                        </p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: message.message,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </motion.li>
                                );
                            })}
                        </ul>
                      ) : (
                        <>
                          <i className="far fa-comments d-block mx-auto mt-5 fa-6x text-center" />
                          <h5 className="mt-4 display-6 text-center">
                            Send a message to begin
                          </h5>
                        </>
                      )}
                    </div>
                  </div>
                  <TextInput
                    submit={this.submit}
                    setForceParse={(e) => (this.forceParse = e)}
                    cooldown={this.state.cooldown}
                    flavor="message"
                    maxChars={5000}
                    key={this.state.reset}
                    label="Message"
                  />
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="bg-gray"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  add_message_profile,
  set_unread_messages,
})(MessageModal);
