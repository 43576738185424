import React from "react";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import {
  MDBBtn,
  MDBAlert,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBContainer,
} from "mdb-react-ui-kit";
import { MdFlipCameraIos } from "react-icons/md";
import { BsCameraVideoFill, BsCameraVideoOffFill } from "react-icons/bs";
import Spinner from "../../components/Spinner";
import Chat from "./Chat";
import { LinearProgress } from "@mui/material";

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * cameraFlip: Boolean - Toggled when the user rotates their camera to force the framer-motion animation on the Rotate Camera icon
       *
       */
      cameraFlip: false,
    };
  }

  /**
   *
   * @param {Boolean} background - Whether the method is being called by the background video
   *
   * If background video, will cover the screen (smaller dimension 100%, larger off screen)
   * If main video, will fit to the screen (larger dimension 100%, smaller auto)
   *
   * @returns String - Video classes
   */
  getVideoClasses = (background) => {
    const windowRatio = window.innerWidth / window.innerHeight;
    const cameraRatio =
      this.props.videoDimensions.width / this.props.videoDimensions.height;
    if (background) {
      if (windowRatio > cameraRatio) return "w-100 max-h-100";
      else return "h-100 max-w-100";
    } else {
      if (windowRatio > cameraRatio) return "h-100";
      else return "w-100";
    }
  };

  rotateCamera = () =>
    this.setState(
      {
        ...this.state,
        cameraFlip: !this.state.cameraFlip,
      },
      this.props.rotateCamera
    );

  render() {
    return (
      <motion.div
        className="h-100 w-100 position-relative overflow-x-hidden overflow-y-hidden"
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
      >
        {this.props.cameraEnabled ? (
          <>
            <motion.div
              transition={t.transition}
              exit={t.fade_out}
              animate={t.normalize}
              initial={t.fade_out}
              className="h-100 w-100 position-absolute"
              dangerouslySetInnerHTML={{
                __html: `
                                <video style="object-fit: cover;" class="h-100 w-100" muted autoplay id="streamer-video-background" />
                            `,
              }}
              id="streamer-background-container"
            ></motion.div>
            {this.props.networkProblems ? (
              <motion.section
                transition={t.transition}
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out}
                className="h-100 w-100 position-absolute d-flex justify-content-center align-items-center"
              >
                <MDBContainer className="text-white">
                  <h5 className="text-center mb-5 display-6">
                    Disconnected from stream server. Attempting to reconnect
                  </h5>
                  <LinearProgress className="mt-5" color="inherit" />
                </MDBContainer>
              </motion.section>
            ) : (
              <motion.div
                transition={t.transition}
                exit={t.fade_out}
                animate={t.normalize}
                initial={t.fade_out}
                className="h-100 w-100 d-flex justify-content-center align-items-center position-absolute"
                dangerouslySetInnerHTML={{
                  __html: `
                                <video class="${this.getVideoClasses()}" muted autoplay id="streamer-video-main" />
                            `,
                }}
                id="streamer-video-container"
              ></motion.div>
            )}
          </>
        ) : (
          <motion.section
            transition={t.transition}
            exit={t.fade_out}
            animate={t.normalize}
            initial={t.fade_out}
            className="h-100 w-100 d-flex justify-content-center align-items-center m-0"
          >
            <i className="fas fa-volume-up fa-10x d-block mx-auto text-center"></i>
          </motion.section>
        )}
        <motion.div
          transition={t.transition}
          exit={t.fade_out}
          animate={t.normalize}
          initial={t.fade_out}
          className="position-absolute h-100 w-100 pt-5 top-0"
        >
          <div className="w-100 h-100 d-flex flex-column">
            <div
              className={`transition-33 ${
                this.props.live ? "scale-half-up" : ""
              }`}
            >
              <div
                className={` d-flex justify-content-center stream-device-toggle w-max-content mx-auto p-2 `}
              >
                {this.props.videoDevices.length ? (
                  <MDBBtn
                    onClick={this.props.toggleCamera}
                    color="link"
                    rippleColor="primary"
                    className={`text-dark ${
                      this.props.showCameraToggleButton ||
                      this.props.audioDevices.length
                        ? "me-2"
                        : ""
                    }`}
                  >
                    {this.props.cameraEnabled ? (
                      <>
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <BsCameraVideoFill className="d-block fs-2 mx-auto" />
                          <p className="mb-1 text-center">Camera Enabled</p>
                        </motion.div>
                      </>
                    ) : (
                      <motion.p
                        className="m-0 text-overflow-ellipsis"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        <BsCameraVideoOffFill className="d-block fs-2 mx-auto" />
                        Camera Disabled
                      </motion.p>
                    )}
                  </MDBBtn>
                ) : (
                  <></>
                )}
                {this.props.audioDevices.length ? (
                  <MDBBtn
                    onClick={this.props.toggleMicrophone}
                    color="link"
                    rippleColor="primary"
                    className={`text-dark ${
                      this.props.videoDevices.length ? "ms-2" : ""
                    }`}
                  >
                    {this.props.microphoneEnabled ? (
                      <motion.div
                        className="text-overflow-ellipsis"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        <i className="fas fa-microphone-alt d-block fs-2 mx-auto" />
                        <p className="mb-1 text-center">Microphone Enabled</p>
                      </motion.div>
                    ) : (
                      <motion.p
                        className="m-0 text-overflow-ellipsis"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        <i className="fas fa-microphone-alt-slash d-block fs-2 mx-auto" />
                        Microphone Disabled
                      </motion.p>
                    )}
                  </MDBBtn>
                ) : (
                  <></>
                )}
              </div>
              {this.props.cameraEnabled && this.props.showCameraToggleButton ? (
                <div
                  className={`d-flex justify-content-center stream-device-toggle w-max-content mx-auto p-2 `}
                >
                  <MDBBtn
                    onClick={this.rotateCamera}
                    color="link"
                    rippleColor="primary"
                    className="text-dark"
                  >
                    {this.state.cameraFlip ? (
                      <motion.div
                        className="text-overflow-ellipsis"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        <MdFlipCameraIos
                          size="2rem"
                          className="d-block mx-auto my-2"
                        />
                        Rotate Camera
                      </motion.div>
                    ) : (
                      <motion.section
                        className="text-overflow-ellipsis"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        <MdFlipCameraIos
                          size="2rem"
                          className="d-block mx-auto my-2"
                        />
                        Rotate Camera
                      </motion.section>
                    )}
                  </MDBBtn>
                </div>
              ) : (
                <></>
              )}
            </div>
            {!this.props.audioDevices.length && !this.props.live ? (
              <MDBAlert
                className="w-max-content mt-4 mx-auto"
                show
                color="warning"
              >
                No audio devices detected
              </MDBAlert>
            ) : (
              <></>
            )}
            {!this.props.videoDevices.length && !this.props.live ? (
              <MDBAlert
                className="w-max-content mt-4 mx-auto"
                show
                color="warning"
              >
                No video devices detected
              </MDBAlert>
            ) : (
              <></>
            )}
            <div
              className={`fg-1 d-flex flex-column justify-content-between ${
                this.props.live ? "" : "pt-4"
              }`}
            >
              {this.props.live ? (
                <motion.small
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                  className="d-block mx-auto text-center text-wrap"
                >
                  {this.props.streamTitle.value}
                </motion.small>
              ) : (
                <motion.div
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                >
                  <MDBValidation
                    method="dialog"
                    id="stream-title"
                    name="stream-title"
                    onSubmit={
                      this.props.peerOpen
                        ? this.props.startStream
                        : () =>
                            alert(
                              "Waiting for peer connection. This should only take a couple seconds."
                            )
                    }
                    className="form-containers"
                  >
                    <MDBValidationItem
                      className="pb-4"
                      feedback={this.props.streamTitle.error}
                      invalid={true}
                    >
                      <MDBInput
                        name="streamTitle"
                        onChange={this.props.streamTitleChange}
                        id="streamTitle"
                        label="Title"
                        size="lg"
                        className={!this.props.streamTitle.invalid ? "mb-0" : 0}
                        type="text"
                        value={this.props.streamTitle.value}
                      />
                    </MDBValidationItem>
                  </MDBValidation>
                </motion.div>
              )}
              {this.props.peerOpen ? (
                <motion.div
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                  className="position-relative"
                >
                  <MDBBtn
                    disabled={this.props.working}
                    color={this.props.live ? "danger" : "success"}
                    className={`${
                      this.props.live ? "stream-button-live" : "btn-xl"
                    } stream-control-buttons `}
                    onClick={
                      this.props.working
                        ? () => {}
                        : this.props.live
                        ? this.props.endStream
                        : this.props.startStream
                    }
                  >
                    {this.props.live ? (
                      this.props.working ? (
                        <>
                          <Spinner className="me-2" size="sm" />
                          Ending
                        </>
                      ) : (
                        <>
                          <i className="fas fa-times-circle me-2"></i>
                          End Stream
                        </>
                      )
                    ) : this.props.working ? (
                      <>
                        <Spinner size="sm" className="me-2" />
                        Starting Stream
                      </>
                    ) : (
                      <>
                        <i className="fas fa-broadcast-tower me-2"></i>
                        Start Stream
                      </>
                    )}
                  </MDBBtn>
                </motion.div>
              ) : (
                <motion.div
                  transition={t.transition}
                  exit={t.fade_out}
                  animate={t.normalize}
                  initial={t.fade_out}
                  className="position-relative"
                >
                  <MDBBtn
                    disabled
                    color="primary"
                    className="btn-xl stream-control-buttons"
                  >
                    Establishing Peer Connection
                  </MDBBtn>
                </motion.div>
              )}
            </div>
          </div>
        </motion.div>
        {this.props.live ? <Chat chat="self" /> : <></>}
      </motion.div>
    );
  }
}

export default Main;
