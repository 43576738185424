import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBRipple,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBContainer,
} from "mdb-react-ui-kit";
import { AnimatePresence } from "framer-motion";
import { StaticRouter, Switch, Route, Link } from "react-router-dom";
import File from "./File";
import t from "../../utilities/transitions";
import { connect } from "react-redux";
import { route } from "../../redux/actions";
import h from "../../utilities/helpers";
import { motion } from "framer-motion";
import axios from "axios";
import Spinner from "../Spinner";
import Url from "url-parse";

class FileModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * enter: Object - framer-motion entrance animation
       * exit: Object - framer-motion exit animation
       * emissionCopied: Boolean - Whether the emission direct link has been copied
       * actionFormOpened: Boolean - Whether the remove/report form has been opened
       */
      enter: t.fade_out,
      exit: t.fade_out,
      emissionCopied: false,
      actionFormOpened: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.modalShown && this.props.modalShown)
      window.addEventListener("keydown", this.arrowNav);
    if (prevProps.modalShown && !this.props.modalShown)
      window.removeEventListener("keydown", this.arrowNav);
  }

  arrowNav = (e) => {
    if (
      e.key === "ArrowRight" &&
      this.props.fileList[this.props.fileList.indexOf(this.props.file) + 1]
    )
      this.next();
    if (
      e.key === "ArrowLeft" &&
      this.props.fileList[this.props.fileList.indexOf(this.props.file) - 1]
    )
      this.back();
  };

  // Toggles the remove/report form
  openActionForm = () =>
    this.setState({
      ...this.state,
      actionFormOpened: !this.state.actionFormOpened,
    });

  /**
   * Triggered when the user clicks the back button
   * Adjust animations to slide right
   * Triggers the fileNav method in the parent
   *
   */
  back = () =>
    this.setState(
      {
        ...this.state,
        exit: t.fade_out_right,
        enter: t.fade_out_left,
      },
      () => this.props.fileNav(false)
    );

  /**
   * Triggered when the user clicks the next button
   * Adjust animations to slide right
   * Triggers the fileNav method in the parent
   *
   */
  next = () =>
    this.setState(
      {
        ...this.state,
        enter: t.fade_out_right,
        exit: t.fade_out_left,
      },
      () => this.props.fileNav(true)
    );

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the modal
   * If the user clicked blank space, close the modal
   */
  clickToClose = (e) => {
    if (e.target.classList.contains("file-modal-blank-space"))
      this.setState(
        {
          ...this.state,
          enter: t.fade_out,
          exit: t.fade_out,
        },
        () => this.props.toggleShowModal()
      );
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (href) {
        const url = new Url(href);
        if (url.hostname === window.location.hostname)
          this.props.route(url.pathname);
        else window.location = href;
      }
    }
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, path) => {
    e.preventDefault();
    this.props.route(path);
  };

  /**
   * Triggered when the user clicks the Copy Link button
   *
   * Copies the link to the user's clipboard
   * Dispatches blank scoll event which updates the tooltip
   *
   */
  copyEmissionLink = () =>
    this.setState(
      {
        ...this.state,
        emissionCopied: true,
      },
      () => {
        navigator.clipboard.writeText(
          process.env.REACT_APP_HOST + "/e/" + this.props.emissionID
        );
        window.dispatchEvent(new Event("scroll"));
      }
    );

  externalAction = (action) => {
    if (!this.props.userInfo.ban.banned) this.props.fileAction(action);
    else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = (emission) => {
    if (emission) {
      if (emission.isBlocked) return "bg-litepurple";
      else if (emission.remove.removed) return "bg-litepink";
      else if (emission.blocksMe) return "bg-litepurple";
      else if (emission.private) return "bg-liteteal";
      else return "bg-default";
    } else return "bg-default";
  };

  /**
   *
   * Special emissions have gold numbers.
   * The following Emission emissionIDs are special:
   * * Emission 1
   * * Any emission over 10 with all the same numbers (i.e. 666)
   * * Any emission over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the emission is a special emission
   */
  checkSpecialEmission = (emission) => {
    if (emission.emissionID === 1) return true;
    const split = String(emission.emissionID).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   * Triggered when the user removes or restores their own emission
   *
   * Removes/restores the emission
   * Updates the emission
   */
  removeRestore = () => {
    if (!this.state.removing)
      this.setState(
        {
          ...this.state,
          removing: true,
        },
        () =>
          axios
            .get(`/emissions/remove-restore-self/${this.props.emissionID}`)
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  removing: false,
                },
                () => this.props.updateEmission(res.data.emission)
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  removing: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user pins or unpins their own emission
   *
   * Pins/unpins the emission
   * Updates the emission
   */
  togglePin = () => {
    if (!this.state.pinning)
      this.setState(
        {
          ...this.state,
          pinning: true,
        },
        () =>
          axios
            .get(`/emissions/pin-unpin/${this.props.emissionID}`)
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  pinning: false,
                },
                () =>
                  this.props.updateEmission(h.setMetadata(res.data.emission))
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  pinning: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  render() {
    let emission;
    if (this.props.emissionID)
      this.props.emissionList.forEach((e) => {
        if (e.emissionID === this.props.emissionID) emission = e;
        if (e.signalBoost?.emissionID === this.props.emissionID) emission = e;

        if (e.replyEmission) {
          if (e.replyEmission.emissionID === this.props.emissionID)
            emission = e.replyEmission;
          if (e.replyEmission.signalBoost?.emissionID === this.props.emissionID)
            emission = e.replyEmission;

          if (e.replyEmission.replyEmission) {
            if (e.replyEmission.emissionID === this.props.emissionID)
              emission = e.replyEmission.replyEmission;
            if (
              e.replyEmission.replyEmission.signalBoost?.emissionID ===
              this.props.emissionID
            )
              emission = e.replyEmission.replyEmission;
          }
        }
      });
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            animationDirection="right"
            className="bg-transparent"
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog className="bg-transparent" size="fullscreen">
              <MDBModalContent className="bg-transparent">
                <MDBModalBody className="bg-transparent p-0">
                  <div className="d-flex bg-transparent h-100 file-modal-containers">
                    <div
                      onClick={this.clickToClose}
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.4" }}
                      className="w-75 h-100 d-flex flex-column file-modal-blank-space"
                    >
                      <div className="flex-grow-1 h-0 d-flex justify-content-center align-items-center w-100 file-modal-blank-space">
                        <div className="w-10 d-flex justify-content-center file-modal-blank-space">
                          {this.props.fileList[
                            this.props.fileList.indexOf(this.props.file) - 1
                          ] ? (
                            <MDBRipple
                              onClick={this.back}
                              className="cursor-pointer file-arrow-containers  rounded-pill"
                              rippleColor="light"
                            >
                              <i className="fas fa-chevron-left text-light file-modal-arrows"></i>
                            </MDBRipple>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="overflow-x-hidden overflow-y-hidden w-80 h-100 file-modal-blank-space py-2">
                          {this.props.file ? (
                            <StaticRouter
                              location={this.props.file.main.split(".")[0]}
                            >
                              <AnimatePresence exitBeforeEnter>
                                <Switch
                                  key={this.props.file.main.split(".")[0]}
                                >
                                  <Route exact path=":file">
                                    <File
                                      file={this.props.file}
                                      enter={this.state.enter}
                                      exit={this.state.exit}
                                    />
                                  </Route>
                                </Switch>
                              </AnimatePresence>
                            </StaticRouter>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="w-10 d-flex justify-content-center file-modal-blank-space">
                          {this.props.fileList[
                            this.props.fileList.indexOf(this.props.file) + 1
                          ] ? (
                            <MDBRipple
                              onClick={this.next}
                              className="cursor-pointer file-arrow-containers rounded-pill"
                              rippleColor="light"
                            >
                              <i className="fas fa-chevron-right file-modal-arrows d-block text-light"></i>
                            </MDBRipple>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {!emission ||
                      emission.remove.removed ||
                      this.props.userInfo.ban.banned ||
                      emission.isBlocked ||
                      emission.blocksMe ||
                      emission.private ? (
                        <></>
                      ) : (
                        <MDBContainer className="d-flex justify-content-between file-button-container">
                          <MDBTooltip
                            wrapperProps={{
                              color: "link",
                              rippleColor: "primary",
                              size: "sm",
                              className: "text-nowrap file-footer-buttons",
                              onClick: () =>
                                this.externalAction(this.props.reply(emission)),
                            }}
                            title="Reply"
                          >
                            <span className="file-stats">
                              <i className="far fa-comments file-modal-icons"></i>
                              {h.compiledNumber(emission.replies)}
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            wrapperProps={{
                              color: "link",
                              rippleColor: "primary",
                              size: "sm",
                              className: "text-nowrap file-footer-buttons",
                              onClick: () =>
                                this.externalAction(
                                  this.props.signalBoost(emission)
                                ),
                            }}
                            title={
                              <span className="text-capitalize">
                                {process.env.REACT_APP_SIGNALBOOST_VERB}
                              </span>
                            }
                          >
                            <span className="file-stats">
                              <i className="fas fa-retweet file-modal-icons"></i>
                              {h.compiledNumber(emission.signalBoosts)}
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            wrapperProps={{
                              color: "link",
                              rippleColor: "#fc45df",
                              size: "sm",
                              onClick: () =>
                                this.props.like(emission.emissionID),
                              className: "text-nowrap file-footer-buttons",
                            }}
                            title="Like"
                          >
                            <span
                              className={`${
                                emission.liked ? "text-pink" : ""
                              } file-stats`}
                            >
                              <i
                                className={`fa${
                                  emission.liked ? "s" : "r"
                                } fa-heart file-modal-icons`}
                              ></i>
                              {h.compiledNumber(emission.likes)}
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            className="min-w-max-content text-nowrap"
                            wrapperProps={{
                              color: "link",
                              className:
                                "cursor-default file-footer-buttons text-nowrap",
                              rippleColor: "primary",
                              size: "sm",
                            }}
                            title="Views"
                          >
                            <span className="file-stats">
                              <i className="fas fa-chart-bar file-modal-icons"></i>
                              {h.compiledNumber(emission.views)}
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            className="min-w-max-content text-nowrap"
                            wrapperProps={{
                              color: "link",
                              rippleColor: "primary",
                              onClick: this.copyEmissionLink,
                              size: "sm",
                              className: "text-nowrap file-footer-buttons",
                            }}
                            title={
                              this.state.emissionCopied
                                ? "Link Copied"
                                : process.env.REACT_APP_HOST +
                                  "/e/" +
                                  this.props.emissionID
                            }
                            placement="left"
                          >
                            <span className="file-stats">
                              <i className="fas fa-share-alt file-modal-icons"></i>
                              Link
                            </span>
                          </MDBTooltip>
                        </MDBContainer>
                      )}
                    </div>
                    <div
                      className={`w-25 h-100 transition-25 ${this.getCardBackground(
                        emission
                      )} file-modal-info`}
                    >
                      <i
                        className="fas fa-times fa-lg d-block ms-auto me-1 mt-1 text-default cursor-pointer w-max-content"
                        onClick={this.props.toggleShowModal}
                      ></i>
                      <hr></hr>
                      {emission ? (
                        <>
                          <div className="file-details-body">
                            <div className="d-flex emission-details-file justify-content-between">
                              <div className="d-flex avatar-name-file">
                                <Link
                                  onClick={(e) =>
                                    this.route(e, `/${emission.username}`)
                                  }
                                  className="h-max-content w-max-content"
                                  to={`/${emission.username}`}
                                >
                                  <div className="d-flex justify-content-center align-items-center square-4 cursor-pointer">
                                    <div
                                      className="fit-images rounded-circle fit-round"
                                      style={{
                                        backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/images/${emission.avatar.main}")`,
                                      }}
                                    ></div>
                                  </div>
                                </Link>
                                <div className="px-2 flex-grow-1">
                                  <Link
                                    onClick={(e) =>
                                      this.route(e, `/${emission.username}`)
                                    }
                                    className="max-w-max-content d-block"
                                    to={`/${emission.username}`}
                                  >
                                    <h5 className="mb-0 text-default text-break mx-0">
                                      {h.getBadge(emission, "me-2 badge-h5")}
                                      {emission.displayName}
                                    </h5>
                                  </Link>
                                  <Link
                                    onClick={(e) =>
                                      this.route(e, `/${emission.username}`)
                                    }
                                    className="max-w-max-content d-block"
                                    to={`/${emission.username}`}
                                  >
                                    <p className="text-blusteel text-break mx-0">
                                      @{emission.username}
                                    </p>
                                  </Link>
                                </div>
                              </div>
                              <hr className="file-dividers"></hr>
                              <div className="file-ids">
                                <h5
                                  className={`text-end mb-0 text-pkmn text-nowrap d-block ms-auto px-2 text-${
                                    this.checkSpecialEmission(emission)
                                      ? "gold"
                                      : "default"
                                  }`}
                                >
                                  <Link
                                    className="text-default"
                                    onClick={(e) =>
                                      this.route(e, `/e/${emission.emissionID}`)
                                    }
                                    to={`/e/${emission.emissionID}`}
                                  >
                                    #{emission.emissionID}
                                  </Link>
                                </h5>
                                <p className="mb-0 text-end d-block ms-auto px-2 text-nowrap">
                                  {h.makeDateHR(emission.timestamp)}
                                </p>
                                <p className="mb-0 text-blusteel text-end d-block ms-auto px-2 text-nowrap">
                                  {h.getTimeHR(emission.timestamp)}
                                </p>
                                {emission.userID === this.props.userInfo._id &&
                                !this.props.userInfo.ban.banned ? (
                                  <div className="d-flex justify-content-end file-action-buttons">
                                    <MDBDropdown className="ms-2">
                                      <MDBDropdownToggle
                                        rippleColor="primary"
                                        color="link"
                                        className="profile-bubbles"
                                      >
                                        <i
                                          style={{ lineHeight: "1.5" }}
                                          className="fas fa-ellipsis-v fa-lg"
                                        ></i>
                                      </MDBDropdownToggle>
                                      <MDBDropdownMenu>
                                        {emission.pinned ? (
                                          <MDBDropdownItem
                                            onClick={this.togglePin}
                                            link
                                          >
                                            <h6 className="m-1">
                                              {this.state.pinning ? (
                                                <>
                                                  <Spinner
                                                    color="primary"
                                                    className="me-2"
                                                    size="sm"
                                                  />
                                                  Unpinning
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fas fa-thumbtack me-2 text-primary"></i>
                                                  Unpin
                                                </>
                                              )}
                                            </h6>
                                          </MDBDropdownItem>
                                        ) : (
                                          <MDBDropdownItem
                                            onClick={this.togglePin}
                                            link
                                          >
                                            <h6 className="m-1">
                                              {this.state.pinning ? (
                                                <>
                                                  <Spinner
                                                    className="me-2"
                                                    size="sm"
                                                    color="primary"
                                                  />
                                                  Pinning
                                                </>
                                              ) : (
                                                <>
                                                  <i className="fas fa-thumbtack me-2 text-primary"></i>
                                                  Pin
                                                </>
                                              )}
                                            </h6>
                                          </MDBDropdownItem>
                                        )}
                                        {emission.remove.removed ? (
                                          <>
                                            {emission.remove.user.userID ===
                                            this.props.userInfo._id ? (
                                              <MDBDropdownItem
                                                onClick={this.removeRestore}
                                                link
                                              >
                                                <h6
                                                  style={{
                                                    opacity: this.state.removing
                                                      ? 0.7
                                                      : 1,
                                                  }}
                                                  className="m-1"
                                                >
                                                  {this.state.removing ? (
                                                    <>
                                                      <Spinner
                                                        color="success"
                                                        size="sm"
                                                        className="me-2"
                                                      />
                                                      Restoring
                                                    </>
                                                  ) : (
                                                    <>
                                                      <i className="far fa-check-circle me-2 text-success"></i>
                                                      Restore
                                                    </>
                                                  )}
                                                </h6>
                                              </MDBDropdownItem>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <MDBDropdownItem
                                              onClick={this.removeRestore}
                                              link
                                            >
                                              <h6
                                                style={{
                                                  opacity: this.state.removing
                                                    ? 0.7
                                                    : 1,
                                                }}
                                                className="m-1"
                                              >
                                                {this.state.removing ? (
                                                  <>
                                                    <Spinner
                                                      color="danger"
                                                      className="me-2"
                                                      size="sm"
                                                    />
                                                    Removing
                                                  </>
                                                ) : (
                                                  <>
                                                    <i className="far fa-trash-alt me-2 text-danger"></i>
                                                    Remove
                                                  </>
                                                )}
                                              </h6>
                                            </MDBDropdownItem>
                                          </>
                                        )}
                                      </MDBDropdownMenu>
                                    </MDBDropdown>
                                  </div>
                                ) : (
                                  <>
                                    {h.checkJanny(this.props.userInfo) ? (
                                      <>
                                        {emission.remove.removed ? (
                                          <MDBBtn
                                            onClick={() =>
                                              this.externalAction(() =>
                                                this.props.restore(emission)
                                              )
                                            }
                                            className="text-blusteel d-block ms-auto px-2"
                                            color="link"
                                          >
                                            <i className="far fa-check-circle me-2"></i>
                                            Restore
                                          </MDBBtn>
                                        ) : (
                                          <MDBBtn
                                            onClick={() =>
                                              this.externalAction(() =>
                                                this.props.remove(emission)
                                              )
                                            }
                                            className="text-blusteel d-block ms-auto px-2"
                                            color="link"
                                          >
                                            <i className="fas fa-ban me-2"></i>
                                            Remove
                                          </MDBBtn>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {emission.remove.removed ||
                                        this.props.userInfo.ban.banned ||
                                        emission.isBlocked ||
                                        emission.blocksMe ||
                                        emission.private ? (
                                          <></>
                                        ) : (
                                          <>
                                            {emission.reported ? (
                                              <motion.h5
                                                transition={t.transition}
                                                exit={t.fade_out_minimize}
                                                animate={t.normalize}
                                                initial={t.fade_out_minimize}
                                                className="text-danger text-end ms-auto"
                                              >
                                                <span className="text-capitalize">
                                                  {
                                                    process.env
                                                      .REACT_APP_EMISSION_NAME
                                                  }
                                                </span>{" "}
                                                Reported
                                              </motion.h5>
                                            ) : (
                                              <MDBBtn
                                                onClick={() =>
                                                  this.externalAction(() =>
                                                    this.props.report(emission)
                                                  )
                                                }
                                                className="text-blusteel d-block ms-auto px-2"
                                                color="link"
                                              >
                                                <i className="fas fa-flag me-2"></i>
                                                Report
                                              </MDBBtn>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="file-emission-bodies">
                              {h.getMediaFromHtml(emission.html)}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: emission.html,
                                }}
                                onClick={this.clickEmissionBody}
                              ></div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(FileModal);
