import React from "react";
import md5 from "md5";
import sanitize from "sanitize-html";
import { parse } from "node-html-parser";
import { MDBBadge, MDBTooltip } from "mdb-react-ui-kit";
import videoParser from "js-video-url-parser";
import VideoSnapshot from "video-snapshot";

const urlRegex =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const h = {};

/**
 *
 * @param {String} dataURL - DataURL
 * @param {String} filename - File name
 *
 * Converts dataURL to Uint8Array, then feeds Uint8Array, filename, and mimetype into File constructor
 *
 * @returns JavaScript file object
 */
h.dataURLtoFile = (dataURL, filename) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

/**
 *
 * @param {File} file - Video file
 *
 * Creates <video>
 * Sets src of video to file
 * Once duration is determined, get VideoSnapshot
 *
 * @returns Thumbnail of video taken halfway through the video
 */
h.getVideoThumbnail = (file) =>
  new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.ondurationchange = async function () {
      try {
        const thumbnail = await new VideoSnapshot(file).takeSnapshot(
          this.duration / 2
        );
        resolve(h.dataURLtoFile(thumbnail, "file.png"));
      } catch (err) {
        console.log("Video thumbnail error", err);
        reject(err);
      }
    };
  });

// Hides all tooltips on the page
h.hideToolTips = () =>
  Array.from(document.getElementsByClassName("tooltip")).forEach((e) =>
    e.classList.remove("show")
  );

/**
 *
 * @param {String} html
 * @returns JSX - Fragment or iframe of last supported 3rd party video embed in it
 */
h.getMediaFromHtml = (html) => {
  try {
    const urls = html.match(urlRegex);
    if (!urls) return <></>;
    else {
      let video = false;
      urls.forEach((url) => {
        const parsed = videoParser.parse(url);
        if (parsed) video = parsed;
      });
      if (!video) return <></>;
      else
        switch (video.provider) {
          case "youtube":
            return (
              <iframe
                className="iframe-videos no-route"
                src={`https://www.youtube.com/embed/${video.id}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            );
          case "vimeo":
            return (
              <iframe
                src={`https://player.vimeo.com/video/${video.id}`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Vimeo video player"
                className="iframe-videos no-route"
              />
            );
          case "twitch":
            return (
              <iframe
                src={`https://player.twitch.tv/?channel=${
                  video.channel
                }&parent=${process.env.REACT_APP_HOST.split("://")[1]}`}
                frameborder="0"
                allowfullscreen="true"
                scrolling="no"
                className="iframe-videos no-route"
              />
            );
          case "dailymotion":
            return (
              <iframe
                frameborder="0"
                type="text/html"
                src={`https://www.dailymotion.com/embed/video/${video.id}`}
                allowfullscreen
                title="Dailymotion Video Player"
                allow="autoplay"
                className="iframe-videos no-route"
              />
            );
          default:
            return <></>;
        }
    }
  } catch (err) {
    console.log("get media from html error", err);
    return <></>;
  }
};

/**
 *
 * @param {Object} userInfo - Users document
 * @param {String} classes - CSS classes
 * @returns Tooltip with badge that depends on the user's role with provided CSS classes
 */
h.getBadge = (userInfo, classes) => {
  if (userInfo.role === "Chadmin")
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{ className: "name-chadmin cursor-default" }}
        title={process.env.REACT_APP_ADMIN_NAME}
      >
        <MDBBadge className={`badge-chadmin ${classes} no-route`}>
          <div className="d-flex justify-content-center align-items-center no-route">
            <div
              className="fit-images no-route"
              style={{ backgroundImage: `url("/assets/images/meltrans.png")` }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else if (userInfo.role === "Janny")
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{ className: "name-janny cursor-default no-route" }}
        title={process.env.REACT_APP_MOD_NAME}
      >
        <MDBBadge className={`badge-janny no-route ${classes}`}>
          <div className="d-flex justify-content-center align-items-center no-route">
            <div
              className="fit-images no-route"
              style={{
                backgroundImage: `url("/assets/images/thomastrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else if (userInfo.verified)
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{ className: "name-verified cursor-default no-route" }}
        title="Verified"
      >
        <MDBBadge className={`badge-verified ${classes} no-route`}>
          <div className="d-flex justify-content-center align-items-center no-route">
            <div
              className="fit-images no-route"
              style={{
                backgroundImage: `url("/assets/images/verifiedlogotrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else return <></>;
};

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable date in the format "MM/DD/YYYY"
 */
h.makeDateHR = (date) => {
  date = new Date(date);
  let months = date.getMonth() + 1;
  let days = date.getDate();
  let years = date.getFullYear();
  return months + "/" + days + "/" + years;
};

/**
 *
 * @param {JavaScript date} timestamp
 * @returns The time that the message was sent if sent less than a day ago, otherwise the date that the message was sent
 */
h.getMessageTime = (timestamp) => {
  const now = new Date();
  const messageTime = new Date(timestamp);
  const timeDifference = now.getTime() - messageTime.getTime();
  const day = 1000 * 60 * 60 * 24;
  if (timeDifference > day) return h.getNiceDate(timestamp);
  else return h.getTimeHR(timestamp);
};

/**
 *
 * @param {Number} size - Size of the file to be measured
 * @returns Size of file in Bytes/KB/MB/etc
 */
h.getFileSize = (size) => {
  size = Number(size);
  const units = ["Bytes", "KB", "MB", "GB"];
  let scale = 0;
  while (size > 900 && scale < 3) {
    size /= 1024;
    scale++;
  }
  return Math.round(size * 100) / 100 + " " + units[scale];
};

/**
 *
 * @param {String} html - HTML string
 * @returns The length of the regular text in the string sans whitespace that is not a space or new line
 */
h.checkHTMLLength = (html) =>
  String(parse(html).textContent)
    .split("")
    .filter((c) => {
      const checkWhiteSpace = c.match(/[\s]/);
      if (!checkWhiteSpace) return true;
      else {
        return [" ", "\n"].indexOf(c) > -1;
      }
    }).length;

/**
 *
 * @param {Object} userInfo - Users document
 * @param {Array} rawData - List of emissions
 * @returns emissionIDs of emissions that were authored by the user
 */
h.getThreadEmissions = (userInfo, rawData) => {
  let emissions = [];
  rawData.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  emissions = [...new Set(emissions)];
  return emissions;
};

/**
 *
 * @param {Object} userInfo - Users document
 * @param {Object} profileInfo - Profile Info
 * @returns emissionIDs of emissions in profile emissions and profile likes that were authored by the user
 */
h.getUserProfileEmissions = (userInfo, profileInfo) => {
  let emissions = [];
  profileInfo.emissions.items.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  profileInfo.likes.items.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  emissions = [...new Set(emissions)];
  return emissions;
};

/**
 *
 * @param {Array} oldEmissions - Old list of emissions
 * @param {Array} newEmissions - New list of emissions
 *
 * Loops through old emissions
 * If any match any in the newEmissions array, replace with new data
 *
 * @returns Updated list of emissions
 */
h.replaceUserEmissions = (oldEmissions, newEmissions) =>
  oldEmissions.map((e) => {
    let replacedEmission;
    replacedEmission = newEmissions.find(
      (emission) => emission.emissionID === e.emissionID
    );
    if (replacedEmission) e = replacedEmission;
    else {
      if (e.signalBoost) {
        replacedEmission = newEmissions.find(
          (emission) => emission.emissionID === e.signalBoost.emissionID
        );
        if (replacedEmission) e.signalBoost = replacedEmission;
      }
      if (e.replyEmission) {
        replacedEmission = newEmissions.find(
          (emission) => emission.emissionID === e.replyEmission.emissionID
        );
        if (replacedEmission) e.replyEmission = replacedEmission;
        else {
          if (e.replyEmission.signalBoost) {
            replacedEmission = newEmissions.find(
              (emission) =>
                emission.emissionID === e.replyEmission.signalBoost.emissionID
            );
            if (replacedEmission)
              e.replyEmission.signalBoost = replacedEmission;
          }
          if (e.replyEmission.replyEmission) {
            replacedEmission = newEmissions.find(
              (emission) =>
                emission.emissionID === e.replyEmission.replyEmission.emissionID
            );
            if (replacedEmission)
              e.replyEmission.replyEmission = replacedEmission;
            else {
              if (e.replyEmission.replyEmission.signalBoost) {
                replacedEmission = newEmissions.find(
                  (emission) =>
                    emission.emissionID ===
                    e.replyEmission.replyEmission.signalBoost.emissionID
                );
                if (replacedEmission)
                  e.replyEmission.replyEmission.signalBoost = replacedEmission;
              }
            }
          }
        }
      }
    }

    return e;
  });

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable time in the format "0:00AM"
 */
h.getTimeHR = (date) => {
  date = new Date(date);
  let meridian = "AM";
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours >= 12) meridian = "PM";
  if (!hours) hours = 12;
  if (hours > 12) {
    hours -= 12;
  }
  if (String(minutes).length === 1) minutes = `0${minutes}`;
  return hours + ":" + minutes + meridian;
};

/**
 *
 * @param {MediaStream} stream
 * @returns Dimensions of the stream in pixels
 */
h.getStreamDimensions = (stream) =>
  new Promise((resolve, reject) => {
    let video = document.createElement("video");
    video.muted = true;
    video.srcObject = stream;
    video.onloadedmetadata = () => {
      const dimensions = {
        width: video.videoWidth,
        height: video.videoHeight,
      };

      video = null;

      resolve(dimensions);
    };
  });

/**
 * @param {String | Number} num - A number (i.e. 1000000)
 * @returns String - Number with commas appended (i.e. 1,000,000)
 */
h.numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 *
 * @param {String} string
 * @returns The first up to 24 characters of that string
 */
h.veryShortString = (string) => {
  string = String(string);
  if (string.length > 25) return string.substring(0, 24) + "...";
  else return string;
};

/**
 *
 * @param {String} string
 * @returns The first up to 99 characters of that string
 */
h.shortString = (string) => {
  string = String(string);
  if (string.length > 100) return string.substring(0, 99) + "...";
  else return string;
};

/**
 *
 * @param {String} string
 * @returns Boolean - Whether the string is a number.
 */
h.isNumeric = (string) => {
  if (typeof string != "string") return false;
  return !isNaN(string) && !isNaN(parseFloat(string));
};

/**
 * Fixes MDB bug in which labels on inputs with text input are not properly floated
 * Floats the labels
 */
h.floatLabels = () =>
  setTimeout(
    () =>
      [].slice
        .call(document.getElementsByClassName("form-control"))
        .forEach((e) => {
          if (e.value) {
            if (!e.classList.contains("active")) {
              e.classList.add("active");
              const oldValue = e.value;
              e.value += "4";
              e.value = oldValue;
            }
          }
        }),
    250
  );

/**
 *
 * @param {JavaScript date} date
 * @returns Date in the format of "Jan 1, 1970"
 */
h.getNiceDate = (date) => {
  date = new Date(date);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

/**
 *
 * @param {File} file
 * @returns md5 hash of the file
 */
h.getMD5 = (file) =>
  new Promise((resolve, reject) => {
    fetch(URL.createObjectURL(file))
      .then((res) =>
        res
          .text()
          .then((text) => resolve(md5(text)))
          .catch((err) => {
            console.log(err);
            return reject(err);
          })
      )
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

/**
 *
 * @param {Number} time - Milliseconds to sleep
 *
 * Freezes for the amount of milliseconds specified
 */
h.sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

/**
 * Executes a captcha challenge and generates a key a key
 * Will hang until connected to captcha servers
 */
h.getRecaptcha = (reCaptchaProps) =>
  new Promise(async (resolve, reject) => {
    if (reCaptchaProps.executeRecaptcha)
      reCaptchaProps
        .executeRecaptcha()
        .then((res) => resolve(res))
        .catch((err) => {
          console.log("captcha error", err);
          alert("Recaptcha error. Refresh the page and try again.");
          return reject(false);
        });
    else {
      return reject(true);
    }
  });

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Janny privileges
 */
h.checkJanny = (userInfo) =>
  userInfo && ["Janny", "Chadmin"].indexOf(userInfo.role) !== -1;

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Chadmin privileges
 */
h.checkChadmin = (userInfo) => userInfo && userInfo.role === "Chadmin";

/**
 *
 * @param {String} code - Removal code
 * @returns Removal label
 */
h.getRemovedReason = (code) => {
  switch (code) {
    case "fed":
      return "Terrorism/Fedposting";
    case "porn":
      return "Porn";
    case "spam":
      return "Spam";
    default:
      console.log("Oob removed reason", code);
      return "Other";
  }
};

/**
 *
 * @param {String} string
 * @returns The first up to 100 characters of that string
 */
h.abbreviatedText = (text) => {
  text = String(text);
  if (text.length > 100) return text.substring(0, 100) + "...";
  else return text;
};

/**
 *
 * @param {String} string
 * @returns The first up to 1000 characters of that string
 */
h.longString = (text) => {
  text = String(text);
  if (text.length > 1000) return text.substring(0, 1000) + "...";
  else return text;
};

/**
 *
 * @param {Object} emission - Emissions document
 * @param {Object} userInfo - Users document
 * @returns Emission with metadata applied (likes/votes on main/replies/signalboosted/etc)
 */
h.setMetadata = (emission, userInfo) => {
  if (emission.userLikes || !userInfo)
    emission.liked = userInfo
      ? emission.userLikes.indexOf(userInfo._id) > -1
      : false;
  if (emission.pollData && (emission.pollData.voters || !userInfo))
    emission.pollData.voted = userInfo
      ? emission.pollData.voters.find(
          (voter) => voter.userID === userInfo._id
        ) !== undefined
      : false;

  if (emission.signalBoost) {
    if (emission.signalBoost.userLikes || !userInfo)
      emission.signalBoost.liked = userInfo
        ? emission.signalBoost.userLikes.indexOf(userInfo._id) > -1
        : false;
    if (
      emission.signalBoost.pollData &&
      (emission.signalBoost.pollData.voters || !userInfo)
    )
      emission.signalBoost.pollData.voted = userInfo
        ? emission.signalBoost.pollData.voters.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;
  }

  if (emission.replyEmission) {
    if (emission.replyEmission.userLikes || !userInfo)
      emission.replyEmission.liked = userInfo
        ? emission.replyEmission.userLikes.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;
    if (
      emission.replyEmission.pollData &&
      (emission.replyEmission.pollData.voters || !userInfo)
    )
      emission.replyEmission.pollData.voted = userInfo
        ? emission.replyEmission.pollData.voters.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;

    if (emission.replyEmission.signalBoost) {
      if (emission.replyEmission.signalBoost.userLikes || !userInfo)
        emission.replyEmission.signalBoost.liked = userInfo
          ? emission.replyEmission.signalBoost.userLikes.find(
              (voter) => voter.userID === userInfo._id
            ) !== undefined
          : false;
      if (
        emission.replyEmission.signalBoost.pollData &&
        (emission.replyEmission.signalBoost.pollData.voters || !userInfo)
      )
        emission.replyEmission.signalBoost.pollData.voted = userInfo
          ? emission.replyEmission.signalBoost.pollData.voters.find(
              (voter) => voter.userID === userInfo._id
            ) !== undefined
          : false;
    }
  }

  if (emission.replyEmission && emission.replyEmission.replyEmission) {
    if (emission.replyEmission.replyEmission.userLikes || !userInfo)
      emission.replyEmission.replyEmission.liked = userInfo
        ? emission.replyEmission.replyEmission.userLikes.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;
    if (
      emission.replyEmission.replyEmission.pollData &&
      (emission.replyEmission.replyEmission.pollData.voters || !userInfo)
    )
      emission.replyEmission.replyEmission.pollData.voted = userInfo
        ? emission.replyEmission.replyEmission.pollData.voters.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;

    if (emission.replyEmission.replyEmission.signalBoost) {
      if (
        emission.replyEmission.replyEmission.signalBoost.userLikes ||
        !userInfo
      )
        emission.replyEmission.replyEmission.signalBoost.liked = userInfo
          ? emission.replyEmission.replyEmission.signalBoost.userLikes.find(
              (voter) => voter.userID === userInfo._id
            ) !== undefined
          : false;
      if (
        emission.replyEmission.replyEmission.signalBoost.pollData &&
        (emission.replyEmission.replyEmission.signalBoost.pollData.voters ||
          !userInfo)
      )
        emission.replyEmission.replyEmission.signalBoost.pollData.voted =
          userInfo
            ? emission.replyEmission.replyEmission.signalBoost.pollData.voters.find(
                (voter) => voter.userID === userInfo._id
              ) !== undefined
            : false;
    }
  }

  return emission;
};

/**
 *
 * @param {HTML Element} e
 * @returns Inner dimensions of the element
 */
h.innerDimensions = (e) => {
  const computedStyle = getComputedStyle(e);

  let width = e.clientWidth;
  let height = e.clientHeight;

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);

  return {
    height: height,
    width: width,
  };
};

/**
 *
 * @param {Object} profileInfo
 * @returns Blank profile
 */
h.purgeProfileInfo = (profileInfo) => ({
  ...profileInfo,
  emissions: {
    ...profileInfo.emissions,
    items: profileInfo.emissions.items.map((e) => h.setMetadata(e, false)),
  },
  likes: {
    ...profileInfo.likes,
    items: profileInfo.likes.items.map((e) => h.setMetadata(e, false)),
  },
  following: false,
  followsYou: false,
  live: false,
});

/**
 *
 * @param {Array} emissions - List of emissions
 * @param {Number} emissionID - ref Emissions.emissionID
 * @param {Object} newData - Keys/values of emission with emissionID to update
 * @returns Emissions array with the emission with emissionID updated
 */
h.replaceEmissions = (emissions, emissionID, newData) =>
  emissions.map((emission) => {
    if (emission.emissionID === emissionID)
      emission = {
        ...emission,
        ...newData,
      };
    if (emission.signalBoost && emission.signalBoost.emissionID === emissionID)
      emission.signalBoost = {
        ...emission.signalBoost,
        ...newData,
      };

    if (emission.replyEmission) {
      if (emission.replyEmission.emissionID === emissionID)
        emission.replyEmission = {
          ...emission.replyEmission,
          ...newData,
        };
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.emissionID === emissionID
      )
        emission.replyEmission.signalBoost = {
          ...emission.replyEmission.signalBoost,
          ...newData,
        };

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.emissionID === emissionID)
          emission.replyEmission.replyEmission = {
            ...emission.replyEmission.replyEmission,
            ...newData,
          };
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.emissionID ===
            emissionID
        )
          emission.replyEmission.replyEmission.signalBoost = {
            ...emission.replyEmission.replyEmission.signalBoost,
            ...newData,
          };
      }
    }
    return emission;
  });

/**
 *
 * @param {String} html - HTML string
 *
 * If an html string that is not properly parsed (class names wrong, etc), this function will fix it
 *
 * @returns HTML string that is properly parsed
 */
h.parseStrayTags = (html) => {
  if (html.includes("卐")) {
    alert("Swastikas are not allowed");
    return "<p><br></p>";
  }
  html = html.replace(/[\u200B-\u200F\uFEFF]/g, "");
  let parsedHTML = parse(html);

  // Regular links
  Array.from(parsedHTML.getElementsByTagName("a"))
    .filter((link) => ["#", "@"].indexOf(link.textContent[0]) === -1)
    .forEach(
      (link) =>
        (link.textContent = `卐卐卐卐${link.getAttribute("href")}卐 卐卐${
          link.textContent
        }卐卐 卐`)
    ); // Swastikas are illegal characters. That's why I use them to parse links
  let updatedHTML = parsedHTML.textContent;
  updatedHTML = updatedHTML.split("卐卐卐卐");

  for (let u = 0; u < updatedHTML.length; u++) {
    const slice = updatedHTML[u];
    if (slice.includes("卐 卐卐")) {
      const href = slice.split("卐 卐卐")[0];
      const text = slice.split("卐 卐卐")[1].split("卐卐 卐")[0];
      updatedHTML[
        u
      ] = `<a class="text-blue text-decoration-none" href="${href}">${text}</a>${
        slice.split("卐卐 卐")[1]
      }`;
    }
  }
  updatedHTML = updatedHTML.join("");

  updatedHTML = updatedHTML.split("");
  // Hashtags/mentions
  updatedHTML.forEach((char, c) => {
    if (
      char === "#" &&
      updatedHTML[c + 1] &&
      ["@", "#", "\n", " "].indexOf(updatedHTML[c + 1]) === -1
    ) {
      updatedHTML[c] = "卐卐HASH卐";
      let index = c + 1;
      let endFound = false;
      while (!endFound) {
        if (
          !updatedHTML[index + 1] ||
          ["@", "#", "\n", " "].indexOf(updatedHTML[index + 1]) > -1
        ) {
          updatedHTML[index] = updatedHTML[index] + "卐HASH卐卐";
          endFound = true;
        }
        index++;
      }
    }
    if (
      char === "@" &&
      updatedHTML[c + 1] &&
      ["@", "#", "\n", " "].indexOf(updatedHTML[c + 1]) === -1
    ) {
      updatedHTML[c] = "卐卐MENTION卐";
      let index = c + 1;
      let endFound = false;
      while (!endFound) {
        if (
          !updatedHTML[index + 1] ||
          ["@", "#", "\n", " "].indexOf(updatedHTML[index + 1]) > -1
        ) {
          updatedHTML[index] = updatedHTML[index] + "卐MENTION卐卐";
          endFound = true;
        }
        index++;
      }
    }
  });

  updatedHTML = updatedHTML.join("");

  updatedHTML = updatedHTML.split("卐卐HASH卐");
  for (let u = 0; u < updatedHTML.length; u++) {
    const slice = updatedHTML[u];
    if (slice.includes("卐HASH卐卐")) {
      const text = slice.split("卐HASH卐卐")[0];
      updatedHTML[
        u
      ] = `<a class="text-secondary" href="/tag/${text}">#${text}</a>${
        slice.split("卐HASH卐卐")[1]
      }`;
    }
  }
  updatedHTML = updatedHTML.join("");

  updatedHTML = updatedHTML.split("卐卐MENTION卐");
  for (let u = 0; u < updatedHTML.length; u++) {
    const slice = updatedHTML[u];
    if (slice.includes("卐MENTION卐卐")) {
      const text = slice.split("卐MENTION卐卐")[0];
      updatedHTML[u] = `<a class="text-success" href="/${text}">@${text}</a>${
        slice.split("卐MENTION卐卐")[1]
      }`;
    }
  }
  updatedHTML = updatedHTML.join("");
  return updatedHTML;
};

/**
 *
 * @param {String} html - HTML string
 * @returns HTML with only approved tags, classes, and attributes
 */
h.sanitizeHTML = (html) => {
  while (html.split("<p><br></p><p><br></p>").length > 1)
    html = html.split("<p><br></p><p><br></p>").join("<p><br></p>");
  html = h.parseStrayTags(html);
  while (html.split("  ").length > 1) html = html.split("  ").join(" ");
  while (html.split("\n\n").length > 1) html = html.split("\n\n").join("\n");
  while (html.split(" \n").length > 1) html = html.split(" \n").join("\n");
  const clean = sanitize(html, {
    allowedTags: ["a", "br", "p", "div", "span"],
    allowedAttributes: {
      a: ["href", "class"],
      br: [],
      p: [],
      div: [],
      span: [],
    },
    allowedClasses: {
      a: [
        "text-success",
        "text-secondary",
        "text-blue",
        "text-decoration-none",
        "ql-mention",
        "ql-hashtag",
      ],
      br: [],
      p: [],
      div: [],
      span: [],
    },
  });
  return clean;
};

/**
 *
 * @param {Array} emissions - List of emissions
 * @param {Number} emissionID - ref Emissions.emissionID
 * @returns List of emissions with all emission with emissionID flagged as voted
 */
h.setVoted = (emissions, emissionID) =>
  emissions.map((emission) => {
    if (emission.emissionID === emissionID)
      emission = {
        ...emission,
        pollData: {
          ...emission.pollData,
          voted: true,
        },
      };
    if (emission.signalBoost && emission.signalBoost.emissionID === emissionID)
      emission.signalBoost = {
        ...emission.signalBoost,
        pollData: {
          ...emission.signalBoost.pollData,
          voted: true,
        },
      };

    if (emission.replyEmission) {
      if (emission.replyEmission.emissionID === emissionID)
        emission.replyEmission = {
          ...emission.replyEmission,
          pollData: {
            ...emission.replyEmission.pollData,
            voted: true,
          },
        };
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.emissionID === emissionID
      )
        emission.replyEmission.signalBoost = {
          ...emission.replyEmission.signalBoost,
          pollData: {
            ...emission.replyEmission.signalBoost.pollData,
            voted: true,
          },
        };

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.emissionID === emissionID)
          emission.replyEmission.replyEmission = {
            ...emission.replyEmission.replyEmission,
            pollData: {
              ...emission.replyEmission.replyEmission.pollData,
              voted: true,
            },
          };
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.emissionID ===
            emissionID
        )
          emission.replyEmission.replyEmission.signalBoost = {
            ...emission.replyEmission.replyEmission.signalBoost,
            pollData: {
              ...emission.replyEmission.replyEmission.signalBoost.pollData,
              voted: true,
            },
          };
      }
    }
    return emission;
  });

/**
 *
 * @param {Array} emissions - List of emissions
 * @param {Object} newEmission - Emissions document
 * @param {Object} userInfo - Users document
 * @returns Emissions array with the emission with newEmission.emissionID replaced
 */
h.updateEmission = (emissions, newEmission, userInfo) => {
  return emissions
    .map((emission) => {
      if (emission.emissionID === newEmission.emissionID)
        emission = {
          ...emission,
          ...h.setMetadata(newEmission, userInfo),
        };
      if (
        emission.signalBoost &&
        emission.signalBoost.emissionID === newEmission.emissionID
      )
        emission.signalBoost = {
          ...emission.signalBoost,
          ...h.setMetadata(newEmission, userInfo),
        };

      if (emission.replyEmission) {
        if (emission.replyEmission.emissionID === newEmission.emissionID)
          emission.replyEmission = {
            ...emission.replyEmission,
            ...h.setMetadata(newEmission, userInfo),
          };
        if (
          emission.replyEmission.signalBoost &&
          emission.replyEmission.signalBoost.emissionID ===
            newEmission.emissionID
        )
          emission.replyEmission.signalBoost = {
            ...emission.replyEmission.signalBoost,
            ...h.setMetadata(newEmission, userInfo),
          };

        if (emission.replyEmission.replyEmission) {
          if (
            emission.replyEmission.replyEmission.emissionID ===
            newEmission.emissionID
          )
            emission.replyEmission.replyEmission = {
              ...emission.replyEmission.replyEmission,
              ...h.setMetadata(newEmission, userInfo),
            };
          if (
            emission.replyEmission.replyEmission.signalBoost &&
            emission.replyEmission.replyEmission.signalBoost.emissionID ===
              newEmission.emissionID
          )
            emission.replyEmission.replyEmission.signalBoost = {
              ...emission.replyEmission.replyEmission.signalBoost,
              ...h.setMetadata(newEmission, userInfo),
            };
        }
      }

      return emission;
    })
    .sort((a, b) => b.emissionID - a.emissionID);
};

/**
 *
 * @param {Number} value - Any number
 *
 * Shortens numbers by compiling them
 * i.e. 1000 bytes -> 1KB
 * 10000 bytes -> 10KB
 *
 *
 * @returns The compiled number
 */
h.compiledNumber = (value) => {
  if (!h.isNumeric(String(value))) return "";
  value = Number(value);
  if (value > 1000000000) return String((value / 1000000000).toFixed(1)) + "B";
  else if (value > 1000000) return String((value / 1000000).toFixed(1)) + "M";
  else if (value > 1000) return String((value / 1000).toFixed(1)) + "K";
  return value;
};

export default h;
