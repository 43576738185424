import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Feed from "./pages/Feed";
import React, { useEffect } from "react";
import {
  set_history,
  toggle_go_live_modal,
  set_go_live_modal,
  set_captcha_ready,
} from "./redux/actions";
import { connect } from "react-redux";
import Delete from "./pages/Delete";
import Number from "./pages/Number";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Resets from "./pages/Resets";
import Cancel from "./pages/Cancel";
import Message from "./pages/Message";
import CreateAccount from "./pages/CreateAccount";
import SideNav from "./components/SideNav";
import Notifications from "./pages/Notifications";
import Messages from "./pages/Messages";
import UserBar from "./components/UserBar";
import GoLiveModal from "./components/goLiveModal/GoLiveModal";
import Emission from "./pages/Emission";
import Reports from "./pages/Reports";
import ModLogs from "./pages/ModLogs";
import Tag from "./pages/Tag";
import Search from "./pages/Search";
import Validate from "./pages/Validate";
import Info from "./pages/Info";
import Contact from "./pages/Contact";
import ValidateEmail from "./pages/ValidateEmail";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import GlobalEmissionInput from "./components/globalEmissionInput/GlobalEmissionInput";
import PollVotersModal from "./components/PollVotersModal";
import Profile from "./pages/Profile";
import axios from "axios";
import DeleteCancel from "./pages/DeleteCancel";

const App = (props) => {
  let location = useLocation();
  let history = useHistory();
  let theme =
    props.userInfo.userSettings.theme === "default"
      ? createTheme({
          palette: {
            primary: {
              main: process.env.REACT_APP_COLOR_PRIMARY,
            },
            secondary: {
              main: process.env.REACT_APP_COLOR_SECONDARY,
            },
            info: {
              main: process.env.REACT_APP_COLOR_INFO,
            },
            warning: {
              main: process.env.REACT_APP_COLOR_WARNING,
            },
            danger: {
              main: process.env.REACT_APP_COLOR_DANGER,
            },
            success: {
              main: process.env.REACT_APP_COLOR_SUCCESS,
            },
            light: {
              main: process.env.REACT_APP_COLOR_LIGHT,
            },
            dark: {
              main: process.env.REACT_APP_COLOR_DARK,
            },
          },
        })
      : createTheme({
          palette: {
            primary: {
              main: process.env.REACT_APP_COLOR_PRIMARY_DARK,
            },
            secondary: {
              main: process.env.REACT_APP_COLOR_SECONDARY_DARK,
            },
            info: {
              main: process.env.REACT_APP_COLOR_INFO_DARK,
            },
            warning: {
              main: process.env.REACT_APP_COLOR_WARNING_DARK,
            },
            danger: {
              main: process.env.REACT_APP_COLOR_DANGER_DARK,
            },
            success: {
              main: process.env.REACT_APP_COLOR_SUCCESS_DARK,
            },
            light: {
              main: process.env.REACT_APP_COLOR_LIGHT_DARK,
            },
            dark: {
              main: process.env.REACT_APP_COLOR_DARK_DARK,
            },
          },
        });

  useEffect(() => {
    document.addEventListener("onautocomplete", (e) => {
      if (e.target.classList.contains("form-control"))
        e.target.classList.add("active");
    });
    window.grecaptcha.enterprise.ready(props.set_captcha_ready);
  }, []);

  useEffect(() => {
    const splash = () =>
      axios
        .get("/auth/splash")
        .then((res) => {
          props.set_history(location, history, res.data.userInfo); // Set history into redux store as soon as app is rendered on the client
        })
        .catch((err) => console.log("splash error", err));
    if (!window.location.pathname.includes("/delete/")) splash();
    else props.set_history(location, history);
  }, [props.userInfo._id]);

  useEffect(() => {
    theme =
      props.userInfo.userSettings.theme === "default"
        ? createTheme({
            palette: {
              primary: {
                main: process.env.REACT_APP_COLOR_PRIMARY,
              },
              secondary: {
                main: process.env.REACT_APP_COLOR_SECONDARY,
              },
              info: {
                main: process.env.REACT_APP_COLOR_INFO,
              },
              warning: {
                main: process.env.REACT_APP_COLOR_WARNING,
              },
              danger: {
                main: process.env.REACT_APP_COLOR_DANGER,
              },
              success: {
                main: process.env.REACT_APP_COLOR_SUCCESS,
              },
              light: {
                main: process.env.REACT_APP_COLOR_LIGHT,
              },
              dark: {
                main: process.env.REACT_APP_COLOR_DARK,
              },
            },
          })
        : createTheme({
            palette: {
              primary: {
                main: process.env.REACT_APP_COLOR_PRIMARY_DARK,
              },
              secondary: {
                main: process.env.REACT_APP_COLOR_SECONDARY_DARK,
              },
              info: {
                main: process.env.REACT_APP_COLOR_INFO_DARK,
              },
              warning: {
                main: process.env.REACT_APP_COLOR_WARNING_DARK,
              },
              danger: {
                main: process.env.REACT_APP_COLOR_DANGER_DARK,
              },
              success: {
                main: process.env.REACT_APP_COLOR_SUCCESS_DARK,
              },
              light: {
                main: process.env.REACT_APP_COLOR_LIGHT_DARK,
              },
              dark: {
                main: process.env.REACT_APP_COLOR_DARK_DARK,
              },
            },
          });
  }, [props.userInfo.userSettings.theme]);

  /**
   * Currently using React Router 5
   * React Router 6 recently released; stupidly not made backwards-compatible and removes numerous features with no replacement
   */

  return (
    /**
     * AnimatePresence tells the child components to use framer motion page transitions
     * exitBeforeEnter flag makes sure the exit transition completes before the entrance on the next page initiates
     * The "key" in the Switch is necessary for animations to work properly. Needs to be unique on every page change, hence setting it to location.pathname
     */
    <ThemeProvider theme={theme}>
      <div className="h-100 position-relative">
        <div className="h-100 d-flex flex-column scroll">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/">
                <Feed />
              </Route>
              <Route exact path="/n/:number">
                <Number />
              </Route>
              <Route exact path="/number/:number">
                <Number />
              </Route>
              <Route exact path="/search">
                <Search />
              </Route>
              <Route exact path="/info">
                <Info />
              </Route>
              <Route exact path="/logs">
                <ModLogs />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/set-password/:id">
                <Resets />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/tag/:tag">
                <Tag />
              </Route>
              <Route exact path="/delete/:id">
                <Delete />
              </Route>
              <Route exact path="/delete-cancel/:id">
                <DeleteCancel />
              </Route>
              <Route exact path="/cancel/:id">
                <Cancel />
              </Route>
              <Route exact path="/check-email">
                <Message message="An email has been sent with a link to reset your password." />
              </Route>
              <Route exact path="/awaiting-approval-email">
                <Message
                  message={`Your account is awaiting approval. An email has been sent from ${process.env.REACT_APP_VERIFICATION_EMAIL} to verify your email address. Please click the link in this email to verify your account.`}
                />
              </Route>
              <Route exact path="/awaiting-approval">
                <Message
                  message={`Your account is awaiting approval. Please check back later.`}
                />
              </Route>
              <Route exact path="/received">
                <Message
                  message={`Your message has been recieved. We will get back to you shortly.`}
                />
              </Route>
              <Route exact path="/validate-email">
                <ValidateEmail />
              </Route>
              <Route exact path="/create-account">
                <CreateAccount />
              </Route>
              <Route exact path="/messages">
                <Messages />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/reports">
                <Reports />
              </Route>
              <Route exact path="/notifications">
                <Notifications />
              </Route>
              <Route exact path="/not-found">
                <Message message="Not Found" />
              </Route>
              <Route exact path="/e/:emissionID">
                <Emission />
              </Route>
              <Route exact path="/verify/:id">
                <Validate />
              </Route>
              <Route exact path="/null">
                <Redirect to="/" />
              </Route>
              <Route exact path="/:profile">
                <Profile />
              </Route>
              <Route path="*">
                <Redirect to="/not-found" />
              </Route>
            </Switch>
          </AnimatePresence>
        </div>
        {typeof window !== "undefined" &&
        [
          "/login",
          "/create-account",
          "/forgot-password",
          "/validate-email",
          "/received",
          "/awaiting-approval",
          "/awaiting-approval-email",
          "/messages",
        ].indexOf(window.location.pathname) === -1 ? (
          <GlobalEmissionInput />
        ) : (
          <></>
        )}
        <SideNav loggedIn={props.loggedIn} />
        <UserBar key={props.userInfo.username} />
        {typeof window !== "undefined" ? (
          <GoLiveModal
            modalShown={props.goLiveModalShown}
            setShowModal={props.set_go_live_modal}
            toggleShowModal={props.toggle_go_live_modal}
          />
        ) : (
          <></>
        )}
        <PollVotersModal />
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_history,
  toggle_go_live_modal,
  set_go_live_modal,
  set_captcha_ready,
})(App);
