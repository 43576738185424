import React from "react";
import { motion } from "framer-motion";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import {
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Spinner from "../../../components/Spinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

// Reasons start
const reasons = [
  {
    id: "spam",
    label: "Spam",
  },
  {
    id: "porn",
    label: "Porn",
  },
  {
    id: "fed",
    label: "Illegal/Fedposting",
  },
  {
    id: "other",
    label: "Other",
  },
];
// Reasons end

class Ban extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the user is in the process of banning the profile
       * reason: String - Reason for the ban
       * input: Object - The text input data (values, errors, etc)
       * duration: String - Ban duration
       */
      working: false,
      reason: reasons[0].id,
      input: {
        id: "ban-input",
        error: "",
        invalid: false,
        value: "",
      },
      duration: "day",
    };
  }

  /**
   * Fix mdb inputs
   */
  componentDidMount() {
    setTimeout(this.fixMDBInputs, 250);
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        input: {
          ...this.state.input,
          value: e.target.value,
        },
      },
      () => {
        if (this.state.input.value.length > 5000)
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: true,
                error: "Too long (Max: 5000 chars)",
              },
            },
            () =>
              document
                .getElementById("ban-input")
                .setCustomValidity(this.state.input.error)
          );
        else
          this.setState(
            {
              ...this.state,
              input: {
                ...this.state.input,
                invalid: false,
                error: "",
              },
            },
            () => document.getElementById("ban-input").setCustomValidity("")
          );
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Notify user that the profile has been banned
   * Reset the form
   * Update the profile
   */
  submit = () => {
    document.getElementById("ban-form").classList.add("was-validated");
    if (this.state.input.value.length > 5000)
      document.getElementById("ban-input").setCustomValidity("hello");
    if (!this.state.working && this.state.input.value.length <= 5000)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          try {
            if (this.props.report.type === "emission") {
              const banSubmission = {
                details: this.state.input.value,
                reason: this.state.reason,
                userID: this.props.report.emission.userID,
                duration: this.state.duration,
              };
              axios
                .post("/support/users/ban", banSubmission)
                .then(() => {
                  const emissionSubmission = {
                    details: this.state.input.value,
                    reason: this.state.reason,
                    emissionID: this.props.report.emissionID,
                  };
                  axios
                    .post("/support/emissions/remove", emissionSubmission)
                    .then(() => {
                      this.props.purgeReports({
                        type: "emission",
                        text: (
                          <>
                            Removed{" "}
                            <span className="text-capitalize">
                              {process.env.REACT_APP_EMISSION_NAME}
                            </span>{" "}
                            <span className="text-pkmn">
                              #{this.props.report.emissionID}
                            </span>{" "}
                            and Banned @{this.props.report.emission.username}
                          </>
                        ),
                        icon: <i className="fas fa-gavel me-2 text-danger"></i>,
                        ban: true,
                        user_id: this.props.report.emission.user_id,
                        emissionID: this.props.report.emissionID,
                      });
                    })
                    .catch((err) =>
                      this.setState(
                        {
                          ...this.state,
                          working: false,
                        },
                        () => {
                          console.log(err);
                          alert("An error occurred. Please try again later.");
                        }
                      )
                    );
                })
                .catch((err) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      console.log(err);
                      alert("An error occurred. Please try again later.");
                    }
                  )
                );
            } else {
              const submission = {
                details: this.state.input.value,
                reason: this.state.reason,
                userID: this.props.report.userInfo._id,
                duration: this.state.duration,
              };
              axios
                .post("/support/users/ban", submission)
                .then(() =>
                  this.props.purgeReports({
                    type: "user",
                    user_id: this.props.report.user_id,
                    text: `@${this.props.report.userInfo.username} has been banned`,
                    icon: <i className="fas fa-gavel me-2 text-danger"></i>,
                    ban: true,
                    poster: this.props.report.userInfo._id,
                  })
                )
                .catch((err) =>
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => {
                      console.log(err);
                      alert("An error occurred. Please try again later.");
                    }
                  )
                );
            }
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  reasonChange = (e) =>
    this.setState({
      ...this.state,
      reason: e.target.value,
    });

  durationChange = (e) =>
    this.setState({
      ...this.state,
      duration: e.target.value,
    });

  actions = () => {
    if (!this.state.working) this.props.setView("reports");
  };

  render() {
    return (
      <motion.div
        className="mt-4"
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <div className="d-flex">
          <MDBBtn onClick={this.actions} color="link" rippleColor="primary">
            <i className="fas fa-chevron-left me-2"></i>
            Actions
          </MDBBtn>
          {this.state.working ? (
            <MDBBtn disabled color="danger">
              <Spinner size="sm" className="me-2" />
              {this.props.report.type === "emission"
                ? "Removing and Banning"
                : "Banning"}
            </MDBBtn>
          ) : (
            <MDBBtn onClick={this.submit} color="danger">
              <i className="far fa-check-circle me-2"></i>
              {this.props.report.type === "emission"
                ? "Confirm Remove and Ban"
                : "Confirm Ban"}
            </MDBBtn>
          )}
        </div>
        <hr></hr>
        <div className="d-flex details-modal-ban-options">
          <div className="d-flex justify-content-between">
            <div className="px-4">
              <FormControl>
                <h5 className="fs-5 display-6 m-0">Reason</h5>
                <hr className="my-1" />
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={this.state.reason}
                  name="radio-buttons-group"
                  onChange={this.reasonChange}
                >
                  {reasons.map((reason) => (
                    <FormControlLabel
                      key={reason.id}
                      value={reason.id}
                      control={<Radio />}
                      label={reason.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            <div className="px-4">
              <FormControl>
                <h5 className="fs-5 display-6 m-0">Duration</h5>
                <hr className="my-1" />
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={this.state.duration}
                  name="radio-buttons-group"
                  onChange={this.durationChange}
                >
                  <FormControlLabel
                    key="day"
                    value="day"
                    control={<Radio />}
                    label="1 Day"
                  />
                  <FormControlLabel
                    key="week"
                    value="week"
                    control={<Radio />}
                    label="1 Week"
                  />
                  <FormControlLabel
                    key="month"
                    value="month"
                    control={<Radio />}
                    label="1 Month"
                  />
                  <FormControlLabel
                    key="permanent"
                    value="permanent"
                    control={<Radio />}
                    label="Permanent"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="flex-grow-1">
            <MDBValidation
              className="h-100"
              method="dialog"
              id="ban-form"
              name="ban-form"
            >
              <MDBValidationItem
                className="pb-4 h-100 h-100-child"
                feedback={this.state.input.error}
                invalid={true}
              >
                <MDBTextArea
                  name="ban-input"
                  onChange={this.changeHandler}
                  id="ban-input"
                  label="Details"
                  size="lg"
                  className={`h-100 ${!this.state.input.invalid ? "mb-0" : 0}`}
                />
              </MDBValidationItem>
            </MDBValidation>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default Ban;
