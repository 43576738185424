import React from 'react';
import Details from './Details';
import Ban from './Ban';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';

class Form extends React.Component{
    constructor(props){
        super();
        this.state = {

        }
        this.form = props.form;
    }

    componentDidMount(){
        this.submit = this.submit.bind(this);
        this.props.setSubmit(this.submit);
    }

    render(){
        switch(this.form){
            case 'details':
                return (
                    <motion.div className="pt-4" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                        <Details 
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            setSubmit={click => this.submit = click} 
                            modalShown={this.props.modalShown}
                            setShowModal={this.props.setShowModal}
                            toggleShowModal={this.props.toggleShowModal}
                            setWorking={this.props.setWorking}
                            notify={this.props.notify}
                            working={this.props.working}
                        />
                    </motion.div>
                    
                );
            case 'ban':
                return (
                    <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                        <Ban 
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            setSubmit={click => this.submit = click} 
                            modalShown={this.props.modalShown}
                            setShowModal={this.props.setShowModal}
                            toggleShowModal={this.props.toggleShowModal}
                            setWorking={this.props.setWorking}
                            reset={this.props.reset}
                            notify={this.props.notify}
                        />
                    </motion.div>
                );
            default:
                console.log('oob form', this.form);
                return <></>
        }
    }
}

export default Form;