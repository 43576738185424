import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBTooltip,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import Spinner from "../Spinner";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import axios from "axios";
import PollExpiration from "../PollExpiration";
import { v4 as uuid } from "uuid";

class Emission extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * showRemoveDetails: Boolean - Whether the MDBCollapse showing the emission removal details is open
       * highlightRemoved: Boolean - Whether the green card background has been removed
       */
      showRemoveDetails: false,
      highlightRemoved: false,
      pollReset: false,
      pollCheck: false,
      rippleDuration: 500,
    };
    this.rippleID = uuid();
  }

  // Set highlight to be removed after 1.5 seconds
  componentDidMount() {
    setTimeout(this.removeHighlight, 1500);
    this.setState({
      ...this.state,
      pollCheck: setInterval(this.pollCheck, 1000),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.pollCheck);
  }

  pollCheck = () => {
    if (
      !this.state.pollReset &&
      this.props.emission.pollData &&
      this.props.emission.pollData.expirationDate &&
      new Date().getTime() >
        new Date(this.props.emission.pollData.expirationDate)
    )
      this.setState({
        ...this.state,
        pollReset: true,
      });
  };

  /**
   * Removes the highlight
   *
   */
  removeHighlight = () =>
    this.setState({
      ...this.state,
      highlightRemoved: true,
    });

  /**
   *
   * @param {Click Event} e
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks an emission id
   *
   * If the emission is already on the page, scroll to it
   * Else, route to /e/emissionID
   */
  scrollToEmission = (e, emissionID) => {
    e.preventDefault();
    const emission = document.getElementById(`emission-${emissionID}`);
    if (emission) emission.scrollIntoView();
    else this.props.route(`/e/${emissionID}`);
  };

  /**
   * Toggles the display of removal details
   */
  toggleRemoveDetails = () =>
    this.setState({
      ...this.state,
      showRemoveDetails: !this.state.showRemoveDetails,
    });

  /**
   * @param {Click Event} e
   *
   * Triggered when the user clicks a profile
   *
   * If the user is already on that profile page, scroll to the top of the feed
   * Else route to that profile page
   */
  clickProfile = (e) => {
    e.preventDefault();
    if (
      window.location.pathname.split("/")[1].split("?")[0].toLowerCase() ===
      this.props.emission.username.toLowerCase()
    ) {
      document.getElementById("feed-top").scrollIntoView();
    } else {
      this.props.route(`/${this.props.emission.username}`);
    }
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = () => {
    if (this.props.highlight && !this.state.highlightRemoved)
      return "bg-litegreen scale-minor-hover";
    else if (this.props.emission.isBlocked)
      return "bg-litepurple scale-minor-hover";
    else if (this.props.emission.remove.removed)
      return "bg-litepink scale-minor-hover";
    else if (this.props.emission.blocksMe)
      return "bg-litepurple scale-minor-hover";
    else if (this.props.emission.private)
      return "bg-liteteal scale-minor-hover";
    else return "scale-minor-hover";
  };

  /**
   *
   * Special emissions have gold numbers.
   * The following Emission emissionIDs are special:
   * * Emission 1
   * * Any emission over 10 with all the same numbers (i.e. 666)
   * * Any emission over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the emission is a special emission
   */
  checkSpecialEmission = () => {
    if (this.props.emission.emissionID === 1) return true;
    const split = String(this.props.emission.emissionID).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   * Triggered when the user removes or restores their own emission
   *
   * Removes/restores the emission
   * Updates the emission
   */
  removeRestore = () => {
    if (!this.state.removing)
      this.setState(
        {
          ...this.state,
          removing: true,
        },
        () =>
          axios
            .get(
              `/emissions/remove-restore-self/${this.props.emission.emissionID}`
            )
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  removing: false,
                },
                () => this.props.updateEmission(res.data.emission)
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  removing: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user pins or unpins their own emission
   *
   * Pins/unpins the emission
   * Updates the emission
   */
  togglePin = () => {
    if (!this.state.pinning)
      this.setState(
        {
          ...this.state,
          pinning: true,
        },
        () =>
          axios
            .get(`/emissions/pin-unpin/${this.props.emission.emissionID}`)
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  pinning: false,
                },
                () => {
                  if (this.props.handlePin)
                    this.props.handlePin(res.data.emission);
                  else
                    this.props.updateEmission(h.setMetadata(res.data.emission));
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  pinning: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  selectPollOption = (option) => {
    this.props.setPollModal({
      shown: true,
      voters: this.props.emission.pollData.voters.filter(
        (voter) => voter.votes.indexOf(option.id) > -1
      ),
      option: option.option,
      emissionID: this.props.emission.emissionID,
    });
  };

  clickElsewhere = (e) => {
    e.stopPropagation();
    if (!e.target.classList.contains("no-route")) {
      console.log("route", e.target);
      this.props.route(`/e/${this.props.emission.emissionID}`);
    }
  };

  render() {
    const boostInfo = this.props.userInfo.boosts.find(
      (boost) => boost.boostID === this.props.emission.emissionID
    );
    return (
      <motion.div
        id={`emission-${this.props.emission.emissionID}${
          this.props.signalBoosted ? "-signalboost" : ""
        }`}
        className={`${this.props.index ? "" : "mt-4"} pb-2`}
        key={this.props.emission._id}
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={
          this.props.loaded ? this.props.animation || t.fade_out : t.normalize
        }
        onClick={(e) => {
          this.clickElsewhere(e);
        }}
      >
        <MDBCard
          id={`card-${this.props.emission.emissionID}`}
          style={{ marginBottom: "0.75rem" }}
          className={`${
            this.props.signalBoosted ? "shadow-5-strong" : "shadow-3"
          } ${this.getCardBackground()} ${
            this.props.highlight && !this.state.highlightRemoved
              ? "transition-1"
              : "transition-25"
          } cursor-pointer emission-cards`}
        >
          <MDBCardBody>
            {this.props.highlight ? (
              <>
                <h5
                  id={`highlight-${this.props.emission.emissionID}`}
                  className={`text-${
                    this.state.highlightRemoved ? "success" : "light"
                  } transition-1`}
                >
                  <i className="fas fa-highlighter me-2"></i>
                  Highlighted{" "}
                  <span className="text-capitalize">
                    {process.env.REACT_APP_EMISSION_NAME}
                  </span>
                </h5>
                <hr></hr>
              </>
            ) : (
              <>
                {this.props.signalBoosted ? (
                  <>
                    <h5 className="text-blusteel">
                      <i className="fas fa-retweet me-2 text-danger"></i>
                      <span className="text-capitalize">
                        {process.env.REACT_APP_EMISSION_NAME}{" "}
                      </span>
                      <span className="text-capitalize">
                        {process.env.REACT_APP_SIGNALBOOST_PAST}
                      </span>
                    </h5>
                    <hr></hr>
                  </>
                ) : (
                  <>
                    {this.props.emission.pinned && this.props.showPin ? (
                      <>
                        <h5 className="text-blusteel">
                          <i className="fas fa-thumbtack me-2 text-primary"></i>
                          Pinned
                        </h5>
                        <hr></hr>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
            <div className="d-flex">
              <div
                style={{ width: 0 }}
                className="d-flex emission-headers flex-grow-1"
              >
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    this.clickProfile(e);
                  }}
                  className="h-max-content w-max-content no-route"
                  to={`/${this.props.emission.username}`}
                >
                  <MDBRipple className="no-route" rippleColor="primary">
                    <div className="d-flex justify-content-center align-items-center square-4 cursor-pointer no-route">
                      <div
                        className="fit-images rounded-circle fit-round no-route"
                        style={{
                          backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${this.props.emission.avatar.thumbnail}")`,
                        }}
                      ></div>
                    </div>
                  </MDBRipple>
                </Link>
                <div style={{ width: 0 }} className="flex-grow-1 px-2">
                  <div className="d-flex emission-name-info align-items-center">
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        this.clickProfile(e);
                      }}
                      className="max-w-max-content d-block no-route"
                      to={`/${this.props.emission.username}`}
                    >
                      <h5 className="mb-0 text-default mx-0 text-break no-route">
                        {h.getBadge(this.props.emission, "me-2 badge-h5")}
                        {this.props.emission.displayName}
                      </h5>
                    </Link>
                    {this.props.emission.signalBoost ? (
                      <small className="text-blusteel">
                        <Link
                          onClick={(e) => {
                            e.stopPropagation();
                            this.scrollToEmission(
                              e,
                              this.props.emission.replyID
                            );
                          }}
                          className="text-blusteel no-route"
                          to={`/e/${this.props.emission.signalBoost.emissionID}`}
                        >
                          {this.props.emission.html &&
                          this.props.emission.html !== "<p><br></p>" ? (
                            <>
                              <i className="fas fa-quote-left me-2 ms-4"></i>
                              Quote Replying to #
                              <span className="text-pkmn">
                                {this.props.emission.signalBoost.emissionID}
                              </span>
                            </>
                          ) : (
                            <>
                              <i className="fas fa-retweet me-2 ms-4"></i>
                              <span className="text-capitalize">
                                {process.env.REACT_APP_SIGNALBOOST_CURRENT}{" "}
                              </span>
                              #
                              <span className="text-pkmn">
                                {this.props.emission.signalBoost.emissionID}
                              </span>
                            </>
                          )}
                        </Link>
                      </small>
                    ) : (
                      <></>
                    )}
                    {this.props.emission.replyID ? (
                      <small>
                        <Link
                          to={`/e/${this.props.emission.replyID}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.scrollToEmission(
                              e,
                              this.props.emission.replyID
                            );
                          }}
                          className="text-blusteel no-route"
                        >
                          <i className="fas fa-reply me-2 ms-4"></i>
                          Replying to #
                          <span className="text-pkmn">
                            {this.props.emission.replyID}
                          </span>
                        </Link>
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      this.clickProfile();
                    }}
                    className="max-w-max-content d-block no-route"
                    to={`/${this.props.emission.username}`}
                  >
                    <p className="text-blusteel mx-0 text-break">
                      @{this.props.emission.username}
                    </p>
                  </Link>
                  {h.getMediaFromHtml(this.props.emission.html)}
                  <div
                    className="emission-text-regular no-route"
                    dangerouslySetInnerHTML={{
                      __html: this.props.emission.html,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.clickEmissionBody(
                        e,
                        this.props.emission.emissionID
                      );
                    }}
                  ></div>
                </div>
              </div>
              <div className="max-w-33">
                <h5
                  className={`text-end mb-0 d-block ms-auto pe-2 text-nowrap no-route`}
                >
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.route(`/e/${this.props.emission.emissionID}`);
                    }}
                    to={`/e/${this.props.emission.emissionID}`}
                    className="no-route"
                  >
                    <span
                      className={`text-pkmn text-${
                        this.checkSpecialEmission() ? "gold" : "default"
                      }`}
                    >
                      #{this.props.emission.emissionID}
                    </span>
                  </Link>
                </h5>
                {this.props.emission.remove.removed ? (
                  <div className="ms-auto w-max-content">
                    {this.props.emission.remove.user.userID ===
                    this.props.userInfo._id ? (
                      <>
                        {this.props.emission.remove.details ? (
                          <>
                            {h.checkJanny(this.props.userInfo) ? (
                              <MDBBtn
                                rippleColor="danger"
                                onClick={(e) => {
                                  this.toggleRemoveDetails();
                                }}
                                color="link"
                                className="text-danger p-1 text-end no-route"
                              >
                                You removed this{" "}
                                {process.env.REACT_APP_EMISSION_NAME}
                                {this.props.emission.remove.reason
                                  ? ` (${this.props.emission.remove.reason})`
                                  : ""}
                              </MDBBtn>
                            ) : (
                              <p className="text-danger ms-1 mb-0 text-btn pe-2 text-end text-break no-route">
                                You removed this{" "}
                                {process.env.REACT_APP_EMISSION_NAME}
                                {this.props.emission.remove.reason
                                  ? ` (${this.props.emission.remove.reason})`
                                  : ""}
                              </p>
                            )}
                          </>
                        ) : (
                          <p className="text-danger ms-1 mb-0 text-btn text-end text-break pe-2 no-route">
                            You removed this{" "}
                            {process.env.REACT_APP_EMISSION_NAME}
                            {this.props.emission.remove.reason
                              ? ` (${this.props.emission.remove.reason})`
                              : ""}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {this.props.emission.remove.details ? (
                          <>
                            {h.checkJanny(this.props.userInfo) ? (
                              <MDBBtn
                                rippleColor="danger"
                                onClick={(e) => {
                                  this.toggleRemoveDetails();
                                }}
                                color="link"
                                className="text-danger p-1 text-end no-route"
                              >
                                {process.env.REACT_APP_EMISSION_NAME} removed
                                {this.props.emission.remove.reason
                                  ? `: ${this.props.emission.remove.reason}`
                                  : "by jizzer"}
                              </MDBBtn>
                            ) : (
                              <p className="text-danger ms-1 mb-0 text-btn pe-2 text-end text-break no-route">
                                {process.env.REACT_APP_EMISSION_NAME} removed
                                {this.props.emission.remove.reason
                                  ? `: ${this.props.emission.remove.reason}`
                                  : ` by @${this.props.emission.username}`}
                              </p>
                            )}
                          </>
                        ) : (
                          <p className="text-danger ms-1 mb-0 text-btn text-end text-break pe-2 no-route">
                            {process.env.REACT_APP_EMISSION_NAME} removed
                            {this.props.emission.remove.reason
                              ? `: ${this.props.emission.remove.reason}`
                              : ` by @${this.props.emission.username}`}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {h.checkJanny(this.props.userInfo) ? (
                  <Collapse
                    in={this.state.showRemoveDetails}
                    className="no-route"
                  >
                    <div className="bg-emphasis p-2 text-break no-route">
                      {this.props.emission.remove.details}
                    </div>
                  </Collapse>
                ) : (
                  <></>
                )}
                <p className="mb-0 text-end d-block ms-auto px-2 text-nowrap">
                  {h.makeDateHR(this.props.emission.timestamp)}
                </p>
                <p className="mb-0 text-blusteel text-end d-block ms-auto px-2 text-nowrap">
                  {h.getTimeHR(this.props.emission.timestamp)}
                </p>
                {this.props.emission.userID === this.props.userInfo._id &&
                !this.props.userInfo.ban.banned ? (
                  <></>
                ) : (
                  <>
                    {h.checkJanny(this.props.userInfo) &&
                    !(
                      !h.checkChadmin(this.props.userInfo) &&
                      ["Chadmin", "Janny"].indexOf(this.props.emission.role) >
                        -1
                    ) ? (
                      <>
                        {this.props.emission.remove.removed ? (
                          <MDBBtn
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.restore(this.props.emission);
                            }}
                            className="text-blusteel d-block ms-auto px-2 no-route"
                            color="link"
                          >
                            <i className="far fa-check-circle me-2 no-route"></i>
                            Restore
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.remove(this.props.emission);
                            }}
                            className="text-blusteel d-block ms-auto px-2 no-route"
                            color="link"
                          >
                            <i className="fas fa-ban me-2 no-route"></i>
                            Remove
                          </MDBBtn>
                        )}
                      </>
                    ) : (
                      <>
                        {this.props.emission.remove.removed ||
                        this.props.userInfo.ban.banned ||
                        this.props.emission.isBlocked ||
                        this.props.emission.blocksMe ||
                        this.props.emission.private ? (
                          <></>
                        ) : (
                          <>
                            {this.props.emission.reported ? (
                              <motion.h5
                                transition={t.transition}
                                exit={t.fade_out_minimize}
                                animate={t.normalize}
                                initial={t.fade_out_minimize}
                                className="text-danger no-route"
                              >
                                <span className="text-capitalize no-route">
                                  {process.env.REACT_APP_EMISSION_NAME}
                                </span>{" "}
                                Reported
                              </motion.h5>
                            ) : (
                              <MDBBtn
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.props.report(this.props.emission);
                                }}
                                className="text-blusteel d-block ms-auto px-2 no-route"
                                color="link"
                              >
                                <i className="fas fa-flag me-2 no-route"></i>
                                Report
                              </MDBBtn>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {this.props.emission.userID === this.props.userInfo._id &&
                !this.props.userInfo.ban.banned ? (
                  <div className="d-flex justify-content-end">
                    <MDBDropdown className="ms-2 no-route">
                      <MDBDropdownToggle
                        rippleColor="primary"
                        color="link"
                        className="profile-bubbles no-route"
                      >
                        <i
                          style={{ lineHeight: "1.5" }}
                          className="fas fa-ellipsis-v fa-lg no-route"
                        ></i>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="no-route">
                        {this.props.emission.pinned ? (
                          <MDBDropdownItem
                            onClick={(e) => {
                              this.togglePin();
                            }}
                            link
                            className="no-route"
                          >
                            <h6 className="m-1 no-route">
                              {this.state.pinning ? (
                                <>
                                  <Spinner
                                    color="primary"
                                    className="me-2 no-route"
                                    size="sm"
                                  />
                                  Unpinning
                                </>
                              ) : (
                                <>
                                  <i className="fas fa-thumbtack me-2 text-primary no-route"></i>
                                  Unpin
                                </>
                              )}
                            </h6>
                          </MDBDropdownItem>
                        ) : (
                          <MDBDropdownItem
                            onClick={() => {
                              this.togglePin();
                            }}
                            link
                            className="no-route"
                          >
                            <h6 className="m-1 no-route">
                              {this.state.pinning ? (
                                <>
                                  <Spinner
                                    className="me-2 no-route"
                                    size="sm"
                                    color="primary"
                                  />
                                  Pinning
                                </>
                              ) : (
                                <>
                                  <i className="fas fa-thumbtack me-2 text-primary no-route"></i>
                                  Pin
                                </>
                              )}
                            </h6>
                          </MDBDropdownItem>
                        )}
                        {this.props.emission.remove.removed ? (
                          <>
                            {this.props.emission.remove.user.userID ===
                            this.props.userInfo._id ? (
                              <MDBDropdownItem
                                onClick={() => {
                                  this.removeRestore();
                                }}
                                className="no-route"
                                link
                              >
                                <h6
                                  style={{
                                    opacity: this.state.removing ? 0.7 : 1,
                                  }}
                                  className="m-1 no-route"
                                >
                                  {this.state.removing ? (
                                    <>
                                      <Spinner
                                        color="success"
                                        size="sm"
                                        className="me-2 no-route"
                                      />
                                      Restoring
                                    </>
                                  ) : (
                                    <>
                                      <i className="far fa-check-circle me-2 text-success no-route"></i>
                                      Restore
                                    </>
                                  )}
                                </h6>
                              </MDBDropdownItem>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <MDBDropdownItem
                              onClick={() => {
                                this.removeRestore();
                              }}
                              link
                              className="no-route"
                            >
                              <h6
                                style={{
                                  opacity: this.state.removing ? 0.7 : 1,
                                }}
                                className="m-1 no-route"
                              >
                                {this.state.removing ? (
                                  <>
                                    <Spinner
                                      color="danger"
                                      className="me-2 no-route"
                                      size="sm"
                                    />
                                    Removing
                                  </>
                                ) : (
                                  <>
                                    <i className="far fa-trash-alt me-2 text-danger no-route"></i>
                                    Remove
                                  </>
                                )}
                              </h6>
                            </MDBDropdownItem>
                          </>
                        )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div
              className="emission-text-mobile"
              dangerouslySetInnerHTML={{ __html: this.props.emission.html }}
              onClick={(e) => {
                this.props.clickEmissionBody(e, this.props.emission.emissionID);
              }}
            ></div>
            {this.props.emission.signalBoost ? (
              <Emission
                emission={this.props.emission.signalBoost}
                loaded={this.props.loaded}
                animation={this.props.animation}
                clickEmissionBody={this.props.clickEmissionBody}
                vote={this.props.vote}
                polls={this.props.polls}
                submitVotes={this.props.submitVotes}
                pollsSubmitting={this.props.pollsSubmitting}
                selectFile={this.props.selectFile}
                signalBoost={this.props.signalBoost}
                like={this.props.like}
                copyEmissionLink={this.props.copyEmissionLink}
                emissionCopied={this.props.emissionCopied}
                reply={this.props.reply}
                signalBoosted={true}
                userInfo={this.props.userInfo}
                remove={this.props.remove}
                restore={this.props.restore}
                report={this.props.report}
                route={this.props.route}
                loadingMore={this.props.loadingMore}
                seeMore={this.props.seeMore}
                setPollModal={this.props.setPollModal}
                updateEmission={this.props.updateEmission}
              />
            ) : (
              <></>
            )}
            {this.props.emission.pollData ? (
              <React.Fragment key={this.state.pollReset}>
                <hr />
                <div className="bg-emphasis p-3 rounded-6">
                  <h5 className="text-blusteel">
                    <i className="fas fa-poll me-2 text-secondary"></i>
                    Poll
                  </h5>
                  <hr />
                  <h5 className="text-center">
                    {this.props.emission.pollData.question}
                  </h5>
                  {this.props.emission.pollData.voted ||
                  (this.props.emission.pollData.expirationDate &&
                    new Date().getTime() >
                      new Date(
                        this.props.emission.pollData.expirationDate
                      ).getTime()) ? (
                    <>
                      <p className="m-0">
                        {h.numberWithCommas(
                          this.props.emission.pollData.options.reduce(
                            (prev, curr) => prev + curr.votes,
                            0
                          )
                        )}{" "}
                        Vote
                        {this.props.emission.pollData.options.reduce(
                          (prev, curr) => prev + curr.votes,
                          0
                        ) === 1
                          ? ""
                          : "s"}
                      </p>
                      <PollExpiration pollData={this.props.emission.pollData} />
                      {this.props.emission.pollData.options.map((option) => {
                        const totalVotes =
                          this.props.emission.pollData.options.reduce(
                            (prev, curr) => prev + curr.votes,
                            0
                          );
                        const width = (option.votes / totalVotes) * 100;
                        const userVotes =
                          this.props.emission.pollData.voters.find(
                            (voter) => voter.userID === this.props.userInfo._id
                          );
                        const voted = userVotes
                          ? userVotes.votes?.indexOf(option.id) > -1
                          : false;
                        return (
                          <div
                            className="py-2 cursor-pointer"
                            onClick={(e) => {
                              this.selectPollOption(option);
                            }}
                            key={option.id}
                          >
                            <div className="mt-4 d-flex justify-content-between">
                              <label for={`vote-option-${option.id}`}>
                                {option.option} (
                                {h.numberWithCommas(option.votes)} vote
                                {option.votes === 1 ? "" : "s"})
                              </label>
                              {voted && (
                                <p className="m-0 text-success">
                                  You
                                  <i className="fas fa-check-circle ms-2" />
                                </p>
                              )}
                            </div>
                            <MDBProgress
                              className="mt-1"
                              id={`vote-option-${option.id}`}
                            >
                              <MDBProgressBar
                                bgColor={voted ? "success" : "primary"}
                                width={width}
                                valuemin={0}
                                valuemax={totalVotes}
                              ></MDBProgressBar>
                            </MDBProgress>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        Votes Allowed:{" "}
                        {this.props.emission.pollData.votesAllowed}
                      </p>
                      <PollExpiration pollData={this.props.emission.pollData} />
                      <MDBListGroup className="no-route">
                        {this.props.emission.pollData.options.map((option) => (
                          <MDBRipple
                            onClick={(e) => {
                              this.props.vote(this.props.emission, option.id);
                            }}
                            className="cursor-pointer no-route"
                            key={option.id}
                          >
                            <MDBListGroupItem
                              className="no-route"
                              active={
                                this.props.polls.find(
                                  (poll) =>
                                    poll.emissionID ===
                                    this.props.emission.emissionID
                                ) &&
                                this.props.polls
                                  .find(
                                    (poll) =>
                                      poll.emissionID ===
                                      this.props.emission.emissionID
                                  )
                                  .votes.indexOf(option.id) > -1
                              }
                              action
                            >
                              {option.option}
                            </MDBListGroupItem>
                          </MDBRipple>
                        ))}
                      </MDBListGroup>
                      <div className="d-flex justify-content-center mt-4 no-route">
                        <MDBBtn
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.submitVotes(this.props.emission, true);
                          }}
                          className="me-2 no-route"
                          color="success"
                          disabled={this.props.pollsSubmitting.find(
                            (poll) =>
                              poll.emissionID === this.props.emission.emissionID
                          )}
                        >
                          {this.props.pollsSubmitting.find(
                            (poll) =>
                              poll.emissionID === this.props.emission.emissionID
                          ) &&
                          this.props.pollsSubmitting.find(
                            (poll) =>
                              poll.emissionID === this.props.emission.emissionID
                          ).participating ? (
                            <>
                              <Spinner size="sm" className="me-2 no-route" />
                              Voting
                            </>
                          ) : (
                            <>
                              <i className="fas fa-vote-yea me-2 no-route"></i>
                              Vote
                            </>
                          )}
                        </MDBBtn>
                        <MDBBtn
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.submitVotes(this.props.emission, false);
                          }}
                          className="bg-darkblu ms-2 no-route"
                          disabled={this.props.pollsSubmitting.find(
                            (poll) =>
                              poll.emissionID === this.props.emission.emissionID
                          )}
                        >
                          {this.props.pollsSubmitting.find(
                            (poll) =>
                              poll.emissionID === this.props.emission.emissionID
                          ) &&
                          !this.props.pollsSubmitting.find(
                            (poll) =>
                              poll.emissionID === this.props.emission.emissionID
                          ).participating ? (
                            <>
                              <Spinner size="sm" className="me-2 no-route" />
                              Getting Results
                            </>
                          ) : (
                            <>
                              <i className="fas fa-eye me-2 no-route"></i>
                              View Results
                            </>
                          )}
                        </MDBBtn>
                      </div>
                    </>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <></>
            )}
            {this.props.emission.files.length ? (
              <>
                <hr />
                <div className="bg-emphasis p-3 rounded-6">
                  <h5 className="text-blusteel">
                    <i className="fas fa-images me-2 text-success"></i>
                    Media
                  </h5>
                  <hr />
                  <MDBContainer fluid>
                    <div className="row">
                      {this.props.emission.files.map((file) => (
                        <div
                          key={file.main}
                          className="col-6 col-md-3 col-lg-2 mt-2 px-1 d-flex justify-content-center no-route"
                        >
                          <MDBRipple
                            rippleColor="primary"
                            tagName="div"
                            onClick={(e) => {
                              this.props.selectFile(
                                file,
                                this.props.emission.files,
                                this.props.emission
                              );
                            }}
                            className="square-10 cursor-pointer hover-generic p-2 no-route"
                          >
                            {file.type === "audio" ? (
                              <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route">
                                <i className="fas fa-volume-up fa-3x d-block no-route"></i>
                              </div>
                            ) : (
                              <div
                                className="fit-images no-route"
                                style={{
                                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${file.thumbnail}")`,
                                }}
                              ></div>
                            )}
                          </MDBRipple>
                        </div>
                      ))}
                    </div>
                  </MDBContainer>
                </div>
              </>
            ) : (
              <></>
            )}
          </MDBCardBody>
          <MDBCardFooter className="transition-1 emission-footers">
            {this.props.emission.remove.removed ||
            this.props.userInfo.ban.banned ||
            this.props.emission.isBlocked ||
            this.props.emission.blocksMe ||
            this.props.emission.private ? (
              <></>
            ) : (
              <div className="d-flex no-route">
                <MDBTooltip
                  wrapperProps={{
                    className: "text-nowrap emission-footer-buttons no-route",
                    color: "link",
                    rippleColor: "primary",
                    onClick: (e) => {
                      h.hideToolTips();
                      this.props.reply(this.props.emission);
                    },
                  }}
                  title="Reply"
                  className="no-route"
                >
                  <span
                    className="emission-stats no-route"
                    style={{ fontSize: "1.25em" }}
                  >
                    <i className="far fa-comments emission-stat-icons no-route"></i>
                    {h.compiledNumber(this.props.emission.replies)}
                  </span>
                </MDBTooltip>
                {this.props.emission.signalBoost ? (
                  <></>
                ) : (
                  <MDBTooltip
                    wrapperProps={{
                      color: "link",
                      className: `text-nowrap no-route emission-footer-buttons ${
                        boostInfo ? "text-success" : ""
                      }`,
                      rippleColor: boostInfo ? "success" : "primary",
                      onClick: (e) => {
                        if (boostInfo)
                          this.props.route("/e/" + boostInfo.emissionID);
                        else {
                          h.hideToolTips();
                          this.props.signalBoost(this.props.emission);
                        }
                      },
                    }}
                    title={
                      boostInfo
                        ? `You ${process.env.REACT_APP_SIGNALBOOST_PAST} (${boostInfo.emissionID})`
                        : process.env.REACT_APP_SIGNALBOOST_VERB
                    }
                    className="text-capitalize no-route"
                  >
                    <span
                      className="emission-stats no-route"
                      style={{ fontSize: "1.25em" }}
                    >
                      <i className="fas fa-retweet emission-stat-icons no-route"></i>
                      {h.compiledNumber(this.props.emission.signalBoosts)}
                    </span>
                  </MDBTooltip>
                )}
                <MDBTooltip
                  wrapperProps={{
                    color: "link",
                    className: "text-nowrap emission-footer-buttons no-route",
                    rippleColor: "#fc45df",
                    onClick: (e) => {
                      h.hideToolTips();
                      this.props.like(this.props.emission.emissionID);
                    },
                  }}
                  title="Like"
                  className="no-route"
                >
                  <span
                    className={`emission-stats no-route ${
                      this.props.emission.liked ? "text-pink" : ""
                    }`}
                    style={{ fontSize: "1.25em" }}
                  >
                    <i
                      className={`fa${
                        this.props.emission.liked ? "s" : "r"
                      } fa-heart emission-stat-icons no-route`}
                    ></i>
                    {h.compiledNumber(this.props.emission.likes)}
                  </span>
                </MDBTooltip>
                <MDBTooltip
                  className="min-w-max-content no-route"
                  wrapperProps={{
                    color: "link",
                    className:
                      "cursor-default text-nowrap emission-footer-buttons no-route",
                    rippleColor: "primary",
                  }}
                  title="Views"
                >
                  <span
                    className="emission-stats no-route"
                    style={{ fontSize: "1.25em" }}
                  >
                    <i className="fas fa-chart-bar emission-stat-icons no-route"></i>
                    {h.compiledNumber(this.props.emission.views)}
                  </span>
                </MDBTooltip>
                <MDBTooltip
                  className="min-w-max-content no-route"
                  wrapperProps={{
                    className: "emission-footer-buttons no-route",
                    color: "link",
                    rippleColor: "primary",
                    onClick: (e) => {
                      this.props.copyEmissionLink(
                        this.props.emission.emissionID
                      );
                    },
                  }}
                  title={
                    this.props.emissionCopied === this.props.emission.emissionID
                      ? "Link Copied"
                      : process.env.REACT_APP_HOST +
                        "/e/" +
                        this.props.emission.emissionID
                  }
                >
                  <span
                    className="emission-stats no-route"
                    style={{ fontSize: "1.25em" }}
                  >
                    <i className="fas fa-share-alt emission-stat-icons no-route"></i>
                    <span className="emission-copy-copy no-route">Copy </span>
                    Link
                  </span>
                </MDBTooltip>
              </div>
            )}
            {this.props.emission.children?.length ? (
              <>
                <hr />
                <div className="bg-emphasis p-3 rounded-6">
                  <h5 className="text-blusteel">
                    <i
                      style={{ color: "rgb(255, 171, 0)" }}
                      className="fas fa-comments me-2"
                    ></i>
                    Replies to
                    <span className="text-pkmn ms-2">
                      #{this.props.emission.emissionID}
                    </span>
                  </h5>
                  {this.props.emission.children.map((emission, e) => (
                    <Emission
                      emission={emission}
                      loaded={this.props.loaded}
                      animation={this.props.animation}
                      clickEmissionBody={this.props.clickEmissionBody}
                      vote={this.props.vote}
                      polls={this.props.polls}
                      submitVotes={this.props.submitVotes}
                      pollsSubmitting={this.props.pollsSubmitting}
                      selectFile={this.props.selectFile}
                      signalBoost={this.props.signalBoost}
                      like={this.props.like}
                      copyEmissionLink={this.props.copyEmissionLink}
                      emissionCopied={this.props.emissionCopied}
                      reply={this.props.reply}
                      index={e}
                      key={emission._id}
                      userInfo={this.props.userInfo}
                      remove={this.props.remove}
                      restore={this.props.restore}
                      report={this.props.report}
                      route={this.props.route}
                      loadingMore={this.props.loadingMore}
                      seeMore={this.props.seeMore}
                      setPollModal={this.props.setPollModal}
                      updateEmission={this.props.updateEmission}
                    />
                  ))}
                  {this.props.emission.children.length <
                  this.props.emission.replies ? (
                    <div className="py-4 no-route">
                      {this.props.loadingMore.indexOf(
                        this.props.emission.emissionID
                      ) > -1 ? (
                        <MDBBtn
                          size="lg"
                          disabled
                          className="d-block mx-auto w-50 no-route"
                          rippleColor="primary"
                          outline
                          color="primary"
                          rounded
                        >
                          <Spinner
                            color="primary"
                            size="sm"
                            className="me-2 no-route"
                          />
                          Please Wait
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          size="lg"
                          onClick={(e) => {
                            this.props.seeMore(this.props.emission.emissionID);
                          }}
                          className="d-block mx-auto w-50 no-route"
                          rippleColor="primary"
                          outline
                          color="primary"
                          rounded
                        >
                          See More Replies to{" "}
                          <span className="text-pkmn no-route">
                            #{this.props.emission.emissionID}
                          </span>
                        </MDBBtn>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </MDBCardFooter>
        </MDBCard>
      </motion.div>
    );
  }
}

export default Emission;
