import React from 'react';
import { MDBToast } from 'mdb-react-ui-kit';
import { connect } from 'react-redux';

/**
 * This is a push notification that fades out automatically after a few seconds
 * Can also be manually X'd out by the user
 * Receives a string as props, and displays that string
 * Situated at bottom right of the screen
 */

const ToastMisc = props => (
    <MDBToast
        color={props.userInfo.userSettings.theme === 'default' ? 'light' : 'dark'}
        autohide
        position='bottom-right'
        delay={2000}
        appendToBody
        triggerRef={props.triggerRef}
        headerContent={<div className="me-auto"></div>}
        bodyContent={
            <h5>{props.icon}{props.text}</h5>
        }
        className="w-max-content max-w-100"
    />
);

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(ToastMisc);