import React from "react";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { route, purge_user } from "../redux/actions";
import { connect } from "react-redux";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class Delete extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      working: true,
      attempts: 0,
    };
    this.requestID = props.match.params.id;
  }

  componentDidMount() {
    this.run();
  }

  run = () =>
    axios
      .get("/auth/delete-confirm/" + this.requestID)
      .then(() =>
        this.setState(
          {
            ...this.state,
            working: false,
          },
          this.props.purge_user
        )
      )
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 410)
          this.setState({
            ...this.state,
            error:
              "This deletion request has expired. If you would still like to delete your account, please submit another request and click the new link that is sent to your email.",
            working: false,
          });
        else if (err.response?.status === 404)
          this.setState({
            ...this.state,
            error: "Deletion request not found.",
            working: false,
          });
        else if (err.response?.status === 400)
          this.setState({
            ...this.state,
            error:
              "This deletion request has been cancelled. If you would still like to delete your account, please submit another request and click the new link that is sent to your email.",
          });
        else if (this.state.attempts >= 3)
          this.setState({
            ...this.state,
            error:
              "An unknown error occurred. Please check your connection and try again later.",
            working: false,
          });
        else
          this.setState(
            {
              ...this.state,
              attempts: this.state.attempts + 1,
            },
            () => setTimeout(this.run, 1000)
          );
      });

  render() {
    return (
      <MDBContainer>
        {this.state.working ? (
          <motion.section
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out}
          >
            <h5 className="text-center display-6 my-4">Confirming Request</h5>
            <LinearProgress />
          </motion.section>
        ) : (
          <motion.div
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out}
          >
            <h5 className="text-center my-4">
              {this.state.error || "Your account was successfully deleted"}
            </h5>
            <MDBBtn
              className="d-block mx-auto"
              rippleColor="primary"
              color="link"
              onClick={() => this.props.route("/")}
            >
              <i className="fas fa-home me-2" />
              Return
            </MDBBtn>
          </motion.div>
        )}
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route, purge_user })(
  withRouter(Delete)
);
