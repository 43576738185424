import React from 'react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import t from '../utilities/transitions';
import {
    MDBNavbar,
    MDBTooltip,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { purge_user, route } from '../redux/actions';
import { Link } from 'react-router-dom';

class UserBar extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether the user is in the process of logging out
             */
            working: false
        }
    }

    /**
     * 
     * @param {Boolean} retry - Whether this is retrying from a previous failed logout attempt
     * 
     * Triggered when the user clicks Logout
     * 
     * Logs the user out
     * Disconnects the socket
     * Purges the user
     * 
     */
    logout = retry => {
        if (!this.state.working || retry) this.setState({
            ...this.state,
            working: true
        }, () => axios.get('/auth/logout').then(() => {
            if (this.props.socket && this.props.socket.disconnect) this.props.socket.disconnect();
            this.props.purge_user();
            this.setState({
                ...this.state,
                working: false
            });
        }).catch(err => {
            console.log('logout error', err);
            setTimeout(() => this.logout(true), 1000);
        }));
    }

    /**
     * 
     * @param {Click Event} e
     * 
     * Triggered when the user clicks their avatar
     * Navigates to the user's profile 
     */
    goToProfile = e => {
        e.preventDefault();
        this.props.route(`/${this.props.userInfo.username}`);
    }

    render(){
        return (
            <motion.div className={`position-fixed w-max-content end-0 top-0 ${this.props.userInfo.ban.banned ? 'bg-litepink' : ''}`} transition={t.transition} exit={t.fade_out_scale_1} animate={t.normalize} initial={t.fade_out_scale_1}>
                
                <MDBNavbar 
                    style={{
                        borderBottomLeftRadius: '0.33rem'
                    }} 
                    className="p-0" 
                    light 
                    bgColor={this.props.userInfo.userSettings.theme === 'default' ? 'light' : 'dark'}
                >
                    {this.props.userInfo.username ?
                    <>
                        <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                            <MDBTooltip tag="div" placement="left" title={this.props.userInfo.username} className="me-2">
                                <Link to={`/${this.props.userInfo.username}`} onClick={this.goToProfile}>
                                    <MDBRipple rippleColor="primary">
                                        <div className="d-flex justify-content-center align-items-center square-3 p-2">
                                            <div className="fit-images fit-round" style={{backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${process.env.REACT_APP_INSTANCE_ID}/thumbnails/${this.props.userInfo.avatar.thumbnail}")`, borderRadius: '50%'}}></div>
                                        </div>
                                    </MDBRipple>
                                </Link>
                            </MDBTooltip>
                        </motion.div>
                        <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                            <h5 className="fw-bold m-0">|</h5>
                        </motion.div>
                    </> : <></>}
                    <MDBTooltip tag="div" placement={this.props.userInfo.username ? "bottom" : "left"} title="Info" className="me-2">
                        <Link 
                            to="/info" 
                            onClick={e => {
                                e.preventDefault();
                                this.props.route('/info');
                            }}
                        >
                            <MDBBtn rippleColor="#1976d2" color="link" className="text-darkblu px-2">
                                <i className="fas fa-lg fa-info-circle"></i>
                            </MDBBtn>
                        </Link>
                    </MDBTooltip>
                    {this.props.userInfo.username ?
                    <>
                        <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                            <h5 className="fw-bold m-0">|</h5>
                        </motion.div>
                        <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                            <MDBBtn disabled={this.state.working} onClick={this.logout} className={`text-danger px-2 ${this.state.working ? 'cursor-default' : ''}`} rippleColor='danger' color='link'>
                                Logout
                            </MDBBtn>
                        </motion.div>
                    </> : <></>}
                </MDBNavbar>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { purge_user, route })(UserBar);